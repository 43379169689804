<div class="page-title-box">
	<h1>Participant Data Filtration</h1>
	<ul class="bradcrumb">
		<li>Participant Data Filtration</li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<form [formGroup]="participantDataFilterForm" #form="ngForm" class="edit-mode"
				(ngSubmit)="getParticipantData()">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">Participant Data Filtration</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape">
									<mat-label> Site Code(s)</mat-label>
									<div class="control-pad">
										<ng-select [items]="siteList" formControlName="site_id" [hideSelected]="true"
											bindValue="id" [multiple]="true" bindLabel="site_code"
											placeholder="Select Site Code(s)">
										</ng-select>
									</div>
								</div>
								<div class="input-wrape">
									<mat-label>Derived > Declared Populations</mat-label>
									<div class="control-pad">
										<ng-select [items]="populationList" formControlName="participantPopulations"
											[hideSelected]="true" bindValue="populationid" [multiple]="true"
											bindLabel="sub_population" placeholder="Select Populations">
										</ng-select>
									</div>
								</div>

								<div class="input-wrape">
									<mat-label>Derived > Declared Diseases</mat-label>
									<div class="control-pad">
										<ng-select [items]="diseaseList" formControlName="participantDiseases"
											[hideSelected]="true" bindValue="diseaseid" [multiple]="true"
											bindLabel="diseasename" placeholder="Select Diseases">
										</ng-select>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape calendar-wrape">
									<mat-label>Collection Start Date</mat-label>
									<mat-form-field class="example-full-width" appearance="outline">
										<input matInput [matDatepicker]="pickerStartDate" placeholder="Select start date"
											(click)="pickerStartDate.open()" formControlName="startDate">
										<mat-datepicker-toggle matSuffix [for]="pickerStartDate">
											<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #pickerStartDate></mat-datepicker>
									</mat-form-field>
								</div>

								<div class="input-wrape calendar-wrape">
									<mat-label>Collection End Date</mat-label>
									<mat-form-field class="example-full-width" appearance="outline">
										<input matInput [matDatepicker]="pickerEndDate" placeholder="Select end date"
											(click)="pickerEndDate.open()" formControlName="endDate">
										<mat-datepicker-toggle matSuffix [for]="pickerEndDate">
											<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #pickerEndDate></mat-datepicker>
									</mat-form-field>
								</div>

								<div class="input-wrape">
									<mat-label>Comma Separated PID/New PID/Recollection ID</mat-label>
									<mat-form-field appearance="outline">
										<textarea matInput placeholder="Comma separated PIDs e.g.: ND11ZZ123,ND12FW392"
											formControlName="new_pids"></textarea>
									</mat-form-field>
								</div>

								<div class="input-wrape">
									<mat-label>Get Count OR Get Datapoint Count OR Export Participants</mat-label>
									<mat-radio-group formControlName="getCounts" aria-label="Select an option"
										class="example-radio-group">
										<mat-radio-button class="example-radio-button" value="count" checked>Count
										</mat-radio-button>
										<mat-radio-button class="example-radio-button" value="data_point_count">
											Datapoint Count</mat-radio-button>
										<mat-radio-button class="example-radio-button" value="export">Export
										</mat-radio-button>
									</mat-radio-group>
								</div>

								<div class="input-wrape"
									*ngIf="participantDataFilterForm.controls['getCounts'].value=='export'">
									<mat-label>Select Participant Module To Export</mat-label>
									<div class="control-pad">
										<ng-select appendTo="body" [items]="exportModuleList" formControlName="export_modules"
											[hideSelected]="true" bindValue="value" [multiple]="true" bindLabel="label"
											placeholder="Select Participant Modules">
										</ng-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer" style="display: inline-block;">
						<p *ngIf="participantDataFilterForm.controls['getCounts'].value=='count'"> Total Filtered
							Participants:
							<b>{{filteredParticipants}}</b>
						</p>
						<div style="float: right;">
							<button mat-raised-button class="btn"
								*ngIf="participantDataFilterForm.controls['getCounts'].value=='count'" type="submit"
								color="secoundary" title="Get Participant Count">Get Participant Count</button>
							<button mat-raised-button class="btn"
								*ngIf="participantDataFilterForm.controls['getCounts'].value=='data_point_count'"
								type="submit" color="secoundary" title="Export Datapoint Count">Export Datapoint
								Count</button>
							<button mat-raised-button class="btn"
								*ngIf="participantDataFilterForm.controls['getCounts'].value=='export'" type="submit"
								color="secoundary" title="Export Participants">Export Participants</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</section>