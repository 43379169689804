import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import { HtrService } from 'src/app/service/htr.service';
import { CommonService } from 'src/app/service/common.service';
import { HtrElement } from 'src/app/interfaces/htr';
import { SiteService } from 'src/app/service/site.service';
import { Sites } from 'src/app/interfaces/sites';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AppConstants } from 'src/app/app.constant';

const ELEMENT_DATA: HtrElement[] = [];

@Component({
  selector: 'app-manage-htr',
  templateUrl: './manage-htr.component.html',
  styleUrls: ['./manage-htr.component.scss']
})
export class ManageHtrComponent implements OnInit {

  public htrFilterForm!: FormGroup;

  public displayedColumns: string[] = [
    'select',
    'id',
    'new_pid',
    'site_code',
    'first_name',
    'last_name',
    'diagnostic_lab',
    'action'
  ];

  public siteList: Sites[] = [];

  public dataSource = new MatTableDataSource<HtrElement>(ELEMENT_DATA);
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];

  public show_qc_options = false;
  public hide_review_options = true;
  public filterData: any;

  // @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;
  // PMD DM follow disease multiselect end 

  constructor(
    // @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    public participantService: ParticipantService,
    public htrService: HtrService,
    public commonService: CommonService,
    private siteService: SiteService,
    protected router: Router
  ) {
  }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

    this.getAllHtr();
    this.getSite();

    this.htrFilterForm = this.formBuilder.group({
      siteCodes: [[], []],
      is_htr_qced: ['', []],
      is_htr_reviewed: ['', []]
    });
  }

  //Multiple Site Dropdown Start

  /**
   * Get Site
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  getSite() {
    this.siteService.getAllList().subscribe((result: any) => {
      this.siteList = result.data;
    });
  }
  //Multiple Site Dropdown end

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllHtr();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      if (sortState.active == 'created_date_formatted') {
        this.sortField = 'created_at';
      }
      this.sortOrder = sortState.direction;
      this.getAllHtr();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllHtr();
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getAllHtr() {

    this.show_qc_options = false;
    this.hide_review_options = true;
    let siteCodes = '';
    let is_htr_qced = '';
    let is_htr_reviewed = '';

    if (this.htrFilterForm) {

      siteCodes = this.htrFilterForm.value.siteCodes;

      if (this.htrFilterForm.value.is_htr_qced) {
        this.show_qc_options = true;
        is_htr_qced = this.htrFilterForm.value.is_htr_qced;
      }

      if (this.htrFilterForm.value.is_htr_reviewed) {
        this.hide_review_options = false;
        is_htr_reviewed = this.htrFilterForm.value.is_htr_reviewed;
      }
    }

    this.htrService
      .getAllHtr(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        siteCodes,
        is_htr_qced,
        is_htr_reviewed,
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result.data.data);
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
        },
        error: (e) => {
          console.log('Participant Listing err :: ', e);
        },
        complete: () => { },
      });
  }

  // table checkbox code start
  // checkboxdataSource = new MatTableDataSource<HtrElement>(ELEMENT_DATA);
  selection = new SelectionModel<HtrElement>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: HtrElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.select + 1}`;
  }

  // checkboxapplyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }
  // table checkbox code end

  markAsReviewed() {
    // console.log('here');
    // console.log(this.selection.selected);
    // return;

    if (!this.selection || this.selection.selected.length == 0) {
      this.commonService.showErrorToast('Please select atleast one HTR record')
      return;
    }

    const formData: FormData = new FormData();
    const selectedData = <any>this.selection.selected;
    formData.append("htrID", selectedData);
    
    this.htrService.updateReviewedBulk(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {

            this.commonService.showSuccessToast(
              "Selected records marked as Reviewed"
            );

          } else {
            this.commonService.showErrorToast(
              "Something went wrong. Please contact to administrator."
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }
  
  markAsQCRemove() {

    if (!this.selection || this.selection.selected.length == 0) {
      this.commonService.showErrorToast('Please select atleast one HTR record')
      return;
    }
    
    this.htrService.removeQCed(this.selection.selected)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {

            this.commonService.showSuccessToast(
              "Selected records removed from QC"
            );

          } else {
            this.commonService.showErrorToast(
              "Something went wrong. Please contact to administrator."
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }
}