import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { DrugCategoryService } from 'src/app/service/drug-category.service';

@Component({
  selector: 'app-drug-category-form',
  templateUrl: './drug-category-form.component.html',
  styleUrls: ['./drug-category-form.component.scss'],
})
export class DrugCategoryFormComponent implements OnInit {

  public title: any;
  public loading: Boolean = false;
  public isAddMode: boolean = true;
  public userId: any = '';
  public drugCategoryId: any = '';
  public drugCategoryDetails: any = '';
  public drugCategoryForm!: FormGroup;
  public nameMinLength: number = 3;
  public buttonText: string;
  public selectMapOption: boolean = false;
  public disableSubmitBtn: boolean = false;
  public isApproved: number = 1;
  public snomedIdList: any[] = [];
  public cancelLink: string = '/dictionary-management/drug-category';

  constructor(
    private diseaseService: DiseaseService,
    private drugCategoryService: DrugCategoryService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.drugCategoryId = this.route.snapshot.paramMap.get('id');

    if (this.drugCategoryId !== 0 && this.drugCategoryId !== null) {
      this.title = 'Edit Drug Category';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Drug Category';
      this.buttonText = 'Add';
      this.drugCategoryId = 0;
    }
  }

  ngOnInit(): void {
    this.getDisease();
    if (this.drugCategoryId !== 0 && this.drugCategoryId !== null) {
      this.setEditData();
    }
    this.isAddMode = !this.drugCategoryId;
    this.userId = localStorage.getItem('userId');
    this.drugCategoryForm = this.formBuilder.group({
      category_name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      snomed_id: [null, ''],
    });
  }

  onSubmit(data: any) {
    if (this.drugCategoryForm.invalid) {
      this.commonService.validateAllFormFields(this.drugCategoryForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append(
      'category_name',
      this.drugCategoryForm.controls['category_name'].value
    );
    if (this.drugCategoryForm.controls['snomed_id'].value != '') {
      formData.append('snomed_id', JSON.stringify(this.drugCategoryForm.controls['snomed_id'].value));
    }
    if (this.drugCategoryId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    if (this.isAddMode) {
      this.createDrugCategory(formData);
    } else {
      this.updateDrugCategory(formData);
    }
  }

  /**
   * call Create drugCategory api
   * @returns null
   */
  private createDrugCategory(formData: any): void {
    this.drugCategoryService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast(
            'Drug Category added successfully'
          );
          this.router.navigate(['/dictionary-management/drug-category']);
        } else {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
          if (typeof result.data === 'object') {
            for (const key in result.data) {
              this.drugCategoryForm
                .get(key)!
                .setErrors({ validField: result.data[key] });
            }
          }
        }
      },
      error: (err) => {
        console.log('error from create Drug Category', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update drugCategory api
   * @returns null
   */
  private updateDrugCategory(formData: any): void {
    this.drugCategoryService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(
              'Drug Category updated successfully'
            );
            this.router.navigate(['/dictionary-management/drug-category']);
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err: any) => {
          this.disableSubmitBtn = false;
          console.log('error from update Drug Category', err);
        },
      });
  }

  setEditData() {
    this.drugCategoryService
      .getRow(this.drugCategoryId)
      .subscribe((result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(['page-not-found']);
        }
        this.drugCategoryDetails = result['data'];
        // Set Selected snomedId
        let selected_diseases: any[] = [];
        this.drugCategoryDetails.get_diseases.forEach((res: any) => {
          if (res.get_disease != null) {
            selected_diseases.push(res.get_disease);
          }
        });

        this.drugCategoryForm.patchValue({
          category_name: this.drugCategoryDetails.category_name,
          snomed_id: selected_diseases,
        });
      });
  }

  /**
   * Check Drug Category field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueDrugCategory(event: any) {
    let name = (event.target as HTMLInputElement).value;
    if (name !== '' && name.length > this.nameMinLength && name.length < 255) {
      this.drugCategoryService
        .checkUniqueDrugCategory(name, this.drugCategoryId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.drugCategoryForm.controls['category_name'].setErrors(null);
            } else {
              this.drugCategoryForm.controls['category_name'].setErrors({
                unique: 'Drug Category drug name already exists',
              });
            }
          },
          error: (error: any) => {
            // console.log(error);
            if (error.status === 400) {
              this.drugCategoryForm.controls['name'].setErrors({
                unique: 'Drug Category drug name already exists',
              });
            } else {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
        });
    }
  }

  getDisease() {
    this.diseaseService.getActiveList().subscribe({
      next: (result: any) => {
        if (result.status === 1 || result.status === 200) {
          this.snomedIdList = result.data;
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }
}
