<div class="tabbing-content-wrape">
  <mat-spinner *ngIf="loading"></mat-spinner>
  <form [ngClass]="participantFormStatus ? 'edit-mode' : 'view-mode'" id="participantForm" [formGroup]="participantForm"
    (ngSubmit)="participantInfoSubmit()">
    <div class="container-fluid pi-wrape">
      <div class="row">
        <!-- PI basic information 1 start -->
        <div class="col-lg-6">
          <!-- basic info start -->
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Basic Information - 1
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="input-wrape" [ngClass]="displayFieldCss('unique_id')">
                <mat-label><i>*</i> Select Unique ID Type:</mat-label>
                <div class="control-pad">
                  <ng-select placeholder="Select Unique Id" formControlName="unique_id">
                    <!-- <ng-template ng-option-tmp ng-label-tmp let-item="item">
                      {{ item | titlecase }}
                    </ng-template> -->
                    <ng-option value="{{item}}" *ngFor="let item of uniqueIdOptions">
                      {{item | titlecase }}
                    </ng-option>
                  </ng-select>

                </div>
                <mat-error *ngIf="isFieldValid('unique_id')">
                  Please Select Unique ID Type
                </mat-error>
              </div>
              <div class="input-wrape tree one" *ngIf="participantForm.get('unique_id')?.value">
                <mat-label>Enter ID Number</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Enter ID number" type="text" formControlName="unique_id_detail">
                </mat-form-field>
                <i class="ruler"></i>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> Participant ID #: example - AA12BB123</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Enter ID #: example - AA12BB123" type="text"
                    formControlName="participant_identification" readonly>
                </mat-form-field>
              </div>
              <div class="input-wrape">
                <mat-label>Core ID: (While Recollection)</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Enter recollection ID Ex: BB11BB945" type="text"
                    formControlName="recollection_id" readonly>
                </mat-form-field>
              </div>
              <div class="input-wrape">
                <mat-label>Site Code:</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Enter site code" type="text" formControlName="site_code" readonly>
                </mat-form-field>
              </div>
              <div class="input-wrape" [ngClass]="displayFieldCss('first_name')">
                <mat-label><i>*</i> First Name:</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input [ngClass]="displayFieldCss('first_name')" placeholder="Enter first name"
                    type="text" formControlName="first_name">
                </mat-form-field>

                <mat-error *ngIf="isFieldValid('first_name')">
                  Enter first name
                </mat-error>

              </div>
              <div class="input-wrape">
                <mat-label>Middle Name:</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Enter middle name" type="text" formControlName="middle_name">
                </mat-form-field>
              </div>
              <div class="input-wrape" [ngClass]="displayFieldCss('last_name')">
                <mat-label><i>*</i> Last Name:</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input [ngClass]="displayFieldCss('last_name')" placeholder="Enter last name"
                    type="text" formControlName="last_name">
                </mat-form-field>
                <mat-error *ngIf="isFieldValid('last_name')">
                  Enter last name
                </mat-error>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> Enter Date of Birth <span>OR</span> Age:</mat-label>
                <div class="half-controls">
                  <div class="half">
                    <div class="input-wrape calendar-wrape"
                      [ngClass]="displayFieldCss('date_of_birth') || displayFieldCss('age')">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput [matDatepicker]="pickerPMHDob" [min]="minDate" [max]="maxDate"
                          formControlName="date_of_birth" placeholder="Enter date of birth"
                          (click)="pickerPMHDob.open()" (dateChange)="ageCalculation($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerPMHDob">
                          <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerPMHDob></mat-datepicker>
                      </mat-form-field>
                      <mat-error *ngIf="isFieldValid('date_of_birth') || isFieldValid('age')">
                        Enter date of birth or age is required
                      </mat-error>
                    </div>
                  </div>
                  <div class="half">
                    <div class="control-pad">
                      <ng-select [items]="ageOptions" bindLabel="value" bindValue="id" placeholder="Enter Age"
                        class="numberSearch" formControlName="age" (change)="ageSelection($event)" [searchable]="true">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-wrape rc-wrape">
                <mat-label><i>*</i> Gender:</mat-label>
                <mat-radio-group aria-label="Select an option" formControlName="gender">
                  <mat-radio-button [value]="option" *ngFor="let option of genderOptions">{{
                    option | titlecase }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="isFieldValid('gender')">
                  Select gender
                </mat-error>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- basic info end -->

          <!-- PI contact infromation start -->
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Participant's Contact Information
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="input-wrape pi-contact"
                [ngClass]="displayFieldCss('phone_mobile_country_id') || displayFieldCss('phone_mobile')">
                <mat-label><i>*</i> Phone Number:</mat-label>
                <div class="code">
                  <div class="control-pad">
                    <ng-select [items]="countries" bindLabel="name" bindValue="id" placeholder="Enter phone code"
                      formControlName="phone_mobile_country_id">
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.phone_code }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span class="country-name">{{item.name}} </span>
                        <small>{{ item.phone_code }}</small>
                      </ng-template>
                    </ng-select>
                    <mat-error *ngIf="isFieldValid('phone_mobile_country_id')">
                      Select phone code
                    </mat-error>
                  </div>
                </div>
                <div class="number verified-thing">
                  <mat-form-field appearance="outline" class="number-pad">
                    <input [ngClass]="displayFieldCss('phone_mobile')" matInput #input placeholder="Enter phone number"
                      formControlName="phone_mobile" (keypress)="onlyNumeric($event)" type="number" maxlength="10">
                  </mat-form-field>
                  <div class="side-info">
                    <div class="info-pad popup-over">
                      <button class="info-icon"
                        *ngIf="participantForm.controls['is_phone_number_verified'].value">E-Verified</button>
                      <button class="close-icon" *ngIf="!participantForm.controls['is_phone_number_verified'].value">Not
                        Verified</button>
                    </div>
                  </div>
                </div>
                <mat-error *ngIf="isFieldValid('phone_mobile')">
                  Enter phone number
                </mat-error>
              </div>
              <div class="input-wrape pi-contact">
                <mat-label>Alternate Phone Number:</mat-label>
                <div class="code">
                  <div class="control-pad">
                    <ng-select [ngClass]="displayFieldCss('alternate_phone_no_country_id')" [items]="countries"
                      bindLabel="name" bindValue="id" placeholder="Enter alternate phone code"
                      formControlName="alternate_phone_no_country_id">
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.phone_code }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span class="country-name">{{item.name}} </span>
                        <small>{{ item.phone_code }}</small>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="number verified-thing">
                  <mat-form-field appearance="outline" class="number-pad">
                    <input matInput #input formControlName="alternate_phone_number"
                      placeholder="Enter alternate phone number" type="number" (keypress)="onlyNumeric($event)"
                      maxlength="10">
                  </mat-form-field>
                  <i class="varify"></i>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>Email Address:</mat-label>
                <mat-form-field appearance="outline" class="number-pad">
                  <input matInput #input placeholder="Enter email address" type="text" formControlName="email">
                </mat-form-field>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- PI contact infromation end -->

          <!-- PI Participant Consent end -->
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Participant Consent
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body class="upload-thumb-wrape">

              <div class="input-wrape" *ngIf="participantInformation?.photo != ''">
                <mat-label>Image:</mat-label>
                <div class="icon-wrape-preview">
                  <span class="icon photo"></span>
                  <div class="img-wrape">
                    <span mat-button (click)="showProfile()"><img
                        [src]="(participantInformation?.photo != '')? participantInformation?.photo: '../../../../../../../assets/images/patient-pic-1.jpg'"
                        alt="Photo Pin"></span>
                  </div>
                </div>
              </div>
              <div class="input-wrape" *ngIf="participantInformation?.video_file_name != ''">
                <mat-label>Video:</mat-label>
                <div class="icon-wrape-preview">
                  <span (click)="showVideo()" class="icon vdo"></span>
                </div>
              </div>
              <div class="input-wrape" *ngIf="participantInformation?.participant_icf_file_name != ''">
                <mat-label>ICF Form:</mat-label>
                <div class="icon-wrape-preview">
                  <span class="icon pdf"></span>
                  <div class="upload-wrape">
                    <span mat-button>
                      <a href="{{participantInformation?.participant_icf_file_name}}" target="_blank">
                        <img src="../../../../../../assets/images/PDF_file_icon.png" alt="ICF Form">
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div
                *ngIf="participantInformation?.photo == '' && participantInformation?.video_file_name == '' && participantInformation?.participant_icf_file_name == ''">
                <div class="no-data-found">
                  <div class="no-data-container">
                    <div class="no-data-icon"></div>
                    <div class="no-data-text">No Participant Consent Found</div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- PI Participant Consent end -->
        </div>
        <!-- PI basic information 1 end -->

        <div class="col-lg-6">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Place of Origin (Native)
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="input-wrape">
                <mat-label><i>*</i> Country</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('origin_country_id')" [items]="countries"
                    placeholder="Select country" bindLabel="name" bindValue="id" formControlName="origin_country_id"
                    [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('origin_country_id')">
                    Select origin country
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>PIN/ZIP Code</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('origin_postal_code_id')" [items]="pincodes"
                    placeholder="Enter pin/zip code" bindLabel="pincode" bindValue="id"
                    labelForId="origin_postal_code_id" formControlName="origin_postal_code_id"
                    (search)="searchPincode($event.term)">
                  </ng-select>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>City/Village/Town:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('origin_city_id')" [items]="cities"
                    placeholder="Select City/Village/Town" bindLabel="name" bindValue="id"
                    formControlName="origin_city_id" [addTag]="addTagFn">
                  </ng-select>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> State:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('origin_state_id')" [items]="states" placeholder="Select state"
                    bindLabel="name" bindValue="id" formControlName="origin_state_id" [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('origin_state_id')">
                    Select origin state
                  </mat-error>
                </div>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- PI location end -->

          <!-- PI residentioal start -->
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Residential Information
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="input-wrape">
                <mat-label>Residential Address</mat-label>
                <mat-radio-group aria-label="Select an option" (change)="sameAsAddress($event.value)"
                  formControlName="is_residential_address_same_as">
                  <mat-radio-button value="PLACE_OF_ORIGIN"> Same as Place of Origin (Native)? </mat-radio-button>
                  <mat-radio-button value="NONE_OF_THESE">Other</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> Country:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('residential_country_id')" [items]="countries"
                    placeholder="Select country" bindLabel="name" bindValue="id"
                    formControlName="residential_country_id" [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('residential_country_id')">
                    Select residential country
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>PIN/ZIP Code</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('residential_postal_code_id')" [items]="pincodes"
                    placeholder="Select pin/zip code" bindLabel="pincode" bindValue="id"
                    labelForId="residential_postal_code_id" formControlName="residential_postal_code_id"
                    (search)="searchPincode($event.term)">
                  </ng-select>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> City/Village/Town:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('residential_city_id')" [items]="cities"
                    placeholder="Select city/village/town" bindLabel="name" bindValue="id"
                    formControlName="residential_city_id" [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('residential_city_id')">
                    Select residential city
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> State:</mat-label>
                <div class="control-pad">
                  <ng-select [items]="states" [ngClass]="displayFieldCss('residential_state_id')"
                    placeholder="Select state" bindLabel="name" bindValue="id" formControlName="residential_state_id"
                    [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('residential_state_id')">
                    Enter residential state
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>Address:</mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <textarea matInput placeholder="Enter your address"
                    formControlName="residential_postal_address"></textarea>
                </mat-form-field>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- PI residentioal end -->

          <!-- PI relegion start -->
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Religion
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="input-wrape">
                <mat-label><i>*</i> Religion:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('religion_id')" [items]="religions"
                    placeholder="Select religion" bindLabel="name" bindValue="id" formControlName="religion_id">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('religion_id')">
                    Select religion
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label><i>*</i> Subpopulation (Caste/Ethnicity/Tribe) :</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('population_id')" [items]="populations"
                    placeholder="Select Subpopulation (Caste/Ethnicity/Tribe)" bindLabel="name" bindValue="id"
                    formControlName="population_id" [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('population_id')">
                    Select subpopulation (caste/ethnicity/tribe)
                  </mat-error>
                </div>
              </div>

              <!-- PI laguage start -->
              <div class="input-wrape">
                <mat-label><i>*</i> Mother Tongue:</mat-label>
                <div class="control-pad">
                  <ng-select [ngClass]="displayFieldCss('mother_tongue_id')" [items]="languages"
                    placeholder="Select Mother Tongue" bindLabel="name" bindValue="id"
                    formControlName="mother_tongue_id" [addTag]="addTagFn">
                  </ng-select>
                  <mat-error *ngIf="isFieldValid('mother_tongue_id')">
                    Select mother tongue
                  </mat-error>
                </div>
              </div>
              <div class="input-wrape">
                <mat-label>Are Your Parents Blood Related? </mat-label>
                <mat-radio-group aria-label="Select an option" formControlName="is_parents_blood_relation">
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="input-wrape tree one parents_blood_relation"
                *ngIf="participantForm.get('is_parents_blood_relation')?.value == 'yes'">
                <mat-label>Choose Relationship</mat-label>
                <div class="control-pad">
                  <ng-select
                    [ngClass]="{ 'has-error': participantForm.controls['parents_blood_relation'].invalid && (participantForm.controls['parents_blood_relation'].dirty || participantForm.controls['parents_blood_relation'].touched)}"
                    [items]="bloodRelatives" placeholder="Select relationship" bindValue="relative"
                    formControlName="parents_blood_relation">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                      {{ item | titlecase }}
                    </ng-template>
                  </ng-select>
                  <i class="ruler parents_blood_relation"></i>
                </div>
              </div>
              <div class="input-wrape tree two parents_blood_relation"
                *ngIf="participantForm.get('parents_blood_relation')?.value == 'other' && participantForm.get('is_parents_blood_relation')?.value == 'yes'">
                <mat-label>Describe Relationship</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput #input placeholder="Describe relationship" type="text"
                    formControlName="parents_blood_relation_description">
                </mat-form-field>
                <i class="ruler"></i>
              </div>
              <!-- PI language start -->
            </mat-expansion-panel-body>
          </mat-expansion-panel>
          <!-- PI relegion end -->
        </div>
      </div>
    </div>
    <div class="stic-form-btn-wrape" *ngIf="!userInfo.is_hide_personal_info"> 
      <button type="button" (click)="clickOnEdit()" *ngIf="!participantFormStatus" mat-raised-button class="btn"
        color="secoundary">Edit</button>
      <button type="button" (click)="clickOnCancel()" *ngIf="participantFormStatus" mat-raised-button class="btn"
        color="warn">Cancel</button>
      <button type="submit" *ngIf="participantFormStatus" [disabled]="!participantInfoSave" mat-raised-button
        class="btn" color="secoundary">Save</button>
    </div>
  </form>
</div>