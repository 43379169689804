import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TempBrandGenericService } from 'src/app/service/temp-brand-generic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-temp-brand-generic',
  templateUrl: './temp-brand-generic.component.html',
  styleUrls: ['./temp-brand-generic.component.scss'],
})
export class TempBrandGenericComponent implements OnInit {
  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Medicine Name',
      dataKey: 'medicine_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['medicine_name']}`,
    },
  ];
  public isApproved: number = 0;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/temp-brand-generic/curation-pending/';
  public title = 'Curate Drug Brand / Generic / Category';
  private subscriptions = new Subscription();
  public data: any;
  public isCurate: boolean = false;
  public currentMenuPermission: any;

  constructor(
    private tempBrandGenericService: TempBrandGenericService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
  ) {}

  ngOnInit() {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.loading = true;
    const routerSubscription = this.route.queryParams.subscribe(
      (queryParams: any) => {
        this.isApproved = 0;
        this.title = 'Curate Drug Brand / Generic / Category';
        this.getAll();
        this.isCurate =
          this.router.url.indexOf('curation-pending') >= 0 ? true : false;
      }
    );
    this.subscriptions.add(routerSubscription);
  }

  /**
   * Get all Language
   *
   * @returns object
   */
  getAll() {
    this.loading = true;
    const languageSubscription = this.tempBrandGenericService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log(e.error);
        },
        complete: () => {
          this.loading = false;
        },
      });
    this.subscriptions.add(languageSubscription);
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  SortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
