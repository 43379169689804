<div class="tabbing-content-wrape">
    <form [ngClass]="statusTableform ? 'edit-mode' : 'view-mode'">
        <div class="container-fluid">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">Medical Record</h4>
                </div>
                <div class="card-body">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="progress-overlay"></div>
                    <div class="input-wrape upload-pad">
                        <mat-label>Medical Record: </mat-label>
                        <div class="upload-wrape side-info">
                            <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                (change)="onSelectFile($event)" multiple>
                            <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                mat-raised-button class="btn" color="primary"> Upload</button>
                            <div class="info-pad popup-over">
                                <button class="info-icon"></button>
                                <div class="info-section popupcontent">
                                    Please choose .pdf file only
                                </div>
                            </div>
                        </div>
                        <div class="upload-filename choosen_file_link ng-star-inserted" *ngIf="imageFiles.length > 0 ">
                            <a>{{imageFiles.length}} Files selected</a>
                        </div>
                    </div>
                    <mat-error *ngIf="!imageValid">
                        Please select valid file
                    </mat-error>
                    <mat-error *ngIf="!imageValidSize">
                        File must be less then 25 MB
                    </mat-error>
                    <mat-error *ngIf="!maxImageValid">
                        You can upload maximum up to 100 PMRs
                    </mat-error>

                    <div class="row" *ngIf="albums.length>0" style="margin-top: 10px;">
                        <div class="col-xl-3 col-md-3" *ngFor="let report of albums; index as i"
                            id="staticImage_{{ i }}">
                            <div class="input-wrape ">
                                <mat-label>Document {{ i + 1 }}</mat-label>
                                <div class="img-wrape pmr_pdf icon-wrape-preview thumb-upload">
                                    <span mat-button (click)="openDialog(report)" *ngIf="report.fileType != 'pdf'">
                                        <img src="{{ report.thumb }}" alt="Medical Report" class="grid_img">
                                    </span>
                                    <span mat-button *ngIf="report.fileType == 'pdf'">
                                        <a href="{{report.path}}" target="_blank">
                                            <div mat-button class="icon-wrape-preview choosen_file_div">
                                                <span class="icon pdf"></span>
                                            </div>
                                        </a>
                                        <a href="javascript:;" class="theme-icon download-icon medical-record"
                                            title="Download Past Medical Record" (click)="downloadMedicalRecordDoc(report)"></a>
                                    </span>
                                    <a href="javascript:;" class="theme-icon delete-icon" title="Delete Past Medical Record"
                                        (click)="deletePMR(report.name, i)"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data-found" *ngIf="albums.length<=0">
                        <div class="no-data-container">
                            <div class="no-data-icon"></div>
                            <div class="no-data-text">Medical record not found</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickEventTableform()" *ngIf="disableTableform" mat-raised-button class="btn"
                color="secoundary">Edit</button>
            <button type="button" (click)="cancelEventTableform()" *ngIf="enableTableform" mat-raised-button class="btn"
                color="warn">Cancel</button>
            <button type="button" (click)="saveEventTableform()" [disabled]="loading" *ngIf="enableTableform"
                mat-raised-button class="btn" color="secoundary">Save</button>
        </div>
    </form>
</div>