import { AfterViewInit, OnInit, Component, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/interfaces/Role';
import { RoleService } from '../../../../service/role.service';
import { UserService } from '../../../../service/user.service';
import Swal from 'sweetalert2';
import { CommonService } from '../../../../service/common.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements AfterViewInit {
  public role = new FormControl('');
  public roleControl = new FormControl([]);
  public userForm!: FormGroup;
  public title: string;
  public userDetail: any;
  public buttonText: string;
  public roleList!: Role[];
  public disableButton: boolean = false;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private userService: UserService,
    private commonService: CommonService
  ) {
    this.title = 'Add User';
    this.buttonText = 'Add';
  }
  // multi select start
  /**
   * It will initialize all validator
   * @author Dhaval Bera
   */
  ngOnInit() {
    this.userForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      roleName: [null, Validators.required],
      is_hide_personal_info: [false],
    });
    this.getActiveRole();
  }

  ngAfterViewInit() { }

  /**
   * get Active Role
   * @author Dhaval Bera
   */
  getActiveRole() {
    this.roleService.getActiveRole().subscribe({
      next: (result: any) => {
        if (result.data.length > 0) {
          this.roleList = result.data;
        } else {
          this.commonService.showErrorToast('User role is not found!');
        }
      },
      error: (e) => {
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.!'
        );
      },
      complete: () => { },
    });
  }

  /**
   * It will create/update user
   * @author Dhaval Bera
   */
  onSubmit(data: any) {
    if (this.userForm.invalid) {
      this.commonService.validateAllFormFields(this.userForm);
      return;
    }
    this.disableButton = true;
    this.loading = true;
    this.userService.registerUser(data).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          this.disableButton = false;
          this.commonService.showSuccessToast('User added successfully');
          this.router.navigate(['/manage-user']);
        } else {
          this.disableButton = false;
          if (typeof result.validation_data === 'object') {
            for (const key in result.validation_data) {
              if (key === 'email') {
                this.userForm.controls['email'].setErrors({
                  unique: result.validation_data[key],
                });
              } else {
                this.userForm.controls['email'].setErrors({
                  validField: result.validation_data[key],
                });
              }
            }
          }
        }
        this.loading = false;
      },
      error: (e) => {
        this.disableButton = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
        this.loading = false;
      },
      complete: () => { },
    });
  }

  /**
   * @author Dhaval Bera
   * This function is used to check email unique or not
   * @param event
   */
  checkEmailExist(event: any) {
    if (event.target.value !== '') {
      this.userService.checkEmailUnique(event.target.value).subscribe({
        next: (result: any) => {
          if (result.status === 0) {
            this.userForm.controls['email'].setErrors({
              unique: result.message,
            });
          }
        },
        error: (e) => {
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        },
        complete: () => { },
      });
    } else {
      this.userForm.controls['email'].setErrors({
        required: 'Please enter email',
      });
    }
  }

  onRoleRemoved(topping: string) {
    let roleVariable = this.roleControl.value as string[];
    this.removeFirst(roleVariable, topping);
    this.roleControl.setValue(roleVariable);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
    console.log(array);
    this.setRoleOnSelect(array);
  }

  onSelectChange(event: any) {
    this.setRoleOnSelect(event.value);
  }

  setRoleOnSelect(roles: any) {
    let selectedRoleList = [];
    for (let index = 0; index < roles.length; index++) {
      const element = roles[index];
      let selectedRole = this.roleList.filter(
        (role) => role.role_name === element
      );
      selectedRoleList.push(selectedRole[0].id);
    }
    this.userForm.controls['roleName'].setValue(selectedRoleList);
  }

  clearRoles() {
    this.userForm.controls['roleName'].setValue([]);
    this.roleControl.setValue(null);
  }
}
