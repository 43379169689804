import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app.constant';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { OccupationsService } from 'src/app/service/occupations.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-occupations',
  templateUrl: './occupations.component.html',
  styleUrls: ['./occupations.component.scss']
})
export class OccupationsComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    // {
    //   name: 'Source',
    //   dataKey: 'source',
    //   isSortable: true,
    //   cell: (element: Record<string, any>) => `${element['source']}`,
    // },
  ];
  public isApproved: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/occupations/edit/';
  public title = 'Occupations';
  private subscriptions = new Subscription();
  public data: any;
  public currentMenuPermission: any;
  public isCurate: boolean = false;

  constructor(
    private occupationsService: OccupationsService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
  ) { }

  ngOnInit() {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    const routerSubscription = this.route.queryParams.subscribe(
      (queryParams: any) => {
        this.isApproved = 1;
        if (this.router.url.indexOf('curation-pending') >= 0) {
          this.isApproved = 0;
          this.isCurate = true;
          this.editLink = 'dictionary-management/occupations/curation-pending/';
        } else {
          this.isApproved = 1;
          this.isCurate = false;
        }
        this.getAll();

      }
    );
    this.subscriptions.add(routerSubscription);
  }

  /**
   * Get all Occupations
   *
   * @returns object
   */
  getAll() {
    this.loading = true;
    const occupationsSubscription = this.occupationsService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log(e.error);
        },
        complete: () => {
          this.loading = false;
        },
      });
    this.subscriptions.add(occupationsSubscription);
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }

  deleteOccupations(event: any) {
    this.loading = true;
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the occupation?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        const occupationsDeleteSubscription = this.occupationsService
          .delete(event.id)
          .subscribe({
            next: (result: any) => {
              if (result.status === AppConstants.serverSuccessStatus) {
                this.commonService.showSuccessToast(
                  'Occupation deleted successfully'
                );
                this.getAll();
              } else {
                this.commonService.showErrorToast(
                  "Occupation can't be deleted. Please try again later."
                );
              }
              this.loading = false;
            },
            error: (e: any) => {
              this.commonService.showErrorToast('Occupation not found');
              this.loading = false;
            },
          });
        this.subscriptions.add(occupationsDeleteSubscription);
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }

}
