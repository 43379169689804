import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';
import { UniqueIdentityService } from 'src/app/service/unique-identity.service';

@Component({
  selector: 'app-unique-identity',
  templateUrl: './unique-identity.component.html',
  styleUrls: ['./unique-identity.component.scss']
})
export class UniqueIdentityComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
  ];

  public title = 'Unique Identity';
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public data: any;
  public currentMenuPermission: any;

  constructor(
    private uniqueIdentityService: UniqueIdentityService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
   * This function is user to get all User Listing
   * @author Farhan Shaikh
   */
  getAll() {
    this.loading = true;
    this.uniqueIdentityService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('Unique Identity Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  editAction(event: any) {
    this.router.navigate(['dictionary-management/unique-identity/edit/' + event.id]);
  }
  /**
   * call Delete UniqueIdentity api
   * @returns null
   */
  deleteUniqueIdentity(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Unique Identity?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.uniqueIdentityService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                result.message
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Unique Identity not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
