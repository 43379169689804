import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class PincodeService extends CrudService {

  constructor(public override http: HttpClient) {
    super('pincode', http);
  }

  /**
   * Check Pincode field is unique
   *
   * @param string pincodeFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniquePincode(pincodeFieldValue: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique-pincode/${pincodeFieldValue}/${id}`);
  }

   /**
   * Check search Pincode by Pincode
   *
   * @param string pincodeFieldValue
   * @param number id
   *
   * @returns object
   */
   searchPincode(pincode: string) {
    return this.http.get(`${ this.baseUrl }/search?pincode=${ pincode }`);
  }
}
