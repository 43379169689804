import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstants } from './app.constant';
import { MenuService } from './service/menu.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  /**
   *
   * @param authService
   * @param router
   * @author Dhaval Bera
   */
  constructor(private menuService: MenuService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const routingName = route.data['routing'];
    const action = route.data['action'];
    return this.checkPermission(routingName, action);
  }

  /**
   * It will check component wise module access
   * @param routeName
   * @param action
   * @author Dhaval Bera
   */
  checkPermission(routeName: string, action: string) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    const body = new HttpParams()
      .set(`route_name`, '/' + routeName)
      .set(`user_id`, userInfo?.user_id);
    if (this.router.url != '/test') {
      this.menuService.getMenuInfo(body).subscribe({
        next: (result: any) => {
          this.menuService.currentMenu.next(result.data);
          if (result.status === AppConstants.serverSuccessStatus) {
            if (!result.data[action]) {
              this.router.navigate(['/access-denied']);
            }
          } else {
            this.router.navigate(['/access-denied']);
          }
        },
        error: (err) => {},
        complete: () => {},
      });
    }
    return true;
  }
}
