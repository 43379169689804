<div class="page-title-box">
    <h1>Bulk Day Wise Site Assign</h1>
    <ul class="bradcrumb">
        <li>Bulk Day Wise Site Assign</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <form [formGroup]="bulkAssignSiteForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text">Bulk Day Wise Site Assign</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6 margin-b-0">

                                <div class="input-wrape" [ngClass]="displayFieldCss('siteCode')">
                                    <mat-label><i>*</i>Site Code: </mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="siteList" formControlName="siteCode" [hideSelected]="true"
                                            bindValue="id" bindLabel="site_code" placeholder="Select Site Code">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['siteCode'].invalid && (bulkAssignSiteForm.controls['siteCode'].dirty || bulkAssignSiteForm.controls['siteCode'].touched)">
                                        <mat-error *ngIf="bulkAssignSiteForm.controls['siteCode'].errors?.['required']">
                                            Site Code is required
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape" [ngClass]="displayFieldCss('campCounsellor')">
                                    <mat-label><i>*</i>Counsellor:</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="counsellors" formControlName="campCounsellor" bindValue="id"
                                            bindLabel="name" placeholder="Select camp counsellor" [multiple]="true">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['campCounsellor'].invalid && (bulkAssignSiteForm.controls['campCounsellor'].dirty || bulkAssignSiteForm.controls['campCounsellor'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['campCounsellor'].errors?.['required']">
                                            Site Code is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-3 margin-b-0">
                                <div class="input-wrape calendar-wrape" [ngClass]="displayFieldCss('startDate')">
                                    <mat-label><i>*</i>Camp Start Date</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <input matInput [matDatepicker]="pickerStartDate" placeholder="Enter start date"
                                            (click)="pickerStartDate.open()" formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix [for]="pickerStartDate">
                                            <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerStartDate></mat-datepicker>
                                    </mat-form-field>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['startDate'].invalid && (bulkAssignSiteForm.controls['startDate'].dirty || bulkAssignSiteForm.controls['startDate'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['startDate'].errors?.['required']">
                                            Start Date is required
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape half-wrape" [ngClass]="displayFieldCss('campTimingFrom')">
                                    <mat-label><i>*</i>Camp Start Timing: </mat-label>
                                    <div class="container-fluid">
                                        <div class="time-wrape row">
                                            <div class="col-xl-12">
                                                <div class="default-time-example">
                                                    <!-- <span class="deafult-text">From</span> -->
                                                    <input placeholder="From" class="camp-time"
                                                        formControlName="campTimingFrom" aria-label="default time"
                                                        [ngxTimepicker]="fromValue" [value]="'12:00 AM'" readonly>
                                                    <ngx-material-timepicker #fromValue></ngx-material-timepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['campTimingFrom'].invalid && (bulkAssignSiteForm.controls['campTimingFrom'].dirty || bulkAssignSiteForm.controls['campTimingFrom'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['campTimingFrom'].errors?.['required']">
                                            Start Time is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-3 margin-b-0">
                                <div class="input-wrape calendar-wrape" [ngClass]="displayFieldCss('endDate')">
                                    <mat-label><i>*</i>Camp End Date</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <input matInput [matDatepicker]="pickerEndDate" placeholder="Enter end date"
                                            (click)="pickerEndDate.open()" formControlName="endDate">
                                        <mat-datepicker-toggle matSuffix [for]="pickerEndDate">
                                            <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerEndDate></mat-datepicker>
                                    </mat-form-field>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['endDate'].invalid && (bulkAssignSiteForm.controls['endDate'].dirty || bulkAssignSiteForm.controls['endDate'].touched)">
                                        <mat-error *ngIf="bulkAssignSiteForm.controls['endDate'].errors?.['required']">
                                            End Date is required
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape half-wrape" [ngClass]="displayFieldCss('campTimingTo')">
                                    <mat-label><i>*</i>Camp End Timing: </mat-label>
                                    <div class="container-fluid">
                                        <div class="time-wrape row">
                                            <div class="col-xl-12">
                                                <div class="default-time-example">
                                                    <input placeholder="To" class="camp-time"
                                                        formControlName="campTimingTo" aria-label="default time"
                                                        readonly [ngxTimepicker]="toValue" [value]="'11:00 PM'">
                                                    <ngx-material-timepicker #toValue></ngx-material-timepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['campTimingTo'].invalid && (bulkAssignSiteForm.controls['campTimingTo'].dirty || bulkAssignSiteForm.controls['campTimingTo'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['campTimingTo'].errors?.['required']">
                                            End Time is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape" [ngClass]="displayFieldCss('campCoordinator')">
                                    <mat-label><i>*</i>Coordinator: </mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="coordinators" formControlName="campCoordinator"
                                            bindValue="id" bindLabel="name" placeholder="Select camp coordinator"
                                            labelForId="campCoordinator">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['campCoordinator'].invalid && (bulkAssignSiteForm.controls['campCoordinator'].dirty || bulkAssignSiteForm.controls['campCoordinator'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['campCoordinator'].errors?.['required']">
                                            Coordinator is required
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Supervisor (Optional):</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="supervisors" formControlName="campSupervisor" bindValue="id"
                                            bindLabel="name" placeholder="Select camp supervisor">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="input-wrape calendar-wrape" [ngClass]="displayFieldCss('campCoordinator')">
                                    <mat-label><i>*</i>Date - Materials Packed for Camp</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <input matInput [matDatepicker]="pickerMaterialsPackedDate"
                                            placeholder="Enter date materials packed for camp"
                                            (click)="pickerMaterialsPackedDate.open()"
                                            formControlName="materialsPackedDate">
                                        <mat-datepicker-toggle matSuffix [for]="pickerMaterialsPackedDate">
                                            <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerMaterialsPackedDate></mat-datepicker>
                                    </mat-form-field>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['materialsPackedDate'].invalid && (bulkAssignSiteForm.controls['materialsPackedDate'].dirty || bulkAssignSiteForm.controls['materialsPackedDate'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['materialsPackedDate'].errors?.['required']">
                                            Date - materials packed for camp is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape" [ngClass]="displayFieldCss('campPhlebotomist')">
                                    <mat-label><i>*</i>Phlebotomist:</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="phlebotomists" formControlName="campPhlebotomist"
                                            bindValue="id" bindLabel="name" placeholder="Select camp phlebotomist"
                                            [multiple]="true">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['campPhlebotomist'].invalid && (bulkAssignSiteForm.controls['campPhlebotomist'].dirty || bulkAssignSiteForm.controls['campPhlebotomist'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['campPhlebotomist'].errors?.['required']">
                                            Phlebotomist is required
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape" [ngClass]="displayFieldCss('diagnosticLabId')">
                                    <mat-label><i>*</i>Diagnostic Lab: </mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="diagnosticLabs" formControlName="diagnosticLabId"
                                            [hideSelected]="true" bindValue="id" bindLabel="name"
                                            placeholder="Select diagnostic lab">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="bulkAssignSiteForm.controls['diagnosticLabId'].invalid && (bulkAssignSiteForm.controls['diagnosticLabId'].dirty || bulkAssignSiteForm.controls['diagnosticLabId'].touched)">
                                        <mat-error
                                            *ngIf="bulkAssignSiteForm.controls['diagnosticLabId'].errors?.['required']">
                                            Diagnostic Lab is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="/dashboard"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary"
                            title="Assign">Assign</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>