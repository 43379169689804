import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/service/state.service';
import { CountryService } from 'src/app/service/country.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
@Component({
  selector: 'app-state-form',
  templateUrl: './state-form.component.html',
  styleUrls: ['./state-form.component.scss'],
})
export class StateFormComponent implements OnInit {
  public stateForm!: FormGroup;
  public title: any;
  public stateId: any;
  public isAddMode: boolean = true;
  public isCurate: boolean = false;
  public disableSubmitBtn: boolean = false;
  public buttonText: string = '';
  public cancelLink: string = '/dictionary-management/state';
  public userId: any = '';
  public isApproved: number = 1;
  public selectMapOption: boolean = false;
  public showLoading: boolean = false;
  public stateNameMinLength: number = 3;
  public stateNameMaxLength: number = 255;
  public stateCodeMinLength: number = 2;
  public stateCodeMaxLength: number = 7;
  public stateDetails: any = [];
  public stateList: any = [];
  public countryList: any = [];

  constructor(
    private countryService: CountryService,
    private stateService: StateService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    
    this.stateId = (this.route.snapshot.params['id']) ? this.route.snapshot.params['id'] : 0;
    if (this.stateId !== 0 && this.stateId !== null) {
      this.setEditData();
      this.title = 'Edit State';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add State';
      this.buttonText = 'Add';
      this.stateId = 0;
    }
  }

  ngOnInit(): void {
    // Get Country Dictionary
    this.getCountryList();

    this.isAddMode = !this.stateId;
    this.route.queryParams.subscribe((queryParams: any) => {
      if (this.router.url.indexOf('curation-pending') >= 0) {
        this.isCurate = true;
        this.title = 'Curate State';
        this.cancelLink = '/dictionary-management/state/curation-pending';
        // Get State Dictionary
        this.getStateList();
      }
    });

    this.userId = localStorage.getItem('userId');
    this.stateForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.stateNameMinLength),
          Validators.maxLength(this.stateNameMaxLength),
        ],
      ],
      countryId: [
        null,
        [Validators.required, Validators.pattern('^[1-9][0-9]*$')],
      ],
      code:[
        null,
        [
          Validators.minLength(this.stateCodeMinLength),
          Validators.maxLength(this.stateCodeMaxLength)
        ],
      ], 
      isApproved: [this.isApproved, []],
      mapWithOtherState: [null, []],
    });

  }

  /**
   * Get all country for validate State
   * @returns object
   */
  // Get Country Dictionary
  getCountryList() {
    this.countryService.getActiveList().subscribe({
      next: (result: any) => {
        this.countryList = result.data;
      },
      error: (e: any) => {
        console.log(e.error);
      },
    });
  }

  setEditData() {
    this.stateService.getRow(this.stateId).subscribe({
      next: (result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.commonService.showErrorToast('State not found.');
          this.router.navigate([this.cancelLink]);
        } else {
          this.stateDetails = result.data;
          setTimeout(() => {
            this.stateForm.patchValue({
              name: this.stateDetails.name,
              countryId: (this.stateDetails.country_id && this.stateDetails.country_id != '' && this.stateDetails.country_id != '0') ? this.stateDetails.country_id : null,
              code: (this.stateDetails.code && this.stateDetails.code != null && this.stateDetails.code != '') ? this.stateDetails.code : null,
            });
          }, 300);
        }
      },
      error: (e: any) => {
        console.log(e.error);
      },
    });
  }

  getStateNameError() {
    if (this.stateForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.stateForm.controls['name'].errors!['minlength']) {
      return 'Min length is ' + this.stateNameMinLength + ' characters.';
    }
    if (this.stateForm.controls['name'].errors!['maxlength']) {
      return 'Max length is ' + this.stateNameMaxLength + ' characters.';
    }
    if (this.stateForm.controls['name'].errors!['unique']) {
      return this.stateForm.get('name')!.errors!['unique'];
    }
    return '';
  }

  getSelectCountryError() {
    if (this.stateForm.controls['countryId'].errors!['required']) {
      return 'Country is required.';
    }
    if (this.stateForm.controls['countryId'].errors!['pattern']) {
      return 'Please select valid country';
    }
    return '';
  }

  getStateCodeError(){
    if (this.stateForm.controls['code'].errors!['minlength']) {
      return 'Min length is ' + this.stateCodeMinLength + ' characters.';
    }
    if (this.stateForm.controls['code'].errors!['maxlength']) {
      return 'Max length is ' + this.stateCodeMaxLength + ' characters.';
    }
    if (this.stateForm.controls['code'].errors!['unique']) {
      return this.stateForm.get('code')!.errors!['unique'];
    }
    // return 'Please select valid code';
  }

  /**
   * submit form
   * @returns null
   */
  onSubmit(): void {
    if (this.stateForm.invalid) {
      this.commonService.validateAllFormFields(this.stateForm);
      this.commonService.showErrorToast('OOPS! Please enter correct values');
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.stateForm.controls['name'].value);
    formData.append('country_id', this.stateForm.controls['countryId'].value);
    formData.append('code', (this.stateForm.controls['code'].value != null) ? this.stateForm.controls['code'].value : '');
    formData.append('is_approved', this.stateForm.controls['isApproved'].value);
    formData.append(
      'map_with_other_state',
      this.stateForm.controls['mapWithOtherState'].value
        ? this.stateForm.controls['mapWithOtherState'].value
        : 0
    );
    if (this.isAddMode) {
      this.createState(formData);
    } else {
      this.updateState(formData);
    }
  }

  /**
   * call Create State api
   * @returns null
   */
  private createState(formData: any): void {
    this.stateService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(['/dictionary-management/state']);
          this.commonService.showSuccessToast(result.message);
        } else {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update State api
   * @returns null
   */
  private updateState(formData: any): void {
    formData.append('updated_by', this.userId);
    this.stateService.update(formData, this.stateId).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate([this.cancelLink]);
          this.commonService.showSuccessToast(result.message);
        } else {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * Select Approved Option
   *
   * @returns object
   */
  selectOption(value: boolean) {
    this.selectMapOption = value;
    if (value) {
      this.stateForm
      .get('mapWithOtherState')!
      .setValidators([
        Validators.required,
        Validators.pattern('^[1-9][0-9]*$'),
      ]);
      this.stateForm.controls['countryId'].disable();
    } else {
      this.stateForm.controls['countryId'].enable();
      this.stateForm.patchValue({
        countryId: (this.stateDetails.country_id && this.stateDetails.country_id != '' && this.stateDetails.country_id != '0') ? this.stateDetails.country_id : null,
        code: (this.stateDetails.code && this.stateDetails.code != null && this.stateDetails.code != '') ? this.stateDetails.code : null,
        mapWithOtherState: null
      });   
      this.stateForm.get('mapWithOtherState')!.setValidators(null);
    }
    this.stateForm.controls['mapWithOtherState'].updateValueAndValidity();
    this.getMapWithStateError();
  }

  getMapWithStateError() {
    if (this.stateForm.controls['mapWithOtherState'].invalid) {
      if (this.stateForm.controls['mapWithOtherState'].errors!['required']) {
        return 'Select state';
      }
      if (this.stateForm.controls['mapWithOtherState'].errors!['pattern']) {
        return 'Please select valid state';
      }
    }
    return '';
  }

  /**
   * Check state field is existed or not
   * @returns null
   */
  checkUniqueStateFields(stateFieldName: string, event: any) {
    const stateFieldValue = (event.target as HTMLInputElement).value;

    switch (stateFieldName) {
      case "name":
        if ( 
            stateFieldValue.length < this.stateNameMinLength || 
            stateFieldValue.length > this.stateNameMaxLength
          ) {
          return false;
        }
        break;

      case "code":
        if ( 
            stateFieldValue.length < this.stateCodeMinLength || 
            stateFieldValue.length > this.stateCodeMaxLength
          ) {
          return false;
        }
        break;
    }

    if (stateFieldValue !== '') {
      this.stateService
        .checkUniqueStateFields(stateFieldName, stateFieldValue, this.stateId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.stateForm.get(stateFieldName)!.setErrors(null);
            } else {
              this.stateForm
                .get(stateFieldName)!
                .setErrors({ unique: stateFieldName + ' already exists' });
            }
          },
          error: (error: any) => {
            if (error.status === 400) {
              this.stateForm
                .get(stateFieldName)!
                .setErrors({ unique: stateFieldName + ' already exists' });
            } else {
              console.log(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
        });
    }
    return null;
  }

  /**
   * Get all state for map with State
   * @returns object
   */
  // Get State Dictionary
  getStateList() {
    this.stateService.getActiveList().subscribe({
      next: (result: any) => {
        this.stateList = result.data;
      },
      error: (e: any) => {
        console.log(e.error);
      },
    });
  }

  mapWithStateChange(event: any){
    if(event && event.id){
      this.stateService.getRow(event.id)
      .subscribe({
      next: (result: any) => {
        if (result.status == AppConstants.serverSuccessStatus && result.data) {
          setTimeout(() => {
            this.stateForm.patchValue({
              countryId: result.data.country_id,
              code: (result.data.code && result.data.code != 'null') ? result.data.code : '',
            });
          }, 200); 
        }
      },
      error: (e: any) => {
        console.log(
          e.error
          );
        },
      });
    } else {
      this.stateForm.patchValue({
        code: '',
        countryId: null
      });        
    }
  }
}
