<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form [ngClass]="diseaseFamilyHistoryFormStatus ? 'edit-mode' : 'view-mode'" id="diseaseFamilyHistoryForm"
        [formGroup]="diseaseFamilyHistoryForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-5">
                    <div class="card-wrape">
                        <div class="card-head">
                            <h4 class="left-text"> Family Medical History</h4>
                        </div>
                        <div class="card-body">
                            <div class="input-wrape">
                                <mat-label>Select Family Member:</mat-label>
                                <div class="control-pad">
                                    <ng-select formControlName="family_member" placeholder="Enter family member">
                                        <ng-option *ngFor="let member of familyList" [value]="member">{{ member |
                                            titlecase}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <h6>Participant Diseases</h6>
                            <div class="input-wrape">
                                <div class="checkbox-section">
                                    <mat-checkbox  id="pd_checkbox_{{ disease.id }}" (change)="selectParticpantDiseases($event)" value="{{disease.id}}" name="{{disease.display_term}}" *ngFor="let disease of participantDiseasesList;" [checked]="diseaseFamilyHistoryForm.controls['disease_ids'].value?.indexOf(disease.id) >= 0">{{disease.display_term}}</mat-checkbox>
                                    <mat-checkbox (change)="selectOthers($event.checked)" [checked]="familyOtherdiseases">Other Diseases</mat-checkbox>
                                </div>
                            </div>

                            <div class="input-wrape tree one" id="contactOptionsGroup" *ngIf="familyOtherdiseases">
                                <mat-label>Name of Disease:</mat-label>
                                <div class="control-pad">
                                    <ng-select [items]="diseasesList" placeholder="Select Diseases" (change)="diseasesSelect($event)"
                                        bindLabel="display_term" bindValue="id" formControlName="is_other_diseases"
                                        [multiple]="true">
                                    </ng-select>
                                </div>
                                <i class="ruler"></i>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button mat-raised-button class="btn" color="secoundary" *ngIf="!fdUpdate" (click)="addFD()" [disabled]="diseaseFamilyHistoryForm.controls['family_member'].value == null">Add</button>
                            <button mat-raised-button class="btn" color="secoundary" *ngIf="fdUpdate" (click)="updateFD()" [disabled]="diseaseFamilyHistoryForm.controls['family_member'].value == null">Update</button>
                        </div>
                    </div>
                </div>

                <div class="col-xl-7">
                    <div class="card-wrape">
                        <div class="card-head">
                            <h4 class="left-text"> Family Medical History List</h4>
                        </div>
                        <div class="card-body">
                            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                <ng-container matColumnDef="family_member">
                                    <mat-header-cell *matHeaderCellDef>Family Member </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.family_member | titlecase}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="disease_names">
                                    <mat-header-cell *matHeaderCellDef>Participant/Other Diseases </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="cell-inline-block">
                                        {{element.disease_names}}
                                        <i>{{element.is_other_diseases_names}}</i>                                        
                                        </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                    <mat-cell *matCellDef="let element;let i = index;">
                                        <div class="icons-wrape">
                                            <a (click)="editFD(i)" class="theme-icon edit-icon"
                                                title="Edit Family Member Detail"></a>
                                            <a (click)="deleteFD(i)" class="theme-icon delete-icon"
                                                title="Delete Family Member Detail"></a>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                <div *matNoDataRow>No records found</div>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickOnEdit()" *ngIf="!diseaseFamilyHistoryFormStatus" mat-raised-button
                class="btn" color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="diseaseFamilyHistoryFormStatus" mat-raised-button
                class="btn" color="warn">Cancel</button>
            <button type="button" [disabled]="!fdAdd && !fdUpdate" (click)="familyDiseaseSubmit()" *ngIf="diseaseFamilyHistoryFormStatus"
                mat-raised-button class="btn" color="secoundary">Save</button>
        </div>
    </form>
</div>