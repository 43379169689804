<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>{{title}}</h1>
        <ul class="bradcrumb">
            <li>{{title}}</li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>

    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="stat-wrape four m-b-0">
                    <div class="stat-pad">
                        <p>Re-Classified</p>
                        <h2><i class="theme-icon camp-held"></i> {{reclassificationStats?.reclassifiedCount}}</h2>
                    </div>
                    <div class="stat-pad">
                        <p>No Need To Re-Classify</p>
                        <h2><i class="theme-icon participant-enroll"></i>
                            {{reclassificationStats?.noNeedToReclassifiedCount}}</h2>
                    </div>
                    <div class="stat-pad">
                        <p>Non Re-Classifiable</p>
                        <h2><i class="theme-icon couselor"></i> {{reclassificationStats?.nonReClassifiableCount}}</h2>
                    </div>
                    <div class="stat-pad">
                        <p>Low Probability</p>
                        <h2><i class="theme-icon couselor"></i> {{reclassificationStats?.lowProbabilityCount}}</h2>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text"> Population Re-Classification Filters </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="input-wrape">
                                    <mat-label>Stages of Curation</mat-label>
                                    <div class="control-pad">
                                        <ng-select appendTo="body" (change)="changePopulationFilter($event)"
                                            bindLabel="label" bindValue="value" [dropdownPosition]="'bottom'"
                                            [searchable]="true" [multiple]="false" [closeOnSelect]="true"
                                            [items]="reclassificationFilters" [(ngModel)]="selectedPopulationFilter"
                                            placeholder="Please select"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="input-wrape">
                                    <mat-label>Site Code(s)</mat-label>
                                    <div class="control-pad">
                                        <ng-select appendTo="body" (change)="changeSiteCodeFilter()"
                                            bindLabel="site_code" bindValue="id" [dropdownPosition]="'bottom'"
                                            [searchable]="true" [multiple]="true" [items]="sites"
                                            placeholder="Select site code" [(ngModel)]="siteCodesFilter"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 button-controller ">
                                <button (click)="updateQCed()" mat-raised-button class="btn" color="secoundary"
                                    *ngIf="selectedStage == 'qc_pending'">Mark As QC'ed</button>
                                <button (click)="updateReviewed()" mat-raised-button class="btn" color="secoundary"
                                    *ngIf="selectedStage == 'review_pending'">Mark As Reviewed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-12">
                <!-- table expand code start -->
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="card-wrape">
                            <mat-spinner *ngIf="loading"></mat-spinner>
                            <div class="progress-overlay"></div>
                            <div class="card-head">
                                <h4 class="left-text"> Population Re-Classification List </h4>
                                <div class="right-control">

                                    <div class="table-search-wrape side-info">
                                        <span class="icon"></span>
                                        <input (keyup)="searchFilter($event)" placeholder="Search" #input
                                            class="form-control" type="text">
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                New PID, Site Code, First Name, Middle Name, Last Name, Gender, Age
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body table-section">
                                <div class="table-wrape">
                                    <table mat-table [dataSource]="dataSources" multiTemplateDataRows
                                        class="mat-elevation-z8" #expandSort="matSort" matSort
                                        (matSortChange)="SortFilter($event)">
                                        <ng-container matColumnDef="expand">
                                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
                                            <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button aria-label="expand row"
                                                    (click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
                                                    <mat-icon
                                                        *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                                    <mat-icon
                                                        *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selectedParticipants.hasValue() && isAllSelected()"
                                                    [indeterminate]="selectedParticipants.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selectedParticipants.toggle(element) : null"
                                                    [checked]="selectedParticipants.isSelected(element)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="site_code">
                                            <th mat-header-cell *matHeaderCellDef>Site Code</th>
                                            <td mat-cell *matCellDef="let element">{{element.site_code}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="new_pid">
                                            <th mat-header-cell *matHeaderCellDef>New PID</th>
                                            <td mat-cell *matCellDef="let element">{{element.new_pid}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="first_name">
                                            <th mat-header-cell *matHeaderCellDef>First Name</th>
                                            <td mat-cell *matCellDef="let element">{{element.first_name}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="middle_name">
                                            <th mat-header-cell *matHeaderCellDef>Middle Name</th>
                                            <td mat-cell *matCellDef="let element">{{element.middle_name}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="last_name">
                                            <th mat-header-cell *matHeaderCellDef>Last Name</th>
                                            <td mat-cell *matCellDef="let element">{{element.last_name}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="gender">
                                            <th mat-header-cell *matHeaderCellDef>Gender</th>
                                            <td mat-cell *matCellDef="let element">{{element.gender | titlecase }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="age">
                                            <th mat-header-cell *matHeaderCellDef>Age</th>
                                            <td mat-cell *matCellDef="let element">{{element.age}}</td>
                                        </ng-container>

                                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                        <ng-container matColumnDef="expandedDetail">
                                            <td mat-cell *matCellDef="let element;"
                                                [attr.colspan]="columnsToDisplayWithExpand.length">
                                                <div class="example-element-detail"
                                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                                    <div class="example-element-position"> </div>
                                                    <div class="inner-bg-gray">
                                                        <div class="col-xl-6">
                                                            <div class="row-view">
                                                                <p>
                                                                    <b>Native City</b>
                                                                    <span>{{element.native_city}}</span>
                                                                </p>
                                                                <p>
                                                                    <b>Religion</b>
                                                                    <span>{{element.religion}}</span>
                                                                </p>
                                                                <p>
                                                                    <b> Mother Tongue </b>
                                                                    <span>{{element.mother_tongue}}</span>
                                                                </p>
                                                                <p>
                                                                    <b> Sample Collection Date </b>
                                                                    <span>{{element.sample_collection_date}}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="row-view">
                                                                <p>
                                                                    <b> Occupations Name </b>
                                                                    <span>{{element.occupations_name}}</span>
                                                                </p>
                                                                <p>
                                                                    <b> Native State </b>
                                                                    <span>{{element.native_state}}</span>
                                                                </p>
                                                                <p>
                                                                    <b> Declared Sub-population </b>
                                                                    <span>{{element.sub_population}}</span>
                                                                </p>
                                                                <p>
                                                                    <b> Accountable subpopulation (Priority:
                                                                        Re-classified>Declared) </b>
                                                                    <span>{{element.accountable_subpopulation}}</span>
                                                                </p>

                                                            </div>
                                                        </div>

                                                        <div class="form-area">
                                                            <div class="head-part">
                                                                <h2>Add other data in Population Re-Classifications
                                                                </h2>
                                                                <div class="icons-wrape">
                                                                    <button type="button" (click)="clickEvent(element)"
                                                                        class="edit-icon" title="Edit"
                                                                        *ngIf="!element.editing"></button>
                                                                    <button class="delete-icon close-icon"
                                                                        title="Cancel" (click)="cancelEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                    <button class="save-icon" title="Save"
                                                                        (click)="saveEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                </div>
                                                            </div>
                                                            <div class="form-pad"
                                                                [ngClass]="element.editing ? 'enable' : 'disable'">
                                                                <div class="row">
                                                                    <div class="col-xl-4 col-md-4">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Re-classified Native State
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline">
                                                                                <input matInput #input
                                                                                    placeholder="Enter re-classicified native state"
                                                                                    type="text"
                                                                                    [(ngModel)]="element.re_classified_native_state">
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-4 col-md-4">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Re-classified Sub-population
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline">
                                                                                <input matInput #input
                                                                                    placeholder="Enter re-classified sub-population"
                                                                                    type="text"
                                                                                    [(ngModel)]="element.reclassified_population">
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-4 col-md-4">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Flag </mat-label>
                                                                            <div class="control-pad">
                                                                                <ng-select appendTo="body"
                                                                                    bindLabel="label" bindValue="value"
                                                                                    [dropdownPosition]="'bottom'"
                                                                                    [searchable]="true"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [items]="flag"
                                                                                    [(ngModel)]="element.reclassified_pop_flag"
                                                                                    placeholder="Please select flag">
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                                        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                                            class="example-element-row"
                                            [class.example-expanded-row]="expandedElement === element"
                                            (click)="expandedElement = expandedElement === element ? undefined : element">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                            class="example-element-row example-detail-row"></tr>
                                        <tr mat-row>
                                            <td *matNoDataRow colspan="8">{{matNoDataRowTitle}}</td>
                                        </tr>
                                    </table>
                                    <mat-paginator #expandpagination [length]="totalRows" [pageIndex]="currentPage"
                                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                                        (page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons>
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- table expand code end -->
            </div>
        </div>
    </section>
</section>