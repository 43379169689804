import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';


export interface SampleTestTable { }
let ELEMENT_DATA: SampleTestTable[] = [];
@Component({
  selector: 'app-sample-test-information',
  templateUrl: './sample-test-information.component.html',
  styleUrls: ['./sample-test-information.component.scss']
})
export class SampleTestInformationComponent implements OnInit {
  public statusTableForm: boolean = false;
  public disableTableForm: boolean = true;
  public enableTableForm: boolean = false;
  public participantID: any;
  public displayedColumns: string[] = ['sample_type', 'no_of_samples_collected', 'sample_unit_type', 'date_of_collection', 'type', 'action'];
  public dataSource = new MatTableDataSource(ELEMENT_DATA);
  public loading: boolean = false;
  public sampleForm!: FormGroup;
  public sampleDetails: any = [];
  public sampleDetailForm!: FormGroup;
  public togglePopup: boolean = false;
  public sampleTypeList: any;
  public sampleUnitTypeList: any;
  public hideTestInformationSection = false;
  public hideTestInformationArray: any[] = [];
  public sampleTypeOptions: any[] = [];
  public sampleItems: any[] = [];
  public sampleUpdate: boolean = false;
  public sampleAdd: boolean = false;
  public majorTestList: any;
  public minorTestsList: any;
  public maxDate = new Date();
  public sampleUpdateIndex: any;

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder,
    private titlecasePipe: TitleCasePipe,
    private datePipe: DatePipe
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.sampleForm = new FormGroup({});
    this.sampleForm.setControl('sample_information_available', new FormControl(null, [Validators.required]));
    this.sampleForm.setControl('last_meal_since', new FormControl(null, []));
    this.sampleForm.setControl('full_body_profile', new FormControl(null, []));
    this.sampleForm.setControl('infectious_test', new FormControl(null, []));
    this.sampleForm.setControl('sample_info', new FormArray([]));
    this.sampleForm.setControl('major_test', new FormArray([]));
    this.sampleForm.setControl('minor_test', new FormArray([]));

    this.sampleDetailForm = this.formBuilder.group({
      type: [null, [Validators.required]],
      sample_type: [null, [Validators.required]],
      sample_unit_type: [null, [Validators.required]],
      date_of_collection: [null, [Validators.required]],
      no_of_samples_collected: [null, [Validators.required]],
    });
    this.sampleTypeList = JSON.parse(localStorage.getItem('constants') as string).SAMPLE_TYPES;
    this.sampleUnitTypeList = JSON.parse(localStorage.getItem('constants') as string).SAMPLE_UNIT_TYPES;
    // minorTestsList List Dictionary
    this.dictionaryService.getMinorTestListDictionary().subscribe((result: any) => {
      this.minorTestsList = result.data;
    });
    // majorTestList List Dictionary
    this.dictionaryService.getMajorTestListDictionary().subscribe((result: any) => {
      this.majorTestList = result.data;
    });
    this.sampleItems = this.participantService.getSampleItems();
    this.getSampleTestInfoList();
  }

  getSampleTestInfoList() {
    this.loading = true;
    this.participantService.getSampleTestInfoList(this.participantID).subscribe({
      next: (result: any) => {
        this.sampleDetails = result.data;
        let sample_information_available = '';
        if (this.sampleDetails?.sample_information_available == null || this.sampleDetails?.sample_information_available == '') {
          sample_information_available = 'no'
        } else {
          sample_information_available = this.sampleDetails?.sample_information_available
        }
        this.sampleForm.controls['sample_information_available'].setValue(sample_information_available);
        let full_body_profile = '';
        if (this.sampleDetails?.full_body_profile == 'Y' || this.sampleDetails?.full_body_profile == 'yes') {
          full_body_profile = 'yes';
        }
        if (this.sampleDetails?.full_body_profile == 'N' || this.sampleDetails?.full_body_profile == 'no') {
          full_body_profile = 'no';
        }
        let infectious_test = '';
        if (this.sampleDetails?.infectious_test == 'Y' || this.sampleDetails?.infectious_test == 'yes') {
          infectious_test = 'yes';
        }
        if (this.sampleDetails?.infectious_test == 'N' || this.sampleDetails?.infectious_test == 'no') {
          infectious_test = 'no';
        }
        this.sampleForm.controls['full_body_profile'].setValue(full_body_profile);
        this.sampleForm.controls['infectious_test'].setValue(infectious_test);
        this.sampleForm.controls['last_meal_since'].setValue(this.sampleDetails?.last_meal_since);
        if (this.sampleDetails?.major_test != undefined && this.sampleDetails?.major_test.length > 0) {
          this.majorTestList?.forEach((test: { id: any; checked: boolean; }) => {
            if (this.sampleDetails?.major_test.find((savedTest: any) => savedTest == test.id)) {
              test.checked = true;
              this.sampleForm.controls['major_test'].value.push(test.id);
            }
          });
        }
        if (this.sampleDetails?.minor_test != undefined && this.sampleDetails?.minor_test.length > 0) {
          this.minorTestsList?.forEach((test: { id: any; checked: boolean; }) => {
            if (this.sampleDetails?.minor_test.find((savedTest: any) => savedTest == test.id)) {
              test.checked = true;
              this.sampleForm.controls['minor_test'].value.push(test.id);
            }
          });
        }
        this.dataSource = new MatTableDataSource(result.data.sample_info);
        this.hideTestInformationSection = this.sampleDetails.is_hide_test_information;
        this.sampleForm.controls['sample_info'].setValue([]);
        const addForm = this.sampleForm.get('sample_info') as FormArray;
        this.sampleDetails.sample_info.forEach((sample: any) => {
          let newFormGroup = new FormGroup({
            sample_type: new FormControl(sample.sample_type !== "" ? sample.sample_type : null, [Validators.required]),
            type: new FormControl(sample.type !== "" ? sample.type : null, [Validators.required]),
            sample_unit_type: new FormControl(sample.sample_unit_type !== "" ? sample.sample_unit_type : null, [Validators.required]),
            date_of_collection: new FormControl(sample.date_of_collection !== "" ? sample.date_of_collection : null, [Validators.required]),
            no_of_samples_collected: new FormControl(sample.no_of_samples_collected !== "" ? sample.no_of_samples_collected : null, [Validators.required]),
          });
          addForm.push(newFormGroup);
        });
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Disease err :: ', e);
      }
    })
  }

  /**
   * @author Dhaval BEra
   * This function is used to get add value based on index
   * @param id 
   */
  addSampleTest() {
    if (this.sampleDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.sampleDetailForm);
      return;
    }

    const addForm = this.sampleForm.get('sample_info') as FormArray;
    let newFormGroup = new FormGroup({
      sample_type: new FormControl(this.sampleDetailForm.controls['sample_type'].value !== "" ? this.sampleDetailForm.controls['sample_type'].value : null, [Validators.required]),
      type: new FormControl(this.sampleDetailForm.controls['type'].value !== "" ? this.sampleDetailForm.controls['type'].value : null, [Validators.required]),
      sample_unit_type: new FormControl(this.sampleDetailForm.controls['sample_unit_type'].value !== "" ? this.sampleDetailForm.controls['sample_unit_type'].value : null, [Validators.required]),
      date_of_collection: new FormControl(this.sampleDetailForm.controls['date_of_collection'].value !== "" ?
        this.commonService.transformDate( this.sampleDetailForm.controls['date_of_collection'].value) : null, [Validators.required]),
      no_of_samples_collected: new FormControl(this.sampleDetailForm.controls['no_of_samples_collected'].value !== "" ? this.sampleDetailForm.controls['no_of_samples_collected'].value : null, [Validators.required]),
    });
    addForm.push(newFormGroup);
    this.dataSource = new MatTableDataSource(this.sampleForm.controls['sample_info']?.value);
    this.togglePopup = !this.togglePopup;
    this.hideSampleTestInformation();
  }
  /**
   * @author Dhaval BEra
   * This function is used to get updated value based on index
   * @param id 
   */
  editSampleTest(id: any) {
    this.sampleUpdateIndex = id;
    this.sampleUpdate = true;
    this.togglePopup = !this.togglePopup;
    const sample_type = (<FormArray>this.sampleForm.get("sample_info")).at(id).get('sample_type')?.value;
    const type = (<FormArray>this.sampleForm.get("sample_info")).at(id).get('type')?.value;
    const sample_unit_type = (<FormArray>this.sampleForm.get("sample_info")).at(id).get('sample_unit_type')?.value;
    const date_of_collection = (<FormArray>this.sampleForm.get("sample_info")).at(id).get('date_of_collection')?.value;
    const no_of_samples_collected = (<FormArray>this.sampleForm.get("sample_info")).at(id).get('no_of_samples_collected')?.value;
    this.sampleDetailForm.controls['sample_type'].setValue(sample_type);
    this.sampleDetailForm.controls['type'].setValue(type);
    this.sampleDetailForm.controls['sample_unit_type'].setValue(sample_unit_type);
    this.sampleDetailForm.controls['date_of_collection'].setValue(date_of_collection);
    this.sampleDetailForm.controls['no_of_samples_collected'].setValue(no_of_samples_collected);
  }

  submitSampleInfoForm() {
    this.loading = true;
    this.participantService.updateSampleInfo(this.sampleForm.value, this.participantID).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          this.commonService.showSuccessToast('Participant updated successfully.');
          this.statusTableForm = !this.statusTableForm;
          this.disableTableForm = true;
          // this.enableTableForm = false;
        } else {
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
        this.loading = false;
      },
      error: (e: any) => {
        this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        this.loading = false;
      },
      complete: () => { },
    });
  }

  /**
   * @author Dhaval Bera
   * This function is used to update Medication update form value
   */
  updateSample() {
    if (this.sampleDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.sampleDetailForm);
      return;
    }
    const updateForm = (<FormArray>this.sampleForm.get("sample_info")).at(this.sampleUpdateIndex);
    updateForm.patchValue({
      sample_type: this.sampleDetailForm.controls['sample_type']?.value,
      type: this.sampleDetailForm.controls['type']?.value,
      sample_unit_type: this.sampleDetailForm.controls['sample_unit_type']?.value,
      date_of_collection: this.sampleDetailForm.controls['date_of_collection']?.value,
      no_of_samples_collected: this.sampleDetailForm.controls['no_of_samples_collected']?.value,
    });
    this.dataSource = new MatTableDataSource(this.sampleForm.controls['sample_info']?.value);
    this.togglePopup = !this.togglePopup;
    this.sampleDetailForm.reset();
    this.hideSampleTestInformation();
  }

  sampleDelete(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Sample Test?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (<FormArray>this.sampleForm.get("sample_info"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.sampleForm.controls['sample_info'].value);
        this.hideSampleTestInformation();
      }
    });
  }


  hideSampleTestInformation() {
    const sampleTypeList = this.sampleTypeList;
    const sampleTypeInformation = this.sampleForm.get('sample_info')?.value;

    let index = 0;
    if (sampleTypeInformation.length > 0) {
      for (const sampleType of sampleTypeInformation) {
        const sampleTypeId = sampleType.sample_type;
        for (const sampleTypeObject of sampleTypeList) {
          if (sampleTypeObject === sampleTypeId) {
            this.changeSampleType(sampleTypeObject, index);
          }
        }
        index = index + 1;
      }
    }
  }

  changeSampleType(event: any, index: number) {
    if (typeof event === "object") {
      this.sampleTypeOptions[index] = event;
    } else {
      this.sampleTypeOptions[index] = event;
    }
    if (this.sampleTypeOptions.length > 0) {
      this.sampleTypeOptions.forEach(
        (item: any, i: any) => {
          if (item === "tissue block" || item === "tissue slide" || item === "tissue section") {
            this.hideTestInformationArray[i] = "yes";
          } else {
            this.hideTestInformationArray[i] = "no";
          }
        }
      );
    }
    if (this.hideTestInformationArray.length > 0) {
      if (this.hideTestInformationArray.indexOf("no") !== -1) {
        this.hideTestInformationSection = false;
      } else {
        this.hideTestInformationSection = true;
      }
    }
  }

  /**
  * @author Dhaval Bera
  * @param field
  */
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field)
    };
  }

  /**
   * @author Dhaval Bera
   * This function is used to submit Form Details
   * @param field
   */
  isFieldValid(field: string) {
    return (
      (this.sampleDetailForm.controls[field].invalid && this.sampleDetailForm.controls[field].dirty) ||
      (this.sampleDetailForm.controls[field].touched && this.sampleDetailForm.controls[field].invalid)
    );
  }

  clickEventTableForm() {
    this.statusTableForm = !this.statusTableForm;
    this.disableTableForm = false;
    this.enableTableForm = true;
    this.sampleAdd = true;
    this.sampleUpdate = false;
  }

  cancelEventTableForm() {
    this.statusTableForm = !this.statusTableForm;
    this.disableTableForm = true;
    this.enableTableForm = false;
    this.sampleAdd = false;
    this.sampleUpdate = false;
    this.sampleDetailForm.reset();
  }

  // toggle class code start
  closePopup() {
    this.sampleDetailForm.reset();
    this.togglePopup = !this.togglePopup;
    this.sampleAdd = false;
    this.sampleUpdate = false;
  }
  openSample() {
    this.sampleDetailForm.reset();
    this.togglePopup = !this.togglePopup;
    this.sampleAdd = true;
    this.sampleUpdate = false;
  }
  testSelection(event: any, fieldName: string) {
    if (event.checked) {
      this.sampleForm.controls[fieldName].value.push(Number(event.source.value));
    } else {
      this.deleteTestSelect(this.sampleForm.controls[fieldName].value, Number(event.source.value));
    }
  }
  deleteTestSelect(data: any, value: number) {
    const index = data.indexOf(value);
    if (index !== -1) {
      data.splice(index, 1);
    }
  }
  removeUnder(sample_for: any) {
    if (sample_for == null || sample_for == '') {
      return '';
    }
    return this.titlecasePipe.transform(sample_for.replace('_', " "));
  }

  /**
    *
    * @param event
    */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    if (event.target.value.length > 1) {
      event.preventDefault();
    }
  }
}
