<div class="page-title-box">
    <h1>Manage {{title}}</h1>
    <ul class="bradcrumb">
        <li>Manage {{title}}</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<div class="stat-wrape four">
    <div class="stat-pad">
        <p>SITES</p>
        <h2><i class="theme-icon camp-held"></i> {{totalSites}}</h2>
    </div>
    <div class="stat-pad">
        <p>COORDINATORS</p>
        <h2><i class="theme-icon participant-enroll"></i> {{totalCoordinator}}</h2>
    </div>
    <div class="stat-pad">
        <p>COUNSELLORS</p>
        <h2><i class="theme-icon couselor"></i> {{totalCounsellor}}</h2>
    </div>
    <div class="stat-pad">
        <p>PHLEBOTOMISTS</p>
        <h2><i class="theme-icon couselor"></i> {{totalPhlebotomist}}</h2>
    </div>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <mat-spinner *ngIf="loading"></mat-spinner>
                <div class="progress-overlay"></div>
                <app-table [tableData]="siteList" [tableColumns]="displayedColumns" [isFilterable]="true"
                    [isPageable]="true" [paginationSizes]="pageSizeOptions" [defaultPageSize]="10"
                    [totalRecord]="totalRecord" [totalRows]="totalRows" [pageIndex]="currentPage"
                    [rowActionIcon]="'action'" (sort)="SortFilter($event)" (editAction)="editAction($event)"
                    (viewAction)="viewAction($event)" (pageChanged)="pageChanged($event)"
                    (searchFilter)="searchFilter($event)" [pageTitle]="title"
                    [currentMenuPermission]="currentMenuPermission">
                </app-table>
            </div>
        </div>
    </div>
</section>