import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DiagnosticLabsService } from 'src/app/service/diagnosticLabs.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-diagnostic-lab',
  templateUrl: './diagnostic-lab.component.html',
  styleUrls: ['./diagnostic-lab.component.scss']
})
export class DiagnosticLabComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Branch Code',
      dataKey: 'branch_code',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['branch_code']}`,
    },
    {
      name: 'State',
      dataKey: 'state',
      isSortable: false,
      is_relation: true,
      relationFieldName: 'name',
      cell: (element: Record<string, any>) => `${element}`,
    },
    {
      name: 'City',
      dataKey: 'city',
      isSortable: false,
      is_relation: true,
      relationFieldName: 'name',
      cell: (element: Record<string, any>) => `${element}`,
    },
    {
      name: 'Street',
      dataKey: 'street',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['street']}`,
    },
    {
      name: 'Contact Person Name',
      dataKey: 'contact_person_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['contact_person_name']}`,
    },
    {
      name: 'Contact Person Phone Number',
      dataKey: 'contact_person_phone_no',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['contact_person_phone_no']}`,
    },
  ];

  public currentPage = 0;
  public data: any;
  public editLink: string = 'site/diagnostic-lab/edit/';
  public filterValue = '';
  public isApproved: number = 1;
  public loading: boolean = false;
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public pageSize = 10;
  public sortField = '';
  public sortOrder = '';
  public title = 'Diagnostic Lab';
  public totalRows = 0;
  public currentMenuPermission: any;

  constructor(
    private diagnosticLabsService: DiagnosticLabsService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
   * This function is used to get all diagnosticLabs Listing
   * @author Farhan Shaikh
   */
  getAll() {
    this.loading = true;
    this.diagnosticLabsService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('diagnosticLabs Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }
  /**
   * call Create Organ site api
   * @returns null
   */
  deleteDiagnosticLabs(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Diagnostic Lab?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.diagnosticLabsService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                result.message
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('diagnosticLabs not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  SortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }

}
