import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
import { environment } from './../../environments/environment';
import { AppError } from '../shared/error-handlers/app-error';
import { BadInputError } from '../shared/error-handlers/bad-input-error';
import { NotFoundError } from '../shared/error-handlers/not-found-error';
import { catchError } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root',
// })

export class CrudService {

  baseUrl: string = `${environment.baseURL}`;
  cronUrl: string = `${environment.cronURL}`

  constructor(private url: string, public http: HttpClient) {
    this.baseUrl = this.baseUrl + url;
  }

  /**
   * Get all records
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @returns object
   */
  getAll(isApproved: number = 1) {
    return this.http.get<any[]>(`${this.baseUrl}?isApproved=` + isApproved)
      .pipe(catchError(this.handleError));
  }

  /**
   * This function is used to get record by filter
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param number pageNo
   * @param string sortField
   * @param string sortOrder
   * @param string globalField
   * @param number rows
   *
   * @returns object
   */
  getRecordByFilter(pageNo: number, sortField: string, sortOrder: string, globalFilter: string, rows: number, isApproved: number = 1) {

    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';

    if (!Number.isNaN(pageNo) && pageNo != null) {
      page = '&page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }

    if (rows !== 0) {
      perPage = '&perPage=' + rows;
    }
    const finalFilter = page + sortFieldName + globalSearch + perPage;

    return this.http.get(`${this.baseUrl}?isApproved=` + isApproved + finalFilter)
      .pipe(catchError(this.handleError));
  }

  /**
   * Get all active list
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @returns object
   */
  getActiveList(searchFilter?: any) {
    let globalSearch = '';
    if (searchFilter !== null && searchFilter !== undefined) {
      globalSearch = '?searchFilter=' + searchFilter;
    }
    return this.http.get(`${this.baseUrl}/active/list` + globalSearch);
  }

  /**
   * Get single record by id
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param string id
   *
   * @returns object
   */
  getRow(id: any) {
    return this.http.get(`${this.baseUrl}/${id}`)
      .pipe(catchError(this.handleError));
  }

  /**
   * Delete record
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param number id
   *
   * @returns object
   */
  delete(id: number) {
    return this.http.delete(`${this.baseUrl}/${id}`)
      .pipe(catchError(this.handleError));
  }

  /**
   * Post state data
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param object body
   *
   * @returns object
   */
  store(body: any) {
    return this.http.post(`${this.baseUrl}`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Update data
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param object body
   * @param number id
   *
   * @returns object
   */
  update(body: any, id: number) {
    return this.http.post(`${this.baseUrl}/${id}`, body)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 400)
      return throwError(() => new BadInputError(error));

    if (error.status === 404)
      return throwError(() => new NotFoundError(error));

    return throwError(() => new AppError(error));
  }
}
