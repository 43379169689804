import { map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  baseUrl: string = `${environment.baseURL}` + 'dashboard';

  constructor(private http: HttpClient) { }

  /**
  * Get Participants count all, excel and app.
  * 
  * @param string type
  * @param any body
  *
  * @returns object
  */
  getDashboardCounts(type: string, body: any) {
    return this.http.post(`${this.baseUrl}/get-dashboard-counts/${type}`, body);
  }
  /**
   * Get Participants charts data disease, state, population etc.
   *
   * @param string type
   * @param any body
   *
   * @returns object
   */
  getDashboardChartData(type: string, body: any, monthOrYearFilter: any = 'year', isPercent: any = 2) {

    return this.http.post(
      `${this.baseUrl}/get-dashboard-chart-data/${type}/${monthOrYearFilter}/${isPercent}`,
      body,
    );
  }

  /**
   * Get latest sites information
   * 
   * @param any body
   *
   * @returns object
   */
  getLatestSites(body: any) {
    return this.http.post(`${this.baseUrl}/get-latest-sites`, body);
  }

  /**
   * Get Participants count all, excel and app.
   *
   * @param any body
   *
   * @returns object
   */
  getParticipantsCount(body: any) {
    return this.http.post(`${this.baseUrl}/get-participants-count`, body);
  }
}
