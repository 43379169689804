import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-participant-document',
  templateUrl: './participant-document.component.html',
  styleUrls: ['./participant-document.component.scss']
})

export class ParticipantDocumentComponent implements OnInit {

  public PID: any;
  public curationNotesDetails: any;
  public curationNotesForm!: FormGroup;
  public curationNotesArray!: FormArray;
  public curationNotesFormStatus: boolean = false;
  public curationNotesAdd: boolean = false;
  public curationNotesTypes: any;
  togglepopup: boolean = false;
  public albums: any[] = [];
  public statusTableform: boolean = false;
  public disableTableform: boolean = true;
  public enableTableform: boolean = false;
  public loading: boolean = false;
  public imageFiles: Blob[] = [];

  constructor(
    public dialog: MatDialog,
    private participantService: ParticipantService,
    public commonService: CommonService,
    private route: ActivatedRoute
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getAllParticipantDocuments();
  }


  /**
   * @author Farhan Shaikh
   * This function is used to get Participant Documents
   */
  getAllParticipantDocuments() {
    // this.loading = true;
    this.participantService.getAllParticipantDocuments(this.PID).subscribe({
      next: (result: any) => {
        this.albums = [];
        if (result.data.length > 0) {
          result.data.forEach((element: any, index: number) => {
            const album = {
              src: element.path,
              fileType: element.fileType,
              caption: "Document " + (index + 1),
              name: element.name,
              thumb: element.path_thumb,
              path: element.path,
            };
            this.albums.push(album);
          });
        }
        // this.loading = false;
      }, error: (e) => {
        // this.loading = false;
        console.log('Participant Documents err :: ', e);
      }
    })
  }

  clickEventTableform() {
    this.statusTableform = !this.statusTableform;
    this.disableTableform = false;
    this.enableTableform = true;
  }
  cancelEventTableform() {
    this.statusTableform = !this.statusTableform;
    this.disableTableform = true;
    this.enableTableform = false;
  }

  openDialog(report: any) {
    console.log('this is report', report);
    this.dialog.open(DialogEmaple, { data: { image: report.src } });
  }

  downloadMedicalRecordDoc(report: any) {
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append("file_name", report.name);
    this.participantService.downloadMedicalRecordDoc(formData, this.PID).subscribe({
      next: (result: any) => {
        var link = document.createElement('a');
        link.href = result.data.url;
        link.click();
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }
}

// dialog code start 
@Component({
  selector: 'dialogEmaple',
  templateUrl: 'dialog-emaple.html',
})
export class DialogEmaple {
  image: string = '../../../../../../assets/images/patient-pic-1.jpg';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogEmaple>
  ) {
    if (data.image != undefined && data.image != '') {
      this.image = data.image;
    }
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
}
    // dialog code end
