<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form [ngClass]="diseaseFormStatus ? 'edit-mode' : 'view-mode'" id="diseaseForm" [formGroup]="diseaseForm">
        <div class="container-fluid">
            <div class="card-wrape">
                <div class="card-body p-b-0">
                    <div class="input-wrape title-controller">
                        <mat-label>Do You Have or Had Any Diseases?</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="option">
                            <mat-radio-button name="anyDiseases" value="yes"
                                (click)="resetForm('yes')">Yes</mat-radio-button>
                            <mat-radio-button name="anyDiseases" value="no"
                                (click)="resetForm('no')">No</mat-radio-button>
                            <mat-radio-button name="anyDiseases" value="prefer not to say"
                                (click)="resetForm('prefer not to say')">Prefer Not to Say</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <button mat-raised-button class="btn add-btn" *ngIf="diseaseForm.controls['option'].value === 'yes'"
                        color="secoundary" [disabled]="diseaseUpdateIndex!=null || diseaseAdd"
                        (click)="openDisease()">Add</button>
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
        <!-- basic form start here-->
        <section class="popup-wrape success" [formGroup]="diseaseDetailForm"
            [ngClass]="togglepopup ? 'success' : 'danger'">
            <div class="overlays"></div>
            <div class="card-wrape internal-card">
                <div class="card-head">
                    <h4 class="left-text">Disease Information</h4>
                    <a href="javascript:;" class="popup-close-btn" (click)="closePopup()">×</a>
                </div>
                <div class="card-body">
                    <div class="input-wrape" [ngClass]="displayFieldCss('disease_id')">
                        <mat-label><i>*</i>Name of Disease:</mat-label>
                        <div class="control-pad">
                            <ng-select [items]="diseases" placeholder="Enter disease name" bindLabel="display_term"
                                bindValue="id" formControlName="disease_id" [addTag]="addTagFn"
                                (change)="diseaseSelect($event)">
                            </ng-select>
                        </div>
                        <mat-error
                            *ngIf="isFieldValid('disease_id')">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape" [ngClass]="displayFieldCss('it_belong_any_following_diseases')"
                        *ngIf="diseaseDetailForm.controls['disease_id'].value == 0">
                        <mat-label><i>*</i> Does It Belong to Any of the Following
                            Diseases?:</mat-label>
                        <div class="control-pad">
                            <ng-select [items]="disease_categories" bindValue="disease_category" bindLabel="name"
                                formControlName="it_belong_any_following_diseases"
                                placeholder="Select does it belong to any of the following diseases"
                                (change)="diseaseCustomSelect($event)">
                            </ng-select>
                        </div>
                        <mat-error
                            *ngIf="isFieldValid('it_belong_any_following_diseases')">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape">
                        <mat-label><i>*</i>Enter Diagnosed Year <span>OR</span>Age</mat-label>
                        <div class="half-controls">
                            <div class="half" [ngClass]="displayFieldCss('diagnose_year') || displayFieldCss('diagnose_age')">
                                <div class="input-wrape calendar-wrape">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <input matInput [matDatepicker]="diagnose_year" [min]="minDiagnoseYear" [max]="maxDate"
                                            placeholder="Select diagnosed year" (click)="diagnose_year.open()"
                                            formControlName="diagnose_year">
                                        <mat-datepicker-toggle matSuffix [for]="diagnose_year">
                                            <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #diagnose_year startView="multi-year"
                                            (yearSelected)="chosenYearHandler($event,diagnose_year)"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="half" [ngClass]="displayFieldCss('diagnose_year') || displayFieldCss('diagnose_age')">
                                <div class="control-pad">
                                    <ng-select placeholder="Select year" formControlName="diagnose_age"
                                        [searchable]="false" (change)="
                                        ageOrDiagnosedValidator($event);
                                        yearCalculationFromAge()
                                      "> <ng-option value="{{age}}"
                                            *ngFor="let age of getAgeRanges(participantAge)">{{age}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <mat-error
                            *ngIf="(isFieldValid('diagnose_year') || isFieldValid('diagnose_age'))">
                            This field is required
                        </mat-error>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-raised-button class="btn" color="secoundary" *ngIf="!diseaseUpdate" [disabled]=""
                        (click)="addDisease()">Add</button>
                    <button mat-raised-button class="btn" color="secoundary" *ngIf="diseaseUpdate" [disabled]=""
                        (click)="updateDisease()">Update</button>
                </div>
            </div>
        </section>
        <!-- basic form end here -->
        <div class="col-lg-12" *ngIf="diseaseForm.controls['option'].value === 'yes'">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">Disease List</h4>
                </div>
                <div class="card-body">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <!-- <ng-container matColumnDef="id">
                            <mat-header-cell [hidden]="true" *matHeaderCellDef>ID</mat-header-cell>
                            <mat-cell [hidden]="true" *matCellDef="let element"> {{element.id}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="disease_id" class="hide-mat-column">
                            <mat-header-cell *matHeaderCellDef>Disease Id</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.disease_id}} </mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="disease_name">
                            <mat-header-cell *matHeaderCellDef>Name of Disease</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.disease_name}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="diagnose_year">
                            <mat-header-cell *matHeaderCellDef> Diagnosed Year </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.diagnose_year | date:'yyyy'}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="diagnose_age">
                            <mat-header-cell *matHeaderCellDef> Approx Age </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.diagnose_age}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                            <mat-cell *matCellDef="let element;let i = index;">
                                <div class="icons-wrape">
                                    <a (click)="editDisease(i,element.id)" class="theme-icon edit-icon" title="Edit Disease"></a>
                                    <a class="theme-icon delete-icon" (click)="deleteDiseaseHistory(element.id)" title="Delete Disease"></a>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        <div *matNoDataRow>No records found</div>
                    </mat-table>
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
        <div class="container-fluid" [formGroup]="diseaseDetailForm">
            <!-- no record found wrape start -->
            <div title="No record"
                *ngIf="diseaseForm.controls['option'].value === 'no' || diseaseForm.controls['option'].value === 'prefer not to say' || diseaseForm.controls['option'].value === null"
                class="no-record-pad">
                <img src="../../../../../../assets/images/edit-participant-no-data/disease-history-no-data.svg"
                    alt="Disease History">
                <span>No participant diseases history found </span>
            </div>
            <!-- no record found wrape end -->

            <app-disease-cancer-questions [diseaseDetails]="diseaseDetails" [diseaseUpdateIndex]="diseaseUpdateIndex"
                [diseaseForm]="diseaseForm" [diseaseDetailForm]="diseaseDetailForm" class="col-lg-12"
                *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'CANCER'"></app-disease-cancer-questions>
            <app-disease-ckd-questions [diseaseDetails]="diseaseDetails" [diseaseUpdateIndex]="diseaseUpdateIndex" [months]="months" [days]="days" [years]="years" [diseaseForm]="diseaseForm"
                [diseaseDetailForm]="diseaseDetailForm" class="col-lg-12"
                *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'CHRONIC_KIDNEY_DISEASE'"></app-disease-ckd-questions>
            <app-disease-mi-questions [diseaseUpdateIndex]="diseaseUpdateIndex" [diseaseDetails]="diseaseDetails"
                [diseaseForm]="diseaseForm" [diseaseDetailForm]="diseaseDetailForm" class="col-lg-12"
                *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'MYOCARDIAL_INFARCTION'"></app-disease-mi-questions>
            <app-disease-hf-questions [diseaseDetails]="diseaseDetails" [diseaseUpdateIndex]="diseaseUpdateIndex" [diseaseForm]="diseaseForm" [diseaseDetailForm]="diseaseDetailForm"
                class="col-lg-12"
                *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'HEART_FAILURE'"></app-disease-hf-questions>
            
            <app-disease-diabetes-questions [diseaseUpdateIndex]="diseaseUpdateIndex" [diseaseDetails]="diseaseDetails" [diseaseForm]="diseaseForm" [diseaseDetailForm]="diseaseDetailForm" [months]="months" [days]="days"
                [years]="years" *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'DIABETES'"></app-disease-diabetes-questions>
            
            <app-disease-nafld-questions [infectionStatus]="infectionStatus" [diseaseUpdateIndex]="diseaseUpdateIndex" [diseaseDetails]="diseaseDetails" [diseaseForm]="diseaseForm" [diseaseDetailForm]="diseaseDetailForm"
                *ngIf="diseaseForm.controls['option'].value === 'yes' && diseaseDetailForm.controls['disease_type_flag'].value == 'NON_ALCOHOLIC_FATTY_LIVER_DISEASE'"></app-disease-nafld-questions>
        </div>

        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickOnEdit()" *ngIf="!diseaseFormStatus" mat-raised-button class="btn"
                color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="diseaseFormStatus" mat-raised-button class="btn"
                color="warn">Cancel</button>
            <button type="button" (click)="diseaseSubmit()" *ngIf="diseaseFormStatus"
                [disabled]="(!diseaseAdd && !diseaseUpdate) || loading" mat-raised-button class="btn"
                color="secoundary">Save</button>
        </div>
    </form>
</div>