import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { CountryService } from 'src/app/service/country.service';
import { DiagnosticLabsService } from 'src/app/service/diagnosticLabs.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { PincodeService } from 'src/app/service/pincode.service';
import { ProjectService } from 'src/app/service/project.service';

@Component({
  selector: 'app-diagnostic-lab-form',
  templateUrl: './diagnostic-lab-form.component.html',
  styleUrls: ['./diagnostic-lab-form.component.scss']
})
export class DiagnosticLabFormComponent implements OnInit {
  public diagnosticLabForm!: FormGroup;
  public title: any;
  public diagnosticLabId: any;
  public diagnosticLabDetails: any;
  public buttonText!: string;
  public diagnosticLabNameMinLength: number = 3;
  public contactPersonNameMinLength: number = 3;
  public addressMinLength: number = 3;
  public accreditationMinLength: number = 3;
  public phoneNumberMinLength: number = 10;
  public phoneNumberMaxLength: number = 10;
  public select_all = false;
  public cancelLink: string = "/site/diagnostic-lab";
  public userId: any = "";
  public isAddMode: boolean = true;
  public loading: Boolean = false;
  public testsSelected: any = [];
  public tests_offered: any[] = []
  public pincodeList!: any[];
  public projectNameOptions: any[] = [];
  public countryCodeOptions: any[] = [];
  public countryList: any = [];
  public stateList: any = [];
  public cityList: any = [];

  constructor(
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
    private countryService: CountryService,
    private diagnosticLabService: DiagnosticLabsService,
    public commonService: CommonService,
    protected router: Router,
    private route: ActivatedRoute,
    private pincodeService: PincodeService
  ) {
    this.diagnosticLabId = this.route.snapshot.paramMap.get('id');;
    if (this.diagnosticLabId !== 0 && this.diagnosticLabId !== null) {
      this.title = "Edit Diagnostic Lab";
      this.buttonText = "Save";
    } else {
      this.title = "Add Diagnostic Lab";
      this.buttonText = "Add";
      this.diagnosticLabId = 0;
    }
  }

  ngOnInit(): void {
    this.getProjects();
    this.getCountryWisePhoneCode();
    this.getCountryList();
    this.userId = localStorage.getItem("userId");
    this.isAddMode = !this.diagnosticLabId;
    this.getFullBodyProfileTestByName();
    if (this.diagnosticLabId !== 0 && this.diagnosticLabId !== null) {
      this.setEditData();
    }

    this.diagnosticLabForm = this.formBuilder.group({
      diagnosticLabName: [
        "",
        [
          Validators.required,
          Validators.minLength(this.diagnosticLabNameMinLength),
          Validators.maxLength(255),
        ],
      ],
      contactPersonName: [
        "",
        [
          Validators.required,
          Validators.minLength(this.contactPersonNameMinLength),
          Validators.maxLength(255),
        ],
      ],
      projectName: [null, [Validators.required]],
      phoneCode: [null, [Validators.required]],
      phoneNumber: [
        "",
        [Validators.required, Validators.minLength(this.phoneNumberMinLength), Validators.maxLength(10)],
      ],
      testsOffered: [''],
      locality: ["", null],
      street: ["", null],
      accreditation: "",
      postal_code: null,
      country_id: [null, [Validators.required]],
      state_id: [null, [Validators.required]],
      city_id: [null, [Validators.required]],
      branch_code: "",
    });
  }

  /**
   * Add and update Diagnostic Lab records
   *
   *
   * @returns object
   */
  onSubmit(data: any) {

    this.addValidation();

    this.testsSelected = [];
    this.tests_offered.find(test => {
      if (test.completed) {
        this.testsSelected.push(test.name);
      }
    })
    if (this.diagnosticLabForm.invalid) {
      this.commonService.validateAllFormFields(this.diagnosticLabForm);
      this.commonService.showErrorToast("Please enter correct values");
      return;
    }

    const formData: FormData = new FormData();

    formData.append("name", this.diagnosticLabForm.controls["diagnosticLabName"].value);
    formData.append("contact_person_name", this.diagnosticLabForm.controls["contactPersonName"].value);
    formData.append("project_id", this.diagnosticLabForm.controls["projectName"].value);
    formData.append("phone_code_id", this.diagnosticLabForm.controls["phoneCode"].value);
    formData.append("contact_person_phone_no", this.diagnosticLabForm.controls["phoneNumber"].value);
    if (!this.commonService.isNullOrUndefined(this.diagnosticLabForm.controls["postal_code"].value)) {
      formData.append("postal_code", this.diagnosticLabForm.controls["postal_code"].value);
    }
    formData.append("country_id", this.diagnosticLabForm.controls["country_id"].value);
    formData.append("state_id", this.diagnosticLabForm.controls["state_id"].value);
    formData.append("city_id", this.diagnosticLabForm.controls["city_id"].value);
    
    if (!this.commonService.isNullOrUndefined(this.diagnosticLabForm.controls["locality"].value)) {
      formData.append("locality", this.diagnosticLabForm.controls["locality"].value);
    }
    if (!this.commonService.isNullOrUndefined(this.diagnosticLabForm.controls["street"].value)) {
      formData.append("street", this.diagnosticLabForm.controls["street"].value);
    }
    if (!this.commonService.isNullOrUndefined(this.diagnosticLabForm.controls["branch_code"].value)) {
      formData.append("branch_code", this.diagnosticLabForm.controls["branch_code"].value);
    }
    if (!this.commonService.isNullOrUndefined(this.diagnosticLabForm.controls["accreditation"].value)) {
      formData.append("accreditation", this.diagnosticLabForm.controls["accreditation"].value);
    }
    formData.append("tests_offered", JSON.stringify(this.testsSelected));

    this.loading = true;
    if (this.diagnosticLabId === 0) {
      formData.append("created_by", this.userId);
      this.createDiagnosticLab(formData);
    } else {
      formData.append("updated_by", this.userId);
      this.updateDiagnosticLab(formData);
    }
  }

  /**
   * call add Diagnostic Lab api
   * @returns null
   */
  private createDiagnosticLab(formData: any) {
    this.diagnosticLabService.store(formData).subscribe({
      next: (result: any) => {
        if (
          result.status === AppConstants.serverSuccessStatus ||
          result.status === 201
        ) {
          this.commonService.showSuccessToast("Diagnostic Lab added successfully");
          this.router.navigate(["/site/diagnostic-lab"]);
        } else {
          let iUnique: boolean = false;
          if (typeof result.data === "object") {
            for (const key in result.data) {
              if (key === "name") {
                this.diagnosticLabForm
                  .controls["diagnosticLabName"]
                  .setErrors({ validField: result.data[key] });
                iUnique = true;
              } else if (key === "contact_person_name") {
                this.diagnosticLabForm
                  .controls["contactPersonName"]
                  .setErrors({ validField: result.data[key] });
                iUnique = true;
              }
            }
          }
          if (!iUnique) {
            this.commonService.showErrorToast(
              "Something went wrong. Please contact to administrator."
            );
          }
        }
        this.loading = false;
      },
      error: (error: any) => {
        this.commonService.showErrorToast("Please enter correct values");
        this.loading = false;
      }
    });
  }
  /**
   * call Update Diagnostic Lab api
   * @returns null
   */
  private updateDiagnosticLab(formData: any): void {
    this.diagnosticLabService
      .update(formData, this.diagnosticLabId)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(
              "Diagnostic Lab updated successfully"
            );
            this.router.navigate(["/site/diagnostic-lab"]);
          } else {
            let iUnique: boolean = false;
            if (typeof result.data === "object") {
              for (const key in result.data) {
                if (key === "name") {
                  this.diagnosticLabForm
                    .controls["diagnosticLabName"]
                    .setErrors({ validField: result.data[key] });
                  iUnique = true;
                } else if (key === "contact_person_name") {
                  this.diagnosticLabForm
                    .controls["contactPersonName"]
                    .setErrors({ validField: result.data[key] });
                  iUnique = true;
                }
              }
            }
            if (!iUnique) {
              this.commonService.showErrorToast(
                "Something went wrong. Please contact to administrator."
              );
            }
          }
          this.loading = false;
        },
        error: (error: any) => {
          this.commonService.showErrorToast("Please enter correct values",);
          this.loading = false;
        }
      });
  }

  changePhoneCode(event: any) {
    if (typeof event === "undefined") {
      return false;
    }
    this.addValidation(event);
    return true;
  }

  addValidation(countryCode: any = "") {    
    let phoneNumberField = this.diagnosticLabForm.controls["phoneNumber"];
    let phoneCode = this.diagnosticLabForm.controls["phoneCode"].value;

    if (phoneNumberField.value !== '' || phoneCode !== null) {
      let phoneNumberLength = new String(phoneNumberField.value).length;
      phoneNumberField.setErrors(null);

      if (phoneCode == '+91' || countryCode.id === 144 || phoneCode == 144) {
        this.phoneNumberMinLength = 10;
        this.phoneNumberMaxLength = 10;

        if (phoneNumberLength !== 10) {
          phoneNumberField.setErrors({ phoneLengthError: "Phone Number must be of " + this.phoneNumberMinLength + " digits" });
        }
      } else {
        this.phoneNumberMinLength = 6;
        this.phoneNumberMaxLength = 15;

        if (phoneNumberLength < 6 && phoneNumberField.value !== "0") {
          phoneNumberField.setErrors({ phoneLengthError: "Phone Number must be of " + this.phoneNumberMinLength + " digits" });
        }
        if (phoneNumberLength > 15 && phoneNumberField.value !== "0") {
          phoneNumberField.setErrors({ phoneLengthError: "Phone Number must be of " + this.phoneNumberMaxLength + " digits" });
        }
      }
    }

  }

  /**
   * Set Diagnostic Lab Data for update records
   *
   *
   * @returns object
   */
  setEditData() {
    this.loading = true;
    this.diagnosticLabService.getRow(this.diagnosticLabId).subscribe({
      next: (result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(["page-not-found"]);
        } else {
          this.diagnosticLabDetails = result.data;
          if (this.diagnosticLabDetails.project_id > 0) {
            this.projectNameOptions.push({
              id: this.diagnosticLabDetails.project_id,
              name: this.diagnosticLabDetails.name,
            });
            this.projectNameOptions = this.projectNameOptions.slice(0);
          }
          if (this.diagnosticLabDetails.tests_offered && JSON.parse(this.diagnosticLabDetails.tests_offered).length > 0) {

            this.tests_offered.forEach(test => {
              if (JSON.parse(this.diagnosticLabDetails.tests_offered).find((savedTest: { name: any; }) => savedTest == test.name)) {
                test.completed = true;
              }
            });
          }
          this.stateList.push(this.diagnosticLabDetails.state);
          this.cityList.push(this.diagnosticLabDetails.city);
          this.diagnosticLabForm.patchValue({
            diagnosticLabName: this.diagnosticLabDetails.name,
            contactPersonName: this.diagnosticLabDetails.contact_person_name,
            projectName: this.diagnosticLabDetails.project_id,
            phoneCode: this.diagnosticLabDetails.phone_code_id,
            phoneNumber: this.diagnosticLabDetails.contact_person_phone_no,
            accreditation: this.diagnosticLabDetails.accreditation,
            locality: this.diagnosticLabDetails.locality,
            street: this.diagnosticLabDetails.street,
            postal_code: this.diagnosticLabDetails.postal_code,
            country_id: this.diagnosticLabDetails.country_id,
            state_id: this.diagnosticLabDetails.state_id,
            city_id: this.diagnosticLabDetails.city_id,
            branch_code: this.diagnosticLabDetails.branch_code,
          });

          this.searchState({ term: this.diagnosticLabDetails.state.name }, this.diagnosticLabDetails.country.id);
          this.searchCity({ term: this.diagnosticLabDetails.city.name, }, this.diagnosticLabDetails.state.id);
        }
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.commonService.showErrorToast("Please enter correct values");
      }
    });
  }

  /**
   * Check diagnostic lab name field is existed or not
   *
   *
   * @returns object
   */
  checkUniqueDiagnosticLabName(event: any) {
    let filterValue = (event.target as HTMLInputElement).value;
    if (
      event.target.value !== "" &&
      filterValue.length > this.diagnosticLabNameMinLength &&
      filterValue.length < 255
    ) {
      this.diagnosticLabService
        .checkUniqueDiagnosticLabName(event.target.value, this.diagnosticLabId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.diagnosticLabForm.controls["diagnosticLabName"].setErrors(null);
            } else {
              this.diagnosticLabForm
                .controls["diagnosticLabName"]
                .setErrors({ unique: "Diagnostic Lab name already exists" });
            }
          },
          error: (error: Response) => {
            if (error.status === 400) {
              this.diagnosticLabForm
                .controls["diagnosticLabName"]
                .setErrors({ unique: "Diagnostic Lab name already exists" });
            }
          }
        });
    }
  }
  /**
     * Get Full Body Profile Tests Lists
     * @returns object
     */
  getFullBodyProfileTestByName() {
    this.tests_offered = [];
    let tests = JSON.parse(localStorage.getItem('constants') as string).FULL_BODY_PROFILE_TESTS;
    if (tests.length > 0) {
      tests.forEach((test: any) => {
        this.tests_offered.push({
          name: test,
        })
      })
    } else {
      this.commonService.showErrorToast('Tests offered Not Available, Please contact administration!')
    }

  }

  /**
   * Get Projects List
   *
   *
   * @returns object
   */
  getProjects() {
    this.projectService.getActiveList().subscribe({
      next: (result: any) => {
        this.projectNameOptions = result.data;
      },
      error: (e: any) => {
        console.log(e.error);
      },
    });
  }
  private _filterProjectName(value: any): any[] {
    if (value) {
      const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.name;
      return this.projectNameOptions.filter((projectNameOption: { name: string; }) => projectNameOption.name.toLowerCase().includes(filterValue));
    }
    return this.projectNameOptions.slice();
  }

  /**
  * @author Dhaval Bera
  *
  */
  getCountryWisePhoneCode() {
    this.dictionaryService.getCountryList().subscribe({
      next: (result: any) => {
        this.countryCodeOptions = result.data;
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  // Get Country Dictionary
  getCountryList() {
    this.countryService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.countryList = result.data;
          this.diagnosticLabForm.patchValue({
            country_id: 144,
          });
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
  }
  clearCountry() {
    this.diagnosticLabForm.controls['country_id'].setValue(null);
    // this.countryList = [];
    this.diagnosticLabForm.controls['state_id'].setValue(null);
    this.stateList = [];
    this.diagnosticLabForm.controls['city_id'].setValue(null);
    this.cityList = [];
  }


  public searchState(event: any, countryID: any) {
    if (event.term !== null && event.term !== undefined && event.term.length > 2) {
      this.diagnosticLabService.searchState(event.term, countryID).subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.stateList = result.data;
          } else {
            this.stateList = [];
            this.diagnosticLabForm.controls['state_id'].setValue(null);
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
        }
      });
    }
  }

  clearState() {
    this.diagnosticLabForm.controls['state_id'].setValue(null);
    this.stateList = [];
  }

  public searchCity(event: any, stateID: any) {
    if (event.term !== null && event.term !== undefined && event.term.length > 2) {
      this.diagnosticLabService.searchCity(event.term, stateID).subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.cityList = result.data;
          } else {
            this.cityList = [];
            this.diagnosticLabForm.controls['city_id'].setValue(null);
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
        }
      });
    }
  }
  clearCity() {
    this.diagnosticLabForm.controls['city_id'].setValue(null);
    this.cityList = [];
  }


  onSelectAll(completed: boolean): void {
    this.select_all = completed;
    if (this.tests_offered == null) {
      return;
    }
    this.tests_offered.forEach(t => (t.completed = completed));
  }
  updateAllComplete() {
    this.select_all = this.tests_offered != null && this.tests_offered.every(t => t.completed);
  }
  someComplete(): boolean {
    if (this.tests_offered == null) {
      return false;
    }
    return this.tests_offered.filter(t => t.completed).length > 0 && !this.select_all;
  }

  /* It will search pincode
  * @param event
  */
  searchPincode(event: any) {
    if (event.term !== undefined && event.term.length > 2) {
      this.loading = true;
      this.pincodeService.searchPincode(event.term).subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.pincodeList = result.data;
          } else {
            this.diagnosticLabForm.patchValue({
              country_id: null,
              state_id: null,
              city_id: null,
            });
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log('Pincode Search err :: ', err);
        }
      });
    }
  }
  selectPincode(event: any) {
    if (event != undefined) {
      if (event != undefined && event.country_id != undefined) {
        this.diagnosticLabForm.patchValue({
          country_id: event.country_id !== 0 ? event.country_id : null
        });
        this.searchState({ term: event.state_name }, event.country_id);
        this.searchCity({ term: event.city_name }, event.state_id);
      }
    }
  }

  /**
  * @param event
  */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  /**
  * @param field
  */
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }
  /**
 * This funtion is used to submit Form Details
 * @param field
 */
  isFieldValid(field: string) {
    return (
      (this.diagnosticLabForm.controls[field].invalid && this.diagnosticLabForm.controls[field].dirty) ||
      (this.diagnosticLabForm.controls[field].touched && this.diagnosticLabForm.controls[field].invalid)
    );
  }
  // Select All component code end (Tests Offered)

  getDiagnosticLabNameError() {
    if (this.diagnosticLabForm.controls['diagnosticLabName'].errors!['required']) {
      return 'Diagnostic Lab is required.';
    }
    if (this.diagnosticLabForm.controls['diagnosticLabName'].errors!['minlength']) {
      return 'Min length is ' + this.diagnosticLabNameMinLength + ' characters.';
    }
    if (this.diagnosticLabForm.controls['diagnosticLabName'].errors!['maxlength']) {
      return 'Max length is 255 characters.';
    }
    if (this.diagnosticLabForm.controls['diagnosticLabName'].errors!['unique']) {
      return this.diagnosticLabForm.controls['diagnosticLabName']!.errors!['unique'];
    }
    return '';
  }
  getContactPersonNameError() {
    if (this.diagnosticLabForm.controls['contactPersonName'].errors!['required']) {
      return 'Contact Person is required.';
    }
    if (this.diagnosticLabForm.controls['contactPersonName'].errors!['minlength']) {
      return 'Min length is ' + this.contactPersonNameMinLength + ' characters.';
    }
    if (this.diagnosticLabForm.controls['contactPersonName'].errors!['maxlength']) {
      return 'Max length is 255 characters.';
    }
    if (this.diagnosticLabForm.controls['contactPersonName'].errors!['unique']) {
      return this.diagnosticLabForm.controls['contactPersonName']!.errors!['unique'];
    }
    return '';
  }
  getPhoneNumberError() {
    if (this.diagnosticLabForm.controls['phoneNumber'].errors!['required']) {
      return 'Phone Number is required.';
    }
    if (this.diagnosticLabForm.controls['phoneNumber'].errors!['minlength']) {
      if (this.phoneNumberMinLength == 10) {
        return "Phone Number must be of 10 digits";
      }
      if (this.phoneNumberMinLength != 10) {
        return "Phone Number must be at least " + this.phoneNumberMinLength + " digits";
      }
    }

    if (this.diagnosticLabForm.controls['phoneNumber'].errors!['maxlength'] > 10) {
      return 'Phone Number must be of 10 digits';
    }
    if (this.diagnosticLabForm.controls['phoneNumber'].errors!['unique']) {
      return this.diagnosticLabForm.controls['phoneNumber']!.errors!['unique'];
    }
    if (this.diagnosticLabForm.controls['phoneNumber'].errors!['phoneLengthError']) {
      return this.diagnosticLabForm.controls['phoneNumber']!.errors!['phoneLengthError'];
    }
    return '';
  }
  getProjectNameError() {
    if (this.diagnosticLabForm.controls['projectName'].errors!['required']) {
      return 'Project Name is required.';
    }
    return '';
  }
  getPhoneCodeError() {
    if (this.diagnosticLabForm.controls['phoneCode'].errors!['required']) {
      return 'Country Code is required.';
    }
    return '';
  }
  getSelectCountryError() {
    if (this.diagnosticLabForm.controls['country_id'].errors!['required']) {
      return 'Country is required.';
    }
    if (this.diagnosticLabForm.controls['country_id'].errors!['pattern']) {
      return 'Please select valid country';
    }
    return '';
  }
  getSelectStateError() {
    if (this.diagnosticLabForm.controls['state_id'].errors!['required']) {
      return 'State is required';
    }
    if (this.diagnosticLabForm.controls['state_id'].errors!['pattern']) {
      return 'Please select valid state';
    }
    return '';
  }
  getSelectCityError() {
    if (this.diagnosticLabForm.controls['city_id'].errors!['required']) {
      return 'City is required';
    }
    if (this.diagnosticLabForm.controls['city_id'].errors!['pattern']) {
      return 'Please select valid city';
    }
    return '';
  }
}

