<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
      <li>{{title}}</li>
      <li><a routerLink="{{cancelLink}}" title="State" class="only-link">State</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xl-8 col-md-12 col-sm-12">
        <form [formGroup]="stateForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
          <div class="card-wrape">
              <div class="card-head">
                  <h4 class="left-text">{{title}}</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6 col-md-6">
                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.stateForm, 'name')">
                        <mat-label><i>*</i> Name</mat-label>
                          <mat-form-field appearance="outline">
                            <input matInput #input 
                            formControlName="name" 
                            placeholder="Enter name" 
                            type="text"
                            (focusout)="
                            checkUniqueStateFields('name', $event)
                          "
                          >
                        </mat-form-field>
                          <mat-error *ngIf="this.stateForm.controls['name'].invalid && (this.stateForm.controls['name'].dirty || this.stateForm.controls['name'].touched)">
                              {{getStateNameError()}}
                          </mat-error>
                    </div>
                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.stateForm, 'code')">
                        <mat-label> Code</mat-label>
                          <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                            <input matInput #input 
                            formControlName="code" 
                            placeholder="Enter code" 
                            type="text"
                            (focusout)="
                            checkUniqueStateFields('code', $event)
                          "
                          >
                        </mat-form-field>
                          <mat-error *ngIf="this.stateForm.controls['code'].invalid && (this.stateForm.controls['code'].dirty || this.stateForm.controls['code'].touched)">
                              {{getStateCodeError()}}
                          </mat-error>
                    </div>
                    <div class="input-wrape" *ngIf="isCurate">
                        <mat-label><i>*</i> Is Approved?:</mat-label>
                        <mat-radio-group aria-label="Select an option" class="example-radio-group">
                          <mat-radio-button ngDefaultControl
                            class="example-radio-button"
                            formControlName="isApproved" 
                            id="radio_yes"
                            (click)="selectOption(false)"
                            [value]="true" checked
                          >
                            Approved</mat-radio-button>
                          <mat-radio-button ngDefaultControl
                            class="example-radio-button"
                            formControlName="isApproved" 
                            id="radio_no"
                            (click)="selectOption(true)"
                            [value]="false"
                          >
                            Map with others</mat-radio-button>
                        </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.stateForm, 'countryId')">
                      <mat-label><i>*</i> Country</mat-label>
                      <div class="control-pad">
                        <ng-select [items]="countryList" formControlName="countryId"
                            bindLabel="name" bindValue="id" labelForId="countryId"
                            placeholder="Select country"
                            [disabled]="true">
                        </ng-select>
                        <mat-error *ngIf="this.stateForm.controls['countryId'].invalid && (this.stateForm.controls['countryId'].dirty || this.stateForm.controls['countryId'].touched)">
                          {{getSelectCountryError()}}
                        </mat-error>
                      </div>
                    </div>
                    <div class="input-wrape" *ngIf="selectMapOption" [class]="this.commonService.displayFieldCss(this.stateForm, 'mapWithOtherState')">
                      <mat-label><i>*</i> Map With:</mat-label>
                      <div class="control-pad">
                        <ng-select [items]="stateList" formControlName="mapWithOtherState"
                            bindLabel="name" bindValue="id" labelForId="mapWithOtherState" (change)="mapWithStateChange($event)"
                            placeholder="Select state">
                        </ng-select>
                        <mat-error *ngIf="this.stateForm.controls['mapWithOtherState'].invalid && (this.stateForm.controls['mapWithOtherState'].dirty || this.stateForm.controls['mapWithOtherState'].touched)">
                          {{getMapWithStateError()}}
                        </mat-error>
                      </div>  
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                  <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}" title="Cancel">Cancel</button>
                  <button mat-raised-button class="btn" type="submit" [disabled]="disableSubmitBtn" color="secoundary" title="{{buttonText}}">{{buttonText}}</button>
              </div>
          </div>
        </form>
      </div>
  </div>
</section>