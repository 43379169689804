import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disease-diabetes-questions',
  templateUrl: './disease-diabetes-questions.component.html',
  styleUrls: ['./disease-diabetes-questions.component.scss']
})
export class DiseaseDiabetesQuestionsComponent implements OnInit {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() years: any = [];
  @Input() months: any = [];
  @Input() days: any = [];
  @Input() diseaseDetails: any;
  @Input() diseaseUpdateIndex: any;

  public diabeteForm!: FormGroup;
  public diseasesAlongWithDiabetes: any = [];
  public DIABETES: any = [];
  public participantID: any;


  // DISEASES_ALONG_WITH_DIABETES
  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private route: ActivatedRoute
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {

    let diseasesAlongWithDiabetes = JSON.parse(localStorage.getItem('constants') as string);
    this.diseasesAlongWithDiabetes = diseasesAlongWithDiabetes['DISEASES_ALONG_WITH_DIABETES'];

    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      this.DIABETES = this.diseaseDetails['details'][this.diseaseUpdateIndex]['DIABETES'];
    }

    let howLongChronicFootUlcers = null;
    let howLongNumbnessTinglingTurningSensation = null;
    let howLongChronicNonHealingSkinWound = null;

    if (![undefined, null, ''].includes(this.DIABETES)) {
      howLongChronicFootUlcers = (![null, undefined, ''].includes(this.DIABETES)) ? this.DIABETES?.how_long_have_chronic_foot_ulcers?.split('-') : null;
      howLongNumbnessTinglingTurningSensation = (![null, undefined, ''].includes(this.DIABETES)) ? this.DIABETES?.how_long_numbness_tingling_burning_sensation?.split('-') : null;
      howLongChronicNonHealingSkinWound = (![null, undefined, ''].includes(this.DIABETES)) ? this.DIABETES?.how_long_have_chronic_non_healing_skin_wound?.split('-') : null;
      // console.log(this.DIABETES.how_long_have_chronic_foot_ulcers,howLongChronicFootUlcers,howLongNumbnessTinglingTurningSensation,howLongChronicNonHealingSkinWound);
    }

    // DIABETES Questions
    this.diabeteForm = this.formBuilder.group({
      have_dm_along_with_other_disease: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_dm_along_with_other_disease, [Validators.required]),
      have_chronic_foot_ulcers: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_chronic_foot_ulcers, [Validators.required]),
      have_chronic_non_healing_skin_wound: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_chronic_non_healing_skin_wound, [Validators.required]),
      have_dm_glucose_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_dm_glucose_report, [Validators.required]),
      have_dm_glumatic_acid_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_dm_glumatic_acid_report, [Validators.required]),
      have_dm_hba1c_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_dm_hba1c_report, [Validators.required]),
      have_dm_random_blood_glucose_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_dm_random_blood_glucose_report, [Validators.required]),
      how_long_have_chronic_foot_ulcers: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.how_long_have_chronic_foot_ulcers, []),
      how_long_have_chronic_foot_ulcers_year: new FormControl((howLongChronicFootUlcers != null) ? howLongChronicFootUlcers[0] : null, []),
      how_long_have_chronic_foot_ulcers_month: new FormControl((howLongChronicFootUlcers != null) ? howLongChronicFootUlcers[1] : null, []),
      how_long_have_chronic_foot_ulcers_day: new FormControl((howLongChronicFootUlcers != null) ? howLongChronicFootUlcers[2] : null, []),
      how_long_have_chronic_non_healing_skin_wound: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.how_long_have_chronic_non_healing_skin_wound, []),
      how_long_have_chronic_non_healing_skin_wound_year: new FormControl((howLongChronicNonHealingSkinWound != null) ? howLongChronicNonHealingSkinWound[0] : null, []),
      how_long_have_chronic_non_healing_skin_wound_month: new FormControl((howLongChronicNonHealingSkinWound != null) ? howLongChronicNonHealingSkinWound[1] : null, []),
      how_long_have_chronic_non_healing_skin_wound_day: new FormControl((howLongChronicNonHealingSkinWound != null) ? howLongChronicNonHealingSkinWound[2] : null, []),
      is_dm_diagnosed_by_clinician: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES.is_dm_diagnosed_by_clinician, [Validators.required]),
      photo_dm_glucose_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_glucose_report, []),
      photo_dm_glucose_report_url: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_glucose_report_url, []),
      photo_dm_glumatic_acid_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_glumatic_acid_report, []),
      photo_dm_glumatic_acid_report_url: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_glumatic_acid_report_url, []),
      photo_dm_hba1c_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_hba1c_report, []),
      photo_dm_hba1c_report_url: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_hba1c_report_url, []),
      photo_dm_prescription_note: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_prescription_note, []),
      photo_dm_prescription_note_url: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_prescription_note_url, []),
      photo_dm_random_blood_glucose_report: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_random_blood_glucose_report, []),
      photo_dm_random_blood_glucose_report_url: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.photo_dm_random_blood_glucose_report_url, []),
      do_you_have_diabetes_related_eye_kidney_problems: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.do_you_have_diabetes_related_eye_kidney_problems, [Validators.required]),
      have_numbness_tingling_burning_sensation: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.have_numbness_tingling_burning_sensation, [Validators.required]),
      how_long_numbness_tingling_burning_sensation: new FormControl([null, undefined, ''].includes(this.DIABETES) ? null : this.DIABETES?.how_long_numbness_tingling_burning_sensation, []),
      how_long_numbness_tingling_burning_sensation_year: new FormControl((howLongNumbnessTinglingTurningSensation != null) ? howLongNumbnessTinglingTurningSensation[0] : null, []),
      how_long_numbness_tingling_burning_sensation_month: new FormControl((howLongNumbnessTinglingTurningSensation != null) ? howLongNumbnessTinglingTurningSensation[1] : null, []),
      how_long_numbness_tingling_burning_sensation_day: new FormControl((howLongNumbnessTinglingTurningSensation != null) ? howLongNumbnessTinglingTurningSensation[2] : null, []),
    });

    this.diseaseDetailForm.setControl('DIABETES', this.diabeteForm);

    // DIABETES Dynamic Validation 
    const diabeteForm = this.diseaseDetailForm.get('DIABETES') as FormArray;

    diabeteForm.get('is_dm_diagnosed_by_clinician')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.diabeteForm.controls['photo_dm_prescription_note'].setValidators([Validators.required]);
      } else {
        this.diabeteForm.controls['photo_dm_prescription_note'].clearValidators();
        this.diabeteForm.controls['photo_dm_prescription_note'].setValue(null);
      }
      this.diabeteForm.controls['photo_dm_prescription_note'].updateValueAndValidity();
    });

    diabeteForm.get('have_dm_glucose_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.diabeteForm.controls['photo_dm_glucose_report'].setValidators([Validators.required]);
      } else {
        this.diabeteForm.controls['photo_dm_glucose_report'].clearValidators();
      }
      this.diabeteForm.controls['photo_dm_glucose_report'].updateValueAndValidity();
    });


    diabeteForm.get('have_dm_hba1c_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.diabeteForm.controls['photo_dm_hba1c_report'].setValidators([Validators.required]);
      } else {
        this.diabeteForm.controls['photo_dm_hba1c_report'].clearValidators();
      }
      this.diabeteForm.controls['photo_dm_hba1c_report'].updateValueAndValidity();
    });

    diabeteForm.get('have_dm_random_blood_glucose_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.diabeteForm.controls['photo_dm_random_blood_glucose_report'].setValidators([Validators.required]);
      } else {
        this.diabeteForm.controls['photo_dm_random_blood_glucose_report'].clearValidators();
      }
      this.diabeteForm.controls['photo_dm_random_blood_glucose_report'].updateValueAndValidity();
    });

    diabeteForm.get('have_dm_glumatic_acid_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.diabeteForm.controls['photo_dm_glumatic_acid_report'].setValidators([Validators.required]);
      } else {
        this.diabeteForm.controls['photo_dm_glumatic_acid_report'].clearValidators();
      }
      this.diabeteForm.controls['photo_dm_glumatic_acid_report'].updateValueAndValidity();
    });

    // how_long_numbness_tingling_burning_sensation
    diabeteForm.get('how_long_numbness_tingling_burning_sensation_year')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_numbness_tingling_burning_sensation'].setValue(val + '-' + diabeteForm.get('how_long_numbness_tingling_burning_sensation_month')?.value + '-' + diabeteForm.get('how_long_numbness_tingling_burning_sensation_day')?.value);
    });
    diabeteForm.get('how_long_numbness_tingling_burning_sensation_month')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_numbness_tingling_burning_sensation'].setValue(diabeteForm.get('how_long_numbness_tingling_burning_sensation_year')?.value + '-' + val + '-' + diabeteForm.get('how_long_numbness_tingling_burning_sensation_day')?.value);
    });
    diabeteForm.get('how_long_numbness_tingling_burning_sensation_day')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_numbness_tingling_burning_sensation'].setValue(diabeteForm.get('how_long_numbness_tingling_burning_sensation_year')?.value + '-' + diabeteForm.get('how_long_numbness_tingling_burning_sensation_month')?.value + '-' + val);
    });

    // how_long_have_chronic_non_healing_skin_wound
    diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_year')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_non_healing_skin_wound'].setValue(val + '-' + diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_month')?.value + '-' + diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_day')?.value);
    });
    diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_month')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_non_healing_skin_wound'].setValue(diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_year')?.value + '-' + val + '-' + diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_day')?.value);
    });
    diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_day')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_non_healing_skin_wound'].setValue(diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_year')?.value + '-' + diabeteForm.get('how_long_have_chronic_non_healing_skin_wound_month')?.value + '-' + val);
    });

    // how_long_have_chronic_foot_ulcers
    diabeteForm.get('how_long_have_chronic_foot_ulcers_year')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_foot_ulcers'].setValue(val + '-' + diabeteForm.get('how_long_have_chronic_foot_ulcers_month')?.value + '-' + diabeteForm.get('how_long_have_chronic_foot_ulcers_day')?.value);
    });
    diabeteForm.get('how_long_have_chronic_foot_ulcers_month')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_foot_ulcers'].setValue(diabeteForm.get('how_long_have_chronic_foot_ulcers_year')?.value + '-' + val + '-' + diabeteForm.get('how_long_have_chronic_foot_ulcers_day')?.value);
    });
    diabeteForm.get('how_long_have_chronic_foot_ulcers_day')?.valueChanges.subscribe(val => {
      this.diabeteForm.controls['how_long_have_chronic_foot_ulcers'].setValue(diabeteForm.get('how_long_have_chronic_foot_ulcers_year')?.value + '-' + diabeteForm.get('how_long_have_chronic_foot_ulcers_month')?.value + '-' + val);
    });
    
    if (![undefined, null, ''].includes(this.DIABETES)) {
      if (![null, undefined, ''].includes(this.DIABETES.have_dm_glucose_report) || ![null, undefined, ''].includes(this.DIABETES.have_dm_hba1c_report) || ![null, undefined, ''].includes(this.DIABETES.have_dm_random_blood_glucose_report)) {
        this.checkValidation();
      }
    }
  }

  /**
* This funtion is used to upload report
* @param event
* @param key
*/
  onSelectFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('disease_type', 'DIABETES');
      formData.append('previous_file', [null, undefined, ''].includes(this.DIABETES?.key) ? '' : this.DIABETES.key);
      this.participantService.uploadDiseaseQuestionReport(formData, this.participantID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            console.log(key);
            this.diabeteForm.controls[key]?.setValue(result.data.file);
            this.diabeteForm.controls[key + '_url']?.setValue(result.data.file_with_path);
            console.log(this.diabeteForm.value);
          }
        },
        error: (e: any) => {
          console.log('err :: ', e);
        },
        complete: () => {

        },
      });
    }
  }

  /**
   * Delete Disease Question Report File
   * @param fileName 
   * @param index 
   */
  deleteDiseaseQuestionReport(fileName: string, key: string) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the file?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {

        this.participantService.deleteDiseaseQuestionReport(fileName).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Report deleted successfully.', icon: 'success', });
              this.diabeteForm.controls[key]?.setValue(null);
              this.diseaseDetails['details'][this.diseaseUpdateIndex]['DIABETES'][key + '_url'] = null;
              this.diabeteForm.controls[key].setValidators([Validators.required]);
              this.diabeteForm.controls[key].updateValueAndValidity();
            } else {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: result.message, icon: 'warning', });
            }
          },
          error: (e: any) => {
            console.log('err :: ', e);
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: e, icon: 'warning', });
          },
          complete: () => {

          },
        });
      }
    });
  }

  showImage(fileUrl: string) {
    Swal.fire({
      imageUrl: fileUrl,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  checkValidation() {
    this.diabeteForm.controls['have_dm_glucose_report'].clearValidators();
    this.diabeteForm.controls['have_dm_hba1c_report'].clearValidators();
    this.diabeteForm.controls['have_dm_random_blood_glucose_report'].clearValidators();
    this.diabeteForm.controls['have_dm_glucose_report'].updateValueAndValidity();
    this.diabeteForm.controls['have_dm_hba1c_report'].updateValueAndValidity();
    this.diabeteForm.controls['have_dm_random_blood_glucose_report'].updateValueAndValidity();
  }

}
