<div class="page-title-box">
  <h1> HTR File Upload </h1>
  <ul class="bradcrumb">
    <li> HTR File Upload </li>
    <li><a routerLink="/htr" title="Ctate" class="only-link">Manage HTR Data</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-8 col-md-12 col-sm-12">
      <form [formGroup]="htrImportForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> HTR File Upload </h4>
            <div class="right-control">
              <a href="https://docs.google.com/spreadsheets/d/16XL5EfUwZ2Gc0QSn-uIv3sTOXJCoMFScgdv2WMhIwT4/view#gid=0"
                class="download-btn" target="_blank" color="secoundary">Download Template</a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="input-wrape"
                  [ngClass]="{ 'has-error': htrImportForm.controls['fileTypes'].invalid && (htrImportForm.controls['fileTypes'].dirty || htrImportForm.controls['fileTypes'].touched)}">
                  <mat-label>File Type </mat-label>
                  <div class="control-pad">
                    <ng-select formControlName="fileTypes" bindLabel="label" bindValue="value"
                      [dropdownPosition]="'bottom'" [searchable]="true" [multiple]="false" [closeOnSelect]="true"
                      [items]="htrFileType" placeholder="Select file type">
                    </ng-select>

                  </div>
                  <span
                    *ngIf="htrImportForm.controls['fileTypes'].invalid && (htrImportForm.controls['fileTypes'].dirty || htrImportForm.controls['fileTypes'].touched)">
                    <mat-error *ngIf="htrImportForm.controls['fileTypes'].errors?.['required']">
                      HTR File type is required
                    </mat-error>
                  </span>
                  <span
                    *ngIf="htrImportForm.controls['fileTypes'].invalid && (htrImportForm.controls['fileTypes'].dirty || htrImportForm.controls['fileTypes'].touched)">
                    <mat-error *ngIf="htrImportForm.controls['fileTypes'].errors?.['serverError']">
                      {{htrImportForm.controls['fileTypes'].errors?.['serverError']}}
                    </mat-error>
                  </span>
                </div>

              </div>
              <div class="col-xl-6">
                <div class="input-wrape"
                  [ngClass]="{ 'has-error': htrImportForm.controls['htr_file'].invalid && (htrImportForm.controls['htr_file'].dirty || htrImportForm.controls['htr_file'].touched)}">
                  <mat-label>Please Upload an HTR File</mat-label>
                  <div class="upload-wrape side-info ">
                    <input id="fileInput" formControlName="htr_file" type="file" class="ng-hide"
                      (change)="onSelectHtrFile($event)">
                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button
                      class="btn" color="primary"> Upload</button>
                    <div class="info-pad popup-over">
                      <button class="info-icon"></button>
                      <div class="info-section popupcontent">
                        Please choose .pdf file only
                      </div>
                    </div>
                  </div>
                  <span
                    *ngIf="htrImportForm.controls['htr_file'].invalid && (htrImportForm.controls['htr_file'].dirty || htrImportForm.controls['htr_file'].touched)">
                    <mat-error class="text-danger errorlbl"
                      *ngIf="htrImportForm.controls['htr_file'].errors?.['fileInvalidSize']">
                      {{htrImportForm.controls['htr_file'].errors?.['fileInvalidSize']}}</mat-error>
                    <mat-error class="text-danger errorlbl"
                      *ngIf="htrImportForm.controls['htr_file'].errors?.['fileInvalidType']">
                      {{ htrImportForm.controls['htr_file'].errors?.['fileInvalidType'] }}</mat-error>
                    <mat-error *ngIf="htrImportForm.controls['htr_file'].errors?.['required']">
                      HTR File is required
                    </mat-error>
                  </span>
                  <mat-error class="text-danger errorlbl"
                    *ngIf="htrImportForm.controls['htr_file'].errors && htrImportForm.controls['htr_file'].errors?.['invalidRecord']">
                    {{ htrImportForm.controls['htr_file'].errors?.['invalidRecord'] }}</mat-error>
                </div>
                <div class="upload-filename" *ngIf="selectedFileName !== '' ">
                  <a href="JavaScript:void(0);">{{ selectedFileName }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="/htr" title="Cancel">Cancel</button>
            <button type="submit" mat-raised-button class="btn" color="secoundary" title="Submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>