import { Component, OnInit, } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2'

import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';

export interface MedicationTable {
  id: Number,
  medication_name: string
  action: any
}
let ELEMENT_DATA: MedicationTable[] = [];

@Component({
  selector: 'app-participant-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.scss']
})

export class MedicationComponent implements OnInit {
  public PID: any;
  public medications: any = [];
  public medicationDetails: any;
  // public medicationFilteredOptions!: Observable<any[]>;
  public medicationForm!: FormGroup;
  public medicationDetailForm!: FormGroup;
  public medicationArray!: FormArray;
  public medicationFormStatus: boolean = false;
  public medicationUpdate: boolean = false;
  public medicationAdd: boolean = false;
  public medicationUpdateIndex: any;
  public newObject: any;
  public loading: boolean = false;

  togglepopup: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  displayedColumns: string[] = ['id', 'medication_name', 'action'];
  public dataSource = new MatTableDataSource<MedicationTable>(ELEMENT_DATA);

  ngOnInit(): void {
    this.medicationForm = new FormGroup({});
    this.medicationForm.setControl('option', new FormControl(null, [Validators.required]));
    this.medicationForm.setControl('details', new FormArray([]));
    this.medicationArray = <FormArray>this.medicationForm.controls["details"];

    this.medicationDetailForm = this.formBuilder.group({
      medication_name: [null, [Validators.required]]
    });

    this.getMedications();

    // this.medicationFilteredOptions = this.medicationDetailForm.controls['medication_name'].valueChanges.pipe(
    //   startWith(''),
    //   map(value => {
    //     if (value && value.length > 2) {
    //       const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.name;
    //       console.log(filterValue);
    //       this.searchDrugs(filterValue);
    //       return this.medications.filter((drugs: { medicine_name: string; }) => drugs.medicine_name.toLowerCase().includes(filterValue));
    //     }
    //     return this.medications.slice();
    //   }),
    // );
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to submit form to server side
  */
  medicationSubmit() {
    if (this.medicationForm.invalid) {
      console.log(this.medicationForm);
      this.commonService.validateAllFormFields(this.medicationForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    } else {
      this.participantService.updateMedication(this.medicationForm.value, this.PID).subscribe({
        next: (result: any) => {
          console.log(result);
          if (result.status === 1) {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
            this.medicationForm.setControl('details', new FormArray([]));
            this.medicationArray = <FormArray>this.medicationForm.controls["details"];
            this.medicationAdd = false;
            this.medicationUpdate = false;
            this.medicationUpdateIndex = null;
            this.getMedications();
            this.medicationDetailForm.reset();
          } else {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          }
          // this.showLoading = false;
        },
        error: (e) => {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          // this.showLoading = false;
        },
        complete: () => { },
      });
      // this.dataSource = new MatTableDataSource(this.medicationForm.controls['details']?.value);

    }
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to get Medication and set Value
   */
  getMedications() {
    this.loading = true;
    this.participantService.getMedications(this.PID).subscribe({
      next: (result: any) => {
        this.medicationDetails = result.data;

        if (this.medicationDetails.length === 0) {
          this.loading = false;
          return;
        }

        this.medicationForm.controls['option'].setValue(this.medicationDetails?.option);
        this.dataSource = new MatTableDataSource(result.data.details);
        this.medicationDetails.details.forEach((medication: any) => {
          this.medicationArray.push(
            new FormGroup({
              medication_name: new FormControl(medication.medication_name !== "" ? medication.medication_name : null, [Validators.required]),
            })
          );
        });
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Medication err :: ', e);
      }
    })
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to Open Medication and hide Update button
   */
  openMedication() {
    this.medicationAdd = true;
    this.togglepopup = !this.togglepopup;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to add Medication and set value
   */
  addMedication() {
    let medication_name_val = ''
    if (this.medicationDetailForm.controls['medication_name']?.value != undefined) {
      medication_name_val = this.medicationDetailForm.controls['medication_name']?.value;
    }
    if (this.medicationDetailForm.controls['medication_name']?.value.label != undefined) {
      medication_name_val = this.medicationDetailForm.controls['medication_name']?.value.label;
    }
    const addForm = this.medicationForm.get('details') as FormArray;
    let newFormGroup = new FormGroup({
      medication_name: new FormControl(medication_name_val !== "" ? medication_name_val : null, [Validators.required]),
    });
    addForm.push(newFormGroup);

    if (this.medicationForm.invalid) {
      this.commonService.validateAllFormFields(this.medicationForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    }

    this.dataSource = new MatTableDataSource(this.medicationForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.medicationDetailForm.reset();
    // this.medicationSubmit();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to update Medication update form value
   */
  updateMedication() {
    let medication_name_val = ''
    if (this.medicationDetailForm.controls['medication_name']?.value != undefined) {
      medication_name_val = this.medicationDetailForm.controls['medication_name']?.value;
    }
    if (this.medicationDetailForm.controls['medication_name']?.value.label != undefined) {
      medication_name_val = this.medicationDetailForm.controls['medication_name']?.value.label;
    }
    const updateForm = (<FormArray>this.medicationForm.get("details")).at(this.medicationUpdateIndex);
    updateForm.patchValue({
      medication_name: medication_name_val
    });

    if (this.medicationForm.invalid) {
      console.log(this.medicationForm);
      this.commonService.validateAllFormFields(this.medicationForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    }

    this.dataSource = new MatTableDataSource(this.medicationForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.medicationDetailForm.reset();
    // this.medicationSubmit();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function used to close and update value
   */
  closePopup() {
    this.medicationAdd = false;
    this.medicationUpdate = false;
    this.medicationUpdateIndex = null;
    this.togglepopup = !this.togglepopup;
    this.medicationDetailForm.reset();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This funtion is used to get updated value based on index
   * @param id
   */
  editMedication(id: any) {
    this.medicationUpdateIndex = id;
    this.medicationUpdate = true;
    this.togglepopup = !this.togglepopup;
    const medicationName = (<FormArray>this.medicationForm.get("details")).at(id).get('medication_name')?.value;
    console.log(medicationName);
    this.medicationDetailForm.controls['medication_name'].setValue(medicationName);
  }

  /**
   * @author Pramod Pramod Kushwaha
   * Enable form edit
   */
  clickOnEdit() {
    this.medicationFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.medicationFormStatus = false;
    this.medicationAdd = false;
    this.medicationUpdate = false;
    this.medicationUpdateIndex = null;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to reset Form
   * @param option
   */
  resetForm(option: string) {
    console.log(option);
    this.medicationForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.medicationForm.controls['details'].value);
    if (option == 'yes') {
      this.medicationAdd = true;
      this.togglepopup = !this.togglepopup;
    } else {
      this.medicationUpdate = true;
    }
    this.medicationUpdateIndex = null;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to delete element
   * @param option
   */
  medicationDelete(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Medication?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // this.medicationUpdate = true;
        (<FormArray>this.medicationForm.get("details"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.medicationForm.controls['details'].value);
        this.medicationSubmit();
      }
      console.log(result);
    });
  }

  /**
  * @author Pramod Pramod Kushwaha
  * @param field
  */
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  /**
   * @author Pramod Kushwaha
   * This funtion is used to submit Form Details
   * @param field
   */
  isFieldValid(field: string) {
    return (
      (this.medicationDetailForm.controls[field].invalid && this.medicationDetailForm.controls[field].dirty) ||
      (this.medicationDetailForm.controls[field].touched && this.medicationDetailForm.controls[field].invalid)
    );
  }

  /**
   * This function is used to search medication
   * @param filterValue
   */
  searchDrugs(filterValue: any) {
    this.loading = true;
    this.dictionaryService.searchDrugs(filterValue.value).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.medications = result.data;
        }
      },
      error: (e) => { },
      complete: () => {
        this.loading = false;
      },
    });
  }

}

