import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/service/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Family',
      dataKey: 'family',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['family']}`,
    },
    {
      name: 'Family Branch',
      dataKey: 'family_branch',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['family_branch']}`,
    },
  ];
  public isApproved: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/language/edit/';
  public title = 'Language';
  private subscriptions = new Subscription();
  public data: any;
  public currentMenuPermission: any;

  constructor(
    private languageService: LanguageService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
  ) {}

  ngOnInit() {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.loading = true;
    const routerSubscription = this.route.queryParams.subscribe(
      (queryParams: any) => {
        this.isApproved = 1;
        this.title = 'Language';
        this.getAll();
      }
    );
    this.subscriptions.add(routerSubscription);
  }

  /**
   * Get all Language
   *
   * @returns object
   */
  getAll() {
    this.loading = true;
    const languageSubscription = this.languageService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log(e.error);
        },
        complete: () => {
          this.loading = false;
        },
      });
    this.subscriptions.add(languageSubscription);
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }

  deleteLanguage(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the language?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        const languageDeleteSubscription = this.languageService
          .delete(event.id)
          .subscribe({
            next: (result: any) => {
              if (result.status === AppConstants.serverSuccessStatus) {
                this.commonService.showSuccessToast(
                  'Language deleted successfully'
                );
                this.getAll();
              } else {
                this.commonService.showErrorToast(
                  "Language can't be deleted. Please try again later."
                );
              }
            },
            error: (e: any) => {
              this.commonService.showErrorToast('Language not found');
            },
          });
        this.subscriptions.add(languageDeleteSubscription);
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
