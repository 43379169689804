<div class="page-title-box">
  <h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="{{cancelLink}}" title="Pincode" class="only-link">Pincode</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-8 col-md-12 col-sm-12">
      <form [formGroup]="pincodeForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">{{title}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.pincodeForm, 'pincode')">
                  <mat-label><i>*</i> Pincode</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput #input formControlName="pincode" placeholder="Enter pincode" type="text" (focusout)="
                                        checkUniquePincode($event)">
                  </mat-form-field>
                  <mat-error
                    *ngIf="this.pincodeForm.controls['pincode'].invalid && (this.pincodeForm.controls['pincode'].dirty || this.pincodeForm.controls['pincode'].touched)">
                    {{getPincodeError()}}
                  </mat-error>
                </div>
                <div class="input-wrape" *ngIf="isCurate">
                  <mat-label><i>*</i> Is Approved?:</mat-label>
                  <mat-radio-group formControlName="isApproved" aria-label="Select an option"
                    class="example-radio-group">
                    <mat-radio-button class="example-radio-button" id="radio_yes" (click)="selectOption(false)" [value]="true"
                      checked>
                      Approved</mat-radio-button>
                    <mat-radio-button class="example-radio-button" id="radio_no" (click)="selectOption(true)" [value]="false">
                      Map with others</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.pincodeForm, 'cityId')">
                <mat-label><i>*</i> City</mat-label>
                <div class="control-pad">
                  <ng-select [items]="cityList" formControlName="cityId"
                    bindLabel="name" bindValue="id" labelForId="cityId"
                    placeholder="Select city">
                  </ng-select>
                  <mat-error
                    *ngIf="this.pincodeForm.controls['cityId'].invalid && (this.pincodeForm.controls['cityId'].dirty || this.pincodeForm.controls['cityId'].touched)">
                    {{getSelectCityError()}}
                  </mat-error>
                </div>
            </div>
            <div class="input-wrape" *ngIf="selectMapOption" [class]="this.commonService.displayFieldCss(this.pincodeForm, 'mapWithOtherPincode')">
                <mat-label><i>*</i> Map With:</mat-label>
                  <div class="control-pad">
                      <ng-select [items]="pincodeList" formControlName="mapWithOtherPincode"
                        bindLabel="pincode" bindValue="id" labelForId="mapWithOtherPincode" (change)="mapWithPincodeChange($event)"
                        placeholder="Select pincode" (search)="getPincodeList($event)">
                      </ng-select>
                      <mat-error
                        *ngIf="this.pincodeForm.controls['mapWithOtherPincode'].invalid && (this.pincodeForm.controls['mapWithOtherPincode'].dirty || this.pincodeForm.controls['mapWithOtherPincode'].touched)">
                        {{getMapWithPincodeError()}}
                      </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
              title="Cancel">Cancel</button>
            <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
              title="{{buttonText}}">{{buttonText}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>