import { Component, OnInit, ViewChild } from '@angular/core';
//import * as echarts from 'echarts';
// table code start 
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
// table code end
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/service/site.service';
import { CommonService } from 'src/app/service/common.service';
import { SiteDayWiseService } from 'src/app/service/site-day-wise.service';
import { AppConstants } from 'src/app/app.constant';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
const ELEMENT_DATA: PopulationTable[] = [];
@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SiteDashboardComponent implements OnInit {

  public percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];
  public diseaseWisePercentOrCount: any = 2;
  public paramSiteDayWiseId: any;
  public dateFormate = "dd MMM yyyy";
  public dataSources = new MatTableDataSource<PopulationTable>(ELEMENT_DATA);
  public expandedElement: PopulationTable | undefined;
  public columnsToDisplay = ['day', 'site_date', 'camp_coordinator', 'camp_timing_from', 'camp_timing_to', 'diagnostic_lab_name'];
  public displayedColumns: string[] = [
    'day',
    'site_date',
    'camp_coordinator',
    'camp_timing_from',
    'camp_timing_to',
    'diagnostic_lab_name',
    'action'
  ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public siteDetailLoader: boolean = true;
  public siteDayWiseDetailLoader: boolean = true;
  public siteId: any;
  public siteDetail: any = "";
  public siteDayWiseDetail: any[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public matNoDataRowTitle: string = 'Loading Data...';

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private siteService: SiteService,
    private commonService: CommonService,
    private siteDayWiseService: SiteDayWiseService,
  ) {
    this.siteId = this.route.snapshot.paramMap.get("id");
  }

  ngAfterViewInit() {
    this.dataSources.sort = this.sort;
    this.dataSources.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.getSiteById(this.siteId);
    this.getSitesDayWiseBySiteId(this.siteId);
  }

  /**
  *
  * @param siteId
  */
  getSiteById(siteId: any) {
    this.siteDetailLoader = true;
    this.siteService.getRow(siteId).subscribe({
      next: (result: any) => {
        this.siteDetailLoader = false;
        this.siteDetail = result.data;
      },
      error: (err) => {
        this.siteDetailLoader = false;
        this.commonService.showErrorToast(
          "Something went wrong. Please contact to administrator."
        );
      }
    });
  }

  /**
   * Get all Sites Day Wise By SiteId
   *
   * @returns object
   */
  getSitesDayWiseBySiteId(siteId: any) {
    this.matNoDataRowTitle = 'Loading Data...';
    this.siteDayWiseDetailLoader = true;
    this.siteDayWiseService
      .getSitesDayWiseBySiteId(
        siteId,
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize)
      .subscribe({
        next: (result: any) => {
          this.siteDayWiseDetailLoader = false;
          this.dataSources = new MatTableDataSource(result.data.data);
          this.dataSources.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
          if (this.dataSources != undefined && this.dataSources.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
        }
      });
  }

  /**
   * Get day wise by id
   * @param row 
   */
  getDayWiseDataById(row: any) {
    if (this.siteDayWiseDetail[row.id] == undefined) {
      this.siteDayWiseDetail = [];
      this.siteDayWiseDetailLoader = true;
      this.siteDayWiseService.getRow(row.id).subscribe({
        next: (result: any) => {
          this.paramSiteDayWiseId = row.id;
          this.siteDayWiseDetail[row.id] = result.data;
          this.siteDayWiseDetailLoader = false;
        },
        error: (err) => {
          this.siteDayWiseDetailLoader = false;
          this.commonService.showErrorToast(
            "Something went wrong. Please contact to administrator."
          );
        },
      });
    } else {
      this.siteDayWiseDetail = [];
    }


  }
  isExpanded(row: any): string {
    
    if (this.siteDayWiseDetail[row.id]) {
      return 'expanded';
    }
    return 'collapsed';
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getSitesDayWiseBySiteId(this.siteId);
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getSitesDayWiseBySiteId(this.siteId);
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getSitesDayWiseBySiteId(this.siteId);
  }

  redirectSite(siteId: any) {
    this.router.navigate(['/site/edit/' + siteId]);
  }
  redirectSiteDayWise(siteInfoId: any) {
    if (siteInfoId == 0) {
      this.router.navigate(['/site/day-wise-form/' + this.siteId]);
    } else{
      this.router.navigate(['/site/day-wise-form/' + this.siteId + '/' + siteInfoId]);
    }
  }
  redirectSitePage() {
    this.router.navigate(['/site']);
  }
}

// table extend code start
export interface PopulationTable {
  day: string;
  date: string;
  camp_coordinator: string;
  camp_timing_from: string;
  camp_timing_to: string;
  diagnostic_lab_name: string;
  date_materials_packed_for_camp: string;
  materials_packed_for_number_of_participants: string;
  camp_supervisor_optional: string;
  camp_counsellor: string;
  camp_phlebotomist: string;
  total_number_of_participants_expected: string;
  data_logger_given_to: string;
  attachment_for_temp_logger: string;
  camp_deliverable_status: string;
  acknowledgment_of_sample_pickup: string;
  test_request_form_TRF_available: string;
  test_request_form_TRF_attachment: string;
  date_HTR_received_in_office: string;
  reports_received_by: string;
  number_of_reports_received: string;
  invoice_cleared_and_payment_done: string;
  reports_checked_and_invoice_verified_by: string;
  camp_report_delivery_status_delivered_pending: string;
  date_on_which_reports_dispatched_to_site: string;
  reports_delivered_by: string;
  soft_copies_of_reports_received_an_uploaded: string;
  soft_copies_of_invoice_received_and_uploaded: string;
  temperature_logger_sent_for_the_camp: string;
  gel_packs_delivered: string;
  camp_materials_checklist_attachment: string;
  shipment_bill_AWB_number: string;
  gate_pass_for_camp_materials_given_to: string;
  post_camp_key_lab_Office_Key_handed_over_to: string;
  cab_booked_for_camp: string;
  camp_photos_videos: string;
  cab_booked_by: string;
  no_of_cabs_booked: string;
  checkin_coordinator: string;
  checkin_counselor: string;
}

// table extend code end

