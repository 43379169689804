import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { CommonService } from 'src/app/service/common.service';
import { SurgeryService } from 'src/app/service/surgery.service';

@Component({
  selector: 'app-surgery',
  templateUrl: './surgery.component.html',
  styleUrls: ['./surgery.component.scss']
})
export class SurgeryComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
  ];
  public isApproved: number = 1;
  public isCurate: boolean = false;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/surgery/edit/';
  public title = 'Surgery';
  public data: any;
  public currentMenuPermission: any;

  constructor(
    private surgeryService: SurgeryService,
    private commonService: CommonService,
    protected router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.loading  = true;
    this.route.queryParams.subscribe((queryParams: any) => {
      if(this.router.url.indexOf('curation-pending') >= 0){

        this.isApproved = 0;
        this.isCurate = true;
        this.editLink = 'dictionary-management/surgery/curation-pending/';
      } else {
        this.isApproved = 1;
        this.isCurate = false;
      }
      this.getAll();
    });
  }

  /**
   * This function is use to get all Surgeries Listing
   * @author Farhan Shaikh
   */
  getAll() {
    this.loading = true;
    this.surgeryService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('Surgery Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }
  /**
   * call Delete Surgery api
   * @returns null
   */
  deleteSurgery(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Surgery?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.surgeryService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
            } else {
              this.commonService.showErrorToast(
                result.message
                );
            }
            this.getAll();
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Surgery not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
    }else{
      this.sortField = 'id';
    }
    this.getAll();
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
