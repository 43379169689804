import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

export interface surgeryTable {
  type_of_surgery: string
  year_of_surgery: string
  action: any
}

let ELEMENT_DATA: surgeryTable[] = [];

@Component({
  selector: 'app-participant-surgeries',
  templateUrl: './surgeries.component.html',
  styleUrls: ['./surgeries.component.scss']
})

export class SurgeriesComponent implements OnInit {

  public PID: any;
  public surgeryDetails: any;
  public surgeryForm!: FormGroup;
  public surgeryDetailForm!: FormGroup;
  public surgeryArray!: FormArray;
  public surgeryFormStatus: boolean = false;
  public surgeryUpdate: boolean = false;
  public surgeryUpdateIndex: any;
  public newObject: any;
  public participantDOB: any;
  public participantAge: any;
  public loading: boolean = false;
  public surgeryAdd: boolean = false;

  togglepopup: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  displayedColumns: string[] = ['type_of_surgery', 'year_of_surgery', 'action'];
  public dataSource = new MatTableDataSource<surgeryTable>(ELEMENT_DATA);

  ngOnInit(): void {
    this.surgeryForm = new FormGroup({});
    this.surgeryForm.setControl('option', new FormControl(null, [Validators.required]));
    this.surgeryForm.setControl('details', new FormArray([]));
    this.surgeryArray = <FormArray>this.surgeryForm.controls["details"];

    this.surgeryDetailForm = this.formBuilder.group({
      type_of_surgery: [null, []],
      year_of_surgery: [null, []]
    });

    this.getSurgery();
  }

  /**
 * @author Pramod Pramod Kushwaha
 * This function is used to submit form to server side
 */
  surgerySubmit() {
    this.loading = true;
    this.participantService.updateSurgery(this.surgeryForm.value, this.PID).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.surgeryForm.setControl('details', new FormArray([]));
          this.surgeryArray = <FormArray>this.surgeryForm.controls["details"];
          this.getSurgery();
          this.surgeryAdd = false;
          this.surgeryUpdate = false;
          this.surgeryUpdateIndex = null;
          this.surgeryDetailForm.reset();
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to get Surgery and set Value
  */
  getSurgery() {
    this.loading = true;
    this.participantService.getSurgery(this.PID).subscribe({
      next: (result: any) => {
        this.surgeryDetails = result.data;

        if(this.surgeryDetails.length === 0){
          this.loading = false;
          return;
        }

        this.surgeryForm.controls['option'].setValue(this.surgeryDetails?.option);
        this.participantDOB = this.surgeryDetails?.date_of_birth;
        this.participantAge = this.surgeryDetails?.age;
        this.dataSource = new MatTableDataSource(result.data.details);
        this.surgeryDetails.details.forEach((medication: any) => {
          this.surgeryArray.push(
            new FormGroup({
              type_of_surgery: new FormControl(medication.type_of_surgery),
              year_of_surgery: new FormControl(medication.year_of_surgery),
            })
          );
        });
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to Open Surgery and hide Update button
   */
  openSurgery() {
    this.surgeryAdd = true;
    this.surgeryUpdate = false;
    this.togglepopup = !this.togglepopup;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to add Surgery and set value
   */
  addSurgery() {
    const addForm = this.surgeryForm.get('details') as FormArray;

    let newFormGroup = new FormGroup({
      type_of_surgery: new FormControl(this.surgeryDetailForm.controls['type_of_surgery']?.value !== "" ? this.surgeryDetailForm.controls['type_of_surgery']?.value : null, []),
      year_of_surgery: new FormControl(this.surgeryDetailForm.controls['year_of_surgery']?.value !== "" ? this.surgeryDetailForm.controls['year_of_surgery']?.value : null, []),
    });

    addForm.push(newFormGroup);
    this.dataSource = new MatTableDataSource(this.surgeryForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.surgeryDetailForm.reset();
    // this.medicationSubmit();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to update Medication update form value
   */
  updateSurgery() {
    const updateForm = (<FormArray>this.surgeryForm.get("details")).at(this.surgeryUpdateIndex);

    updateForm.patchValue({
      type_of_surgery: this.surgeryDetailForm.controls['type_of_surgery']?.value,
      year_of_surgery: this.surgeryDetailForm.controls['year_of_surgery']?.value
    });

    this.dataSource = new MatTableDataSource(this.surgeryForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.surgeryDetailForm.reset();
    // this.medicationSubmit();
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function used to close and update value 
  */
  closePopup() {
    this.surgeryAdd = false;
    this.surgeryUpdate = false;
    this.surgeryUpdateIndex = null;
    this.togglepopup = !this.togglepopup;
    this.surgeryDetailForm.reset();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This funtion is used to get updated value based on index
   * @param id 
   */
  editSurgery(id: any) {
    this.surgeryUpdateIndex = id;
    this.surgeryUpdate = true;
    this.togglepopup = !this.togglepopup;
    const typeOfSurgery = (<FormArray>this.surgeryForm.get("details")).at(id).get('type_of_surgery')?.value;
    const yearOfSurgery = (<FormArray>this.surgeryForm.get("details")).at(id).get('year_of_surgery')?.value;
    console.log(typeOfSurgery, yearOfSurgery);
    this.surgeryDetailForm.controls['type_of_surgery'].setValue(typeOfSurgery);
    this.surgeryDetailForm.controls['year_of_surgery'].setValue(yearOfSurgery);
  }

  /**
   * @author Pramod Pramod Kushwaha
   * Enable form edit 
   */
  clickOnEdit() {
    this.surgeryFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close 
  */
  clickOnCancel() {    
    this.surgeryFormStatus = false;
    this.surgeryAdd = false;
    this.surgeryUpdate = false;
    this.surgeryUpdateIndex = null;
    this.surgeryDetails.reset();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to reset Form 
   * @param option 
   */
  resetForm(option: string) {
    console.log(option);
    this.surgeryForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.surgeryForm.controls['details'].value);

    if (option == 'yes') {
      this.surgeryAdd = true;
      this.togglepopup = !this.togglepopup;
    } else {
      this.surgeryUpdate = true;
    }

    this.surgeryUpdateIndex = null;
    this.surgeryDetailForm.reset();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to delete element
   * @param option 
   */
  surgeryDelete(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Surgery?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id);
        (<FormArray>this.surgeryForm.get("details"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.surgeryForm.controls['details'].value);
        this.surgerySubmit();
      }
      console.log(result);
    });
  }

  getYearRanges() {
    if (this.participantDOB != null && this.participantDOB != '') {
      const pdob = new Date(this.participantDOB);
      let currentYear = new Date().getFullYear();
      const years = [];
      const startYear = pdob.getFullYear();
      while (startYear <= currentYear) {
        years.push(currentYear--);
      }
      return years;
    } else {
      let pAge = this.participantAge;
      let currentYear = new Date().getFullYear();
      const years = [];

      if (pAge === 0) {
        pAge = 1;
      }
      const startYear = new Date().getFullYear() - Number(pAge);

      while (startYear <= currentYear) {
        years.push(currentYear--);
      }
      return years;
    }
  }
}
