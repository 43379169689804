import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiseaseService extends CrudService {
  public dictionaryBaseUrl: string = `${environment.baseURL}` + 'dictionary';

  constructor(public override http: HttpClient) {
    super('disease', http);
  }

  /**
   * Check disease field is unique
   * @returns object
   */
  checkUniqueDiseaseName(diseaseName: string, id: number) {
    return this.http.get(
      `${this.baseUrl}/check-unique-disease/${diseaseName}/${id}`
    );
  }

  /**
   * @author Dhaval Bera
   * This function is used to get Disease Name List Dictionary
   */
  getDiseaseNameDictionary() {
    return this.http.get(
      this.dictionaryBaseUrl + '/get?dictionary_type=disease_name'
    );
  }

  /**
   *
   * @param id
   * @returns
   */
   makeUnapproved(id: number) {
    return this.http.get(`${this.baseUrl}/make-unapproved/${id}`);
  }
  
  /**
   *
   * @param id
   * @returns
   */
   getParticipantDisease() {
    return this.http.get(`${this.baseUrl}/participant/disease`);
  }
}
