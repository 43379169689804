<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
      <li>{{title}}</li>
      <li><a routerLink="{{cancelLink}}" title="City" class="only-link">City</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-xl-8 col-md-12 col-sm-12">
            <form [formGroup]="cityForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
	    	      <div class="card-wrape">
                  <div class="card-head">
                      <h4 class="left-text">{{title}}</h4>
                  </div>
                  <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6 col-md-6">
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.cityForm, 'name')">
                                <mat-label><i>*</i> Name</mat-label>
                                  <mat-form-field appearance="outline">
                                    <input matInput #input 
                                      formControlName="name" 
                                      placeholder="Enter name" 
                                      type="text"
                                      (focusout)="
                                      checkUniqueCityFields($event)"
                                    >
                                </mat-form-field>
                                  <mat-error *ngIf="this.cityForm.controls['name'].invalid && ( this.cityForm.controls['name'].touched || this.cityForm.controls['name'].dirty)">
                                      {{getCityNameError()}}
                                  </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.cityForm, 'stateId')">
                                <mat-label><i>*</i> State</mat-label>
                                <div class="control-pad">
                                    <ng-select matInput [items]="stateList"
                                      formControlName="stateId" bindLabel="name" bindValue="id"
                                      labelForId="stateId" placeholder="Select state">
                                    </ng-select>
                                    <mat-error *ngIf="this.cityForm.controls['stateId'].invalid && ( this.cityForm.controls['stateId'].touched || this.cityForm.controls['stateId'].dirty) ? 'has-error' : '' ">
                                      {{getSelectStateError()}}
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6">
                          <div class="input-wrape">
                            <mat-label>Locality</mat-label>
                              <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                                <input matInput #input formControlName="locality" placeholder="Enter locality" type="text">
                            </mat-form-field>
                          </div>
                          <div class="input-wrape" *ngIf="isCurate">
                            <mat-label><i>*</i> Is Approved?:</mat-label>
                            <mat-radio-group aria-label="Select an option" class="example-radio-group" formControlName="isApproved">
                              <mat-radio-button
                                class="example-radio-button"
                                id="radio_yes"
                                (click)="selectOption(false)"
                                [value]="true" checked
                              >
                                Approved</mat-radio-button>
                              <mat-radio-button
                                class="example-radio-button"
                                id="radio_no"
                                (click)="selectOption(true)"
                                [value]="false"
                              >
                                Map with others</mat-radio-button>
                            </mat-radio-group>
                          </div>
                          <div class="input-wrape" *ngIf="selectMapOption" [class]="this.cityForm.controls['mapWithOtherCity'].invalid && ( this.cityForm.controls['mapWithOtherCity'].touched || this.cityForm.controls['mapWithOtherCity'].dirty) ? 'has-error' : '' ">
                            <mat-label><i>*</i> Map With:</mat-label>
                            <div class="control-pad">
                                <ng-select matInput [items]="cityList" [disabled]="true"
                                  formControlName="mapWithOtherCity" bindLabel="name" bindValue="id"
                                  labelForId="mapWithOtherCity" (change)="mapWithCityChange($event)" placeholder="Select city">
                                </ng-select>
                            </div>
                            <mat-error *ngIf="this.cityForm.controls['mapWithOtherCity'].invalid && ( this.cityForm.controls['mapWithOtherCity'].touched || this.cityForm.controls['mapWithOtherCity'].dirty)">
                              {{getMapWithCityError()}}
                            </mat-error>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="card-footer">
                      <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}" title="Cancel">Cancel</button>
                      <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn" title="{{buttonText}}">{{buttonText}}</button>
                  </div>
              </div>
            </form>
          </div>
      </div>
  </section>