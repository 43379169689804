import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CityService } from 'src/app/service/city.service';
import { EthicsCommitteeService } from 'src/app/service/ethics-committee.service';
import { ProjectService } from 'src/app/service/project.service';
import { StateService } from 'src/app/service/state.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { isNullOrUndefined } from 'is-what';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-ethics-committee-form',
  templateUrl: './ethics-committee-form.component.html',
  styleUrls: ['./ethics-committee-form.component.scss']
})

export class EthicsCommitteeFormComponent implements OnInit {

    public ethicsCommitteeForm!: FormGroup;
    public title: any;
    public ethicsCommitteeId: any;
    public isAddMode: boolean = true;
    public buttonText: string = "";
    public cancelLink: string = "/site/ethics-committee";
    public userId: any = "";
    public isActive: boolean = true;
    public loading: boolean = false;
    public ethicsCommitteeNameMinLength: number = 3;
    public ethicsCommitteeNameMaxLength: number = 255;
    public ethicsCommitteeDetails: any = [];
    public cityList: any = [];
    public stateList: any = [];
    public projectList:any = [];
    public todayDate: Date = new Date(); 
    annualMinDate: any;
    nextRenewalMinDate: any;


  // Multiple file upload variables
  informedConsentFormFiles: string[] = [];
  public informedConsentFormFile?: File[] = [];

  translationCertificateFiles: string[] = [];
  public translationCertificateFile?: File[] = [];

  investigatorDeclarationFiles: string[] = [];
  public investigatorDeclarationFile?: File[] = [];

  assentFormFiles: string[] = [];
  public assentFormFile?: File[] = [];

  // Single file upload variables
  projectApprovalLetterFiles: Blob[] = [];
  public projectApprovalLetterFile?: File[] = [];

  researchProtocolFiles: Blob[] = [];
  public researchProtocolFile?: File[] = [];

  patientHistoryFormFiles: Blob[] = [];
  public patientHistoryFormFile?: File[] = [];

  patientInformationFormFiles: Blob[] = [];
  public patientInformationFormFile?: File[] = [];

  cvOfPISIFiles: Blob[] = [];
  public cvOfPISIFile?: File[] = [];

  annualProgressReportFiles: Blob[] = [];
  public annualProgressReportFile?: File[] = [];

  insurancePolicyFiles: Blob[] = [];
  public insurancePolicyFile?: File[] = [];
    
    constructor(
      private ethicsCommitteeService: EthicsCommitteeService,
      private projectService: ProjectService,
      private cityService: CityService,
      private stateService: StateService,
      public commonService: CommonService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      protected router: Router
    ) {
      this.ethicsCommitteeId = this.route.snapshot.paramMap.get("id");
      if (this.ethicsCommitteeId !== 0 && this.ethicsCommitteeId !== null) {
        this.title = "Edit Ethics Committee";
        this.buttonText = "Save";
      } else {
        this.title = "Add Ethics Committee";
        this.buttonText = "Add";
        this.ethicsCommitteeId = 0;
        this.nextRenewalMinDate = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate()+ 0); //+0 is adding days
        this.annualMinDate = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate()+ 0); //+0 is adding days
      }
    }
  
    ngOnInit(): void {
      
      if (this.ethicsCommitteeId !== 0 && this.ethicsCommitteeId !== null) {
          this.setEditData();
      }

      this.getProjectList();
      this.getCityList();
      this.getStateList();

      this.isAddMode = !this.ethicsCommitteeId;
  
      this.userId = localStorage.getItem("userId");
      this.ethicsCommitteeForm = this.formBuilder.group({
        'name': ['', [Validators.required, Validators.minLength(this.ethicsCommitteeNameMinLength), Validators.maxLength(this.ethicsCommitteeNameMaxLength)]],
        'projectId':[null,  [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
        'cityId':[null,  [Validators.pattern("^[1-9][0-9]*$")]],
        'stateId':[null,  [Validators.pattern("^[1-9][0-9]*$")]],
        'originalApprovalDate':['',[]],
        'annualExpiryDateOfPolicy':['',[]],
        'nextRenewalDateOfPolicy':['',[]],
        'researchProtocolVersion':['', [Validators.required]],
        'informedConsentFormVersion':['', [Validators.required]],
        'assentFormVersion':['', [Validators.required]],
        'patientHistoryFormVersion':['',[]],
        'patientInformationFormVersion':['',[]],
        'projectApprovalLetter':['', (this.isAddMode == true ? [Validators.required] : [])],
        'researchProtocolAttachment':['', (this.isAddMode == true  ? [Validators.required] : [])],
        'informedConsentFormAttachment':['', (this.isAddMode  == true ? [Validators.required] : [])],
        'assentFormAttachment':['',[]],
        'patientHistoryFormAttachment':['',[]],
        'patientInformationFormAttachment':['',[]],
        'translationCertificateAttachment':['',[]],
        'investigatorDeclarationAttachment':['',[]],
        'cvOfPISIAttachment':['',[]],
        'insurancePolicyAttachment':['',[]],
        'annualProgressReportAttachment':['', (this.isAddMode  == true ? [Validators.required] : [])],
        'isActive':[this.isActive,[]],
      });
    
    //ngOninit end
    }

    setEditData() {
      this.ethicsCommitteeService.getRow(this.ethicsCommitteeId)
      .subscribe({
        next: (result: any) => {
          if (result.status !== AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
          }
        this.ethicsCommitteeDetails = result.data;
        setTimeout(() => {
            this.ethicsCommitteeForm.patchValue({
              name: this.ethicsCommitteeDetails.name,
              projectId: this.ethicsCommitteeDetails.project_id,
              researchProtocolVersion: this.ethicsCommitteeDetails.research_protocol_version,
              informedConsentFormVersion:
                this.ethicsCommitteeDetails.informed_consent_form_version,
              assentFormVersion:
                this.ethicsCommitteeDetails.assent_form_version,
              patientHistoryFormVersion:
                this.ethicsCommitteeDetails.patient_history_form_version,
              patientInformationFormVersion:
                this.ethicsCommitteeDetails.patient_information_form_version,
              cityId: this.ethicsCommitteeDetails.city_id,
              stateId: this.ethicsCommitteeDetails.state_id,
              nextRenewalDateOfPolicy: (this.ethicsCommitteeDetails.next_renewal_at ? this.commonService.transformDate(
                this.ethicsCommitteeDetails.next_renewal_at) : ''
              ),
              annualExpiryDateOfPolicy: (this.ethicsCommitteeDetails.annual_expiry_at ? this.commonService.transformDate(
                this.ethicsCommitteeDetails.annual_expiry_at) : ''
              ),
              originalApprovalDate: (this.ethicsCommitteeDetails.original_approval_at ? this.commonService.transformDate(
                this.ethicsCommitteeDetails.original_approval_at) : ''
              ),
              isActive: this.ethicsCommitteeDetails.status,
            });
        }, 300);
      },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }
  
    getEthicsCommitteeNameError() {
      if (this.ethicsCommitteeForm.controls['name'].errors!['required']) {
        return 'Name is required.';
      }
      if (this.ethicsCommitteeForm.controls['name'].errors!['minlength']) {
        return 'Min length is '+ this.ethicsCommitteeNameMinLength +' characters.';
      }
      if (this.ethicsCommitteeForm.controls['name'].errors!['maxlength']) {
        return 'Max length is '+ this.ethicsCommitteeNameMaxLength +' characters.';
      }
      if (this.ethicsCommitteeForm.controls['name'].errors!['unique']) {
        return this.ethicsCommitteeForm.get("name")!.errors!['unique'];
      }
      return ''; 
    }

    getProjectNameError(){
      if (this.ethicsCommitteeForm.controls['projectId'].errors!['required']){
        return 'Project is required';
      } 
      if(this.ethicsCommitteeForm.controls['projectId'].errors!['pattern']) {
        return 'Please select valid project';
      }
      return ''; 
    }

    getRequiredError(formControlName: any){
      const formControl = this.ethicsCommitteeForm.controls[formControlName];
      if (formControl.errors!['required']) {
        if(formControlName == 'researchProtocolVersion'){
          return 'Research protocol (RP) version is required'; 
        } else if(formControlName == 'informedConsentFormVersion'){
          return 'Informed Consent Form (ICF) version is required'; 
        } else if(formControlName == 'assentFormVersion'){
          return 'Assent form (AF) version is required'; 
        } else if(formControlName == 'projectApprovalLetter'){
          return 'Project approval letter is required';
        } else if(formControlName == 'researchProtocolAttachment'){
          return 'Research protocol (RP) attachment is required';
        } else if(formControlName == 'informedConsentFormAttachment'){
          return 'Informed consent form (ICF) attachment is required';
        } else if(formControlName == 'annualProgressReportAttachment'){
          return 'Annual progress report is required'; 
        }
      }
      return ''; 
    }

    getDateError(formControlName: any){
      const formControl = this.ethicsCommitteeForm.controls[formControlName];
      if (formControl.errors) {
        if(formControlName == 'originalApprovalDate'){
          return 'Invalid Date'; 
        } else if(formControlName == 'nextRenewalDateOfPolicy'){
          return 'Invalid Date'; 
        } else if(formControlName == 'annualExpiryDateOfPolicy'){
          return 'Invalid Date'; 
        }
      }
      return '';       
    }

    getPDFFileError(formControlName: any){
      const formControl = this.ethicsCommitteeForm.controls[formControlName];
      console.log('this is formControlName', formControlName, formControl.errors);
      if (formControl.errors!['required']) {
        return this.getRequiredError(formControlName);
      }
      else if (formControl.errors!['fileValidType']) {
        return formControl.errors!['fileValidType'];
      }
      else if (formControl.errors!['fileValidSize']) {
        return formControl.errors!['fileValidSize'];
      }
      return '';
    }

    /**
   * Files Validation check
   * @returns object
   */
    selectPDFFiles(
      event: any,
      filesVariableName: string,
      singleFileVariableName: string,
      formElement: string = ''
    ) {
      if((this as any)[filesVariableName] !== undefined){
      for (let i = 0; i < event.target.files.length; i++) {
        (this as any)[singleFileVariableName] = <File>event.target.files[i];
        if (
          (this as any)[singleFileVariableName] !== undefined &&
          (this as any)[singleFileVariableName].type !== "application/pdf"
          ) {
            (this as any)[filesVariableName] = [];
            this.ethicsCommitteeForm
            .controls[formElement]
            .setErrors({ fileValidType: "File should be .pdf type" });
        } else if (
            (this as any)[singleFileVariableName] !== undefined &&
            (this as any)[singleFileVariableName].size / 1024 / 1024 > 20
        ) {
          (this as any)[filesVariableName] = [];
          this.ethicsCommitteeForm.controls[formElement].setErrors({
            fileValidSize: "Files size limit exceeded. Max file size is 20 MB.",
          });
        } else {
          (this as any)[filesVariableName].push(event.target.files[i]);
        }
      }
    }
  }

  /**
  * Get City List
  *
  * @returns object
  */
  searchCities(event: any) {
    if (event.term !== undefined && event.term.length > 2) {
      this.cityService.searchCityByString(event.term).subscribe((result: any) => {
        this.cityList = result.data;
      });
    }
  }

    /**
     * submit form
     * @returns null
    */
   onSubmit(): void {  
      if (this.ethicsCommitteeForm.invalid) {
        this.commonService.validateAllFormFields(this.ethicsCommitteeForm);
        // this.commonService.showErrorToast(
        //   'OOPS! Please enter correct values'
        // );
        return;
      }

      this.onSubmitFileValidate("projectApprovalLetterFiles", "projectApprovalLetter");
      this.onSubmitFileValidate("researchProtocolFile", "researchProtocolAttachment");
      this.onSubmitFileValidate("informedConsentFormFile", "informedConsentFormAttachment");
      this.onSubmitFileValidate("assentFormFile", "assentFormAttachment");
      this.onSubmitFileValidate("patientHistoryFormFile", "patientHistoryFormAttachment");
      this.onSubmitFileValidate("patientInformationFormFile", "patientInformationFormAttachment");
      this.onSubmitFileValidate("translationCertificateFile", "translationCertificateAttachment");
      this.onSubmitFileValidate("investigatorDeclarationFile", "investigatorDeclarationAttachment");
      this.onSubmitFileValidate("cvOfPISIFile", "cvOfPISIAttachment");
      this.onSubmitFileValidate("annualProgressReportFile", "annualProgressReportAttachment");
      this.onSubmitFileValidate("insurancePolicyFile", "insurancePolicyAttachment");

      const formData: FormData = new FormData();
      formData.append('name', this.ethicsCommitteeForm.controls['name'].value);
      formData.append('project_id', this.ethicsCommitteeForm.controls['projectId'].value);

      formData.append(
        "research_protocol_version",
        this.ethicsCommitteeForm.controls["researchProtocolVersion"].value
          ? this.ethicsCommitteeForm.controls["researchProtocolVersion"]
              .value
          : ''
      );
      formData.append(
        "informed_consent_form_version",
        this.ethicsCommitteeForm.controls["informedConsentFormVersion"]
          .value
          ? this.ethicsCommitteeForm.controls["informedConsentFormVersion"]
              .value
          : ''
      );
      formData.append(
        "assent_form_version",
        this.ethicsCommitteeForm.controls["assentFormVersion"].value
          ? this.ethicsCommitteeForm.controls["assentFormVersion"].value
          : ''
      );
      formData.append(
        "patient_history_form_version",
        this.ethicsCommitteeForm.controls["patientHistoryFormVersion"]
          .value
          ? this.ethicsCommitteeForm.controls["patientHistoryFormVersion"]
              .value
          : ''
      );
      formData.append(
        "patient_information_form_version",
        this.ethicsCommitteeForm.controls["patientInformationFormVersion"]
          .value
          ? this.ethicsCommitteeForm.controls[
              "patientInformationFormVersion"
            ].value
          : ''
      );
      
      if (this.ethicsCommitteeForm.controls["nextRenewalDateOfPolicy"].value) {
        formData.append(
          "next_renewal_at",
          this.commonService.transformDate(
            this.ethicsCommitteeForm.controls["nextRenewalDateOfPolicy"].value
          ) as unknown as Blob
        );
      } else {
        formData.append("next_renewal_at", "");
      }
      
      if (this.ethicsCommitteeForm.controls["annualExpiryDateOfPolicy"].value) {
        formData.append(
          "annual_expiry_at",
          this.commonService.transformDate(
            this.ethicsCommitteeForm.controls["annualExpiryDateOfPolicy"].value
          ) as unknown as Blob
        );
      } else {
        formData.append("annual_expiry_at", "");
      }

      if (this.ethicsCommitteeForm.controls["originalApprovalDate"].value) {
        formData.append(
          "original_approval_at",
          this.commonService.transformDate(
            this.ethicsCommitteeForm.controls["originalApprovalDate"].value
          ) as unknown as Blob
        );
      } else {
        formData.append("original_approval_at", "");
      }
      
      formData.append("city_id", (this.ethicsCommitteeForm.controls["cityId"].value) ? this.ethicsCommitteeForm.controls["cityId"].value : 0);
      formData.append("state_id", (this.ethicsCommitteeForm.controls["stateId"].value) ? this.ethicsCommitteeForm.controls["stateId"].value : 0);
      
      if (!isNullOrUndefined(this.projectApprovalLetterFiles[0])) {
        formData.append(
          "project_approval_letter_attachment",
          this.projectApprovalLetterFiles[0]
        );
      }
      if (!isNullOrUndefined(this.researchProtocolFiles[0])) {
        formData.append(
          "research_protocol_attachment",
          this.researchProtocolFiles[0]
        );
      }
      if (!isNullOrUndefined(this.patientHistoryFormFiles[0])) {
        formData.append(
          "patient_history_form_attachment",
          this.patientHistoryFormFiles[0]
        );
      }
      if (!isNullOrUndefined(this.patientInformationFormFiles[0])) {
        formData.append(
          "patient_information_form_attachment",
          this.patientInformationFormFiles[0]
        );
      }
      if (!isNullOrUndefined(this.cvOfPISIFiles[0])) {
        formData.append("cv_of_pi_si_attachment", this.cvOfPISIFiles[0]);
      }
      if (!isNullOrUndefined(this.annualProgressReportFiles[0])) {
        formData.append(
          "annual_progress_report_attachment",
          this.annualProgressReportFiles[0]
        );
      }
      if (!isNullOrUndefined(this.insurancePolicyFiles[0])) {
        formData.append(
          "insurance_policy_attachment",
          this.insurancePolicyFiles[0]
        );
      }
  
      if (!isNullOrUndefined(this.informedConsentFormFiles[0])) {
        for (let j = 0; j < this.informedConsentFormFiles.length; j++) {
          formData.append(
            "informed_consent_form_attachment[]",
            this.informedConsentFormFiles[j]
          );
        }
      }
      if (!isNullOrUndefined(this.assentFormFiles[0])) {
        for (let j = 0; j < this.assentFormFiles.length; j++) {
          formData.append("assent_form_attachment[]", this.assentFormFiles[j]);
        }
      }
      if (!isNullOrUndefined(this.translationCertificateFiles[0])) {
        for (let j = 0; j < this.translationCertificateFiles.length; j++) {
          formData.append(
            "translation_certificate_attachment[]",
            this.translationCertificateFiles[j]
          );
        }
      }
      if (!isNullOrUndefined(this.investigatorDeclarationFiles[0])) {
        for (let j = 0; j < this.investigatorDeclarationFiles.length; j++) {
          formData.append(
            "investigator_declaration_attachment[]",
            this.investigatorDeclarationFiles[j]
          );
        }
      }
  
      if (this.ethicsCommitteeId === 0) {
        formData.append("created_by", this.userId);
      } else {
        formData.append("updated_by", this.userId);
      }
      formData.append(
        "status",
        this.ethicsCommitteeForm.controls["isActive"].value
      );

      this.loading = true;
      if (this.isAddMode) {
          this.createEthicsCommittee(formData);
      } else {
          this.updateEthicsCommittee(formData);
      }
    }
    
    /**
     * call Create Ethics Committee api
     * @returns null
     */
    private createEthicsCommittee(formData: any): void {
      this.ethicsCommitteeService.store(formData)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(
              result.message
            );
            this.router.navigate([this.cancelLink]);
          }else{
            this.commonService.showErrorToast(
              result.message
            );
            if(result.data){
              this.validatePdfFilesFromApiResponse(result.data);
            }
          }
          this.loading = false;
        },
        error:(err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
          this.loading = false;
        }
      })
    }
    
    /**
     * call Update Ethics Committee api
     * @returns null
     */
    private updateEthicsCommittee(formData: any): void {
      formData.append('updated_by', this.userId);
      this.ethicsCommitteeService.update(formData, this.ethicsCommitteeId)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
            this.router.navigate([this.cancelLink]);
          }else{
            this.commonService.showErrorToast(
              result.message
            );
            if(result.data){
              this.validatePdfFilesFromApiResponse(result.data);
            }
          }
          this.loading = false;
        },
        error:(err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
          this.loading = false;
        }
      })
    }

    // validate files from response
    validatePdfFilesFromApiResponse(data: any){
      let fileArray: any = [];
          fileArray = {
            'project_approval_letter_attachment':'projectApprovalLetter',
            'research_protocol_attachment':'researchProtocolAttachment',
            'patient_history_form_attachment':'patientHistoryFormAttachment',
            'patient_information_form_attachment':'patientInformationFormAttachment',
            'cv_of_pi_si_attachment':'cvOfPISIAttachment',
            'annual_progress_report_attachment':'annualProgressReportAttachment',
            'insurance_policy_attachment':'insurancePolicyAttachment',
            'informed_consent_form_attachment':'informedConsentFormAttachment',
            'assent_form_attachment':'assentFormAttachment',
            'translation_certificate_attachment':'translationCertificateAttachment',
            'investigator_declaration_attachment':'investigatorDeclarationAttachment'
          };
          
          // convert result data object to array
          const mappedResultData = Object.keys(data).map(key => ({file_name: key, error_msg: data[key]}));
          
          // check file validation exists
          mappedResultData.forEach((value: any) => {
              this.setInvalidFileError(fileArray[value['file_name']]);
          });
    }

    // set invalid file error
    setInvalidFileError(fileElement: string){
      this.ethicsCommitteeForm.get(fileElement)!
        .setErrors({ fileValidType: "File should be .pdf type" });
    }

    /**
     * Check File Validation on Submit
     * @returns null
     */
    onSubmitFileValidate(fileVariable: string, fileElement: string) {
      
      if ( (this as any)[fileVariable] !== undefined && (this as any)[fileVariable].length > 0){
        for (let i = 0; i < (this as any)[fileVariable].length; i++) {
          if (
            !isNullOrUndefined((this as any)[fileVariable]) &&
            (this as any)[fileVariable][i].type !== "application/pdf"
          ) {
            this.ethicsCommitteeForm.controls[fileElement]
            .setErrors({ fileValidType: "Please select a valid file." });
            this.commonService.showErrorToast(
              'OOPS! Please enter correct values'
              );
              return;
          }
        }
      }
    }
    
    /**
     * Check Ethics Committee field is existed or not
     * @returns null
     */
     checkUniqueEthicsCommittee(event: any) {
      const ethicsCommitteeFieldValue = (event.target as HTMLInputElement).value;
      if (ethicsCommitteeFieldValue.length > this.ethicsCommitteeNameMaxLength) {
        return false;
      }
  
        if (ethicsCommitteeFieldValue.length < this.ethicsCommitteeNameMinLength) {
          return false;
        }
    
      if (ethicsCommitteeFieldValue !== "") {
          this.ethicsCommitteeService
          .checkUniqueEthicsCommittee(
            ethicsCommitteeFieldValue,
            this.ethicsCommitteeId
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.ethicsCommitteeForm.get("name")!.setErrors(null);
              } else {
                this.ethicsCommitteeForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
              }
            },
            error: (error: any) => {
              if (error.status === 400) {
                this.ethicsCommitteeForm
                  .get("name")!
                  .setErrors({ unique: "Name already exists" });
              } else {
                console.log(
                  "Something went wrong. Please contact to administrator."
                );
              }
            }
          });
      }
      return null;
    }

    /**
       * Get all city
       * @returns object
       */
     // Get City Dictionary
     getCityList(){
      this.cityService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.cityList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    /**
       * Get all State
       * @returns object
       */
     // Get State Dictionary
     getStateList(){
      this.stateService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.stateList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    /**
     * Get State By City List
     *
     * @returns object
     */
    getStateByCity(event: any) {
      this.stateService.getStateByCity(event.id)
      .subscribe({
        next: (result: any) => {
        this.ethicsCommitteeForm.patchValue({
          stateId: (result.data && result.data[0]) ? result.data[0].id : null,
        });
        },
        error: (e: any) => {
          console.log(
          e.error
          );
        },
      });
    }

    /**
       * Get all Project
       * @returns object
       */
     // Get Project Dictionary
     getProjectList(){
      this.projectService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.projectList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

  }
  
  
