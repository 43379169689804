import { Component, Inject, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';

@Component({
  selector: 'app-medical-record',
  templateUrl: './medical-record.component.html',
  styleUrls: ['./medical-record.component.scss']
})
export class MedicalRecordComponent implements OnInit {
  public albums: any[] = [];
  public statusTableform: boolean = false;
  public disableTableform: boolean = true;
  public enableTableform: boolean = false;
  public loading: boolean = false;
  public PID: any;
  public imageFiles: Blob[] = [];
  public maxImageValid: boolean = true;
  public selectedFile!: File;
  public imageValid: boolean = true;
  public imageValidSize: boolean = true;


  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private participantService: ParticipantService,
    private route: ActivatedRoute,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getAllMedicalReports();
  }

  getAllMedicalReports() {
    this.loading = true;
    this.participantService.getAllMedicalReports(this.PID).subscribe({
      next: (result: any) => {
        this.albums = [];
        if (result.data.length > 0) {
          result.data.forEach((element: any, index: number) => {
            const album = {
              src: element.path,
              fileType: element.fileType,
              caption: "Document " + (index + 1),
              name: element.name,
              thumb: element.path_thumb,
              path: element.path,
            };
            this.albums.push(album);
          });
        }
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  deletePMR(fileName: string, index: number) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.participantService.deletePastMedicalRecord(fileName).subscribe({
          next: (prmResult: any) => {
            this.loading = false;
            if (prmResult.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast("Past Medical Record deleted successfully");
              if (index !== -1) {
                this.albums.splice(index, 1);
              }
            } else {
            }
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.showSuccessToast("Something went wrong. Please contact to administrator.");
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  onSelectFile(event: any) {
    const finalCount = this.albums.length + event.target.files.length;
    this.imageFiles = [];

    if (finalCount > 100) {
      this.imageFiles = [];
      this.maxImageValid = false;
      this.commonService.showErrorToast('More than 100 files cannot be selected');
      return;
    }

    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.selectedFile = <File>event.target.files[i];
        if (this.selectedFile.size > 20971520) {
          this.imageFiles = [];
          this.imageValid = true;
          this.maxImageValid = true;
          this.imageValidSize = false;
          return;
        }

        if (this.selectedFile.type !== "image/png" && this.selectedFile.type !== "image/gif" &&
          this.selectedFile.type !== "image/jpg" && this.selectedFile.type !== "image/jpeg" &&
          this.selectedFile.type !== "application/pdf"
        ) {
          this.imageFiles = [];
          this.imageValidSize = true;
          this.maxImageValid = true;
          this.imageValid = false;
          return;
        } else {
          this.imageValid = true;
          this.maxImageValid = true;
          this.imageValidSize = true;
          this.imageFiles.push(event.target.files[i]);
        }
      }
    }
    event.target.value = null;
  }



  clickEventTableform() {
    this.statusTableform = !this.statusTableform;
    this.disableTableform = false;
    this.enableTableform = true;
  }
  cancelEventTableform() {
    this.statusTableform = !this.statusTableform;
    this.disableTableform = true;
    this.enableTableform = false;
  }
  saveEventTableform() {

    if (this.imageFiles.length <= 0) {
      this.commonService.showErrorToast('Please select files');
      return;
    }
    if (this.imageValid === false) {
      this.commonService.showErrorToast('Please select valid file');
      return;
    }
    const formData: FormData = new FormData();
    if (!this.commonService.isNullOrUndefined(this.selectedFile)) {
      for (let i = 0; i < this.imageFiles.length; i++) {
        formData.append("pmr_file[]", this.imageFiles[i]);
      }
    }
    this.loading = true;
    this.participantService.uploadPastMedicalRecord(formData, this.PID).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          setTimeout(() => {
            this.getAllMedicalReports();
          }, 2000);
          this.imageFiles = [];
          this.commonService.showSuccessToast("Participant Medical Record uploaded successfully");
          this.statusTableform = !this.statusTableform;
          this.disableTableform = true;
          this.enableTableform = false;
        } else {
          this.loading = false;
        }
      },
      error: (err: any) => {
        this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
        this.loading = false;
      }
    });

  }

  openDialog(report: any) {
    this.dialog.open(DialogEmaple, { data: { image: report.src } });
  }
  downloadMedicalRecordDoc(report: any) {
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append("file_name", report.name);
    this.participantService.downloadMedicalRecordDoc(formData, this.PID).subscribe({
      next: (result: any) => {
        var link = document.createElement('a');
        link.href = result.data.url;
        link.click();
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }
}

// dialog code start 
@Component({
  selector: 'dialogEmaple',
  templateUrl: 'dialog-emaple.html',
})
export class DialogEmaple {
  image: string = '../../../../../../assets/images/patient-pic-1.jpg';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogEmaple>
  ) {
    if (data.image != undefined && data.image != '') {
      this.image = data.image;
    }
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
}
// dialog code end