import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class CityService extends CrudService {

  constructor(public override http: HttpClient) {
    super('city', http);
  }

  /**
   * Check City field is unique
   *
   * @param string cityFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueCityFields(cityFieldValue: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique-city/${cityFieldValue}/${id}`);
  }

  /**
* get Site By ID
* @param id
*/
  searchCityByString(pincode: any) {
    return this.http.get(`${this.baseUrl}` + '/search?name=' + pincode);
  }
}
