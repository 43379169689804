import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/main-container/dashboard/dashboard.component';
import { ManageUsersComponent } from './components/main-container/manage-users/manage-users.component';
import { AddUserComponent } from './components/main-container/manage-users/add-user/add-user.component';
import { EditUserComponent } from './components/main-container/manage-users/edit-user/edit-user.component';
import { RoleComponent } from './components/main-container/role/role.component';
import { CountryComponent } from './components/main-container/dictionary-management/country/country.component';
import { CountryFormComponent } from './components/main-container/dictionary-management/country/country-form/country-form.component';
import { StateComponent } from './components/main-container/dictionary-management/state/state.component';
import { LanguageComponent } from './components/main-container/dictionary-management/language/language.component';
import { LanguageFormComponent } from './components/main-container/dictionary-management/language/language-form/language-form.component';
import { NotFoundComponent } from './components/main-container/miscellaneous/not-found/not-found.component';
import { AccessDeniedComponent } from './components/main-container/miscellaneous/access-denied/access-denied.component';
import { RoleGuard } from './role.guard';
import { StateFormComponent } from './components/main-container/dictionary-management/state/state-form/state-form.component';
import { CityComponent } from './components/main-container/dictionary-management/city/city.component';
import { CityFormComponent } from './components/main-container/dictionary-management/city/city-form/city-form.component';
import { PincodeComponent } from './components/main-container/dictionary-management/pincode/pincode.component';
import { PincodeFormComponent } from './components/main-container/dictionary-management/pincode/pincode-form/pincode-form.component';
import { ReligionComponent } from './components/main-container/dictionary-management/religion/religion.component';
import { ReligionFormComponent } from './components/main-container/dictionary-management/religion/religion-form/religion-form.component';
import { PopulationComponent } from './components/main-container/dictionary-management/population/population.component';
import { PopulationFormComponent } from './components/main-container/dictionary-management/population/population-form/population-form.component';
import { ProjectComponent } from './components/main-container/site/project/project.component';
import { ProjectFormComponent } from './components/main-container/site/project/project-form/project-form.component';
import { EthicsCommitteeComponent } from './components/main-container/site/ethics-committee/ethics-committee.component';
import { EthicsCommitteeFormComponent } from './components/main-container/site/ethics-committee/ethics-committee-form/ethics-committee-form.component';
import { AuthGuard } from './auth.guard';
import { DiseaseFormComponent } from './components/main-container/dictionary-management/disease/disease-form/disease-form.component';
import { DiseaseComponent } from './components/main-container/dictionary-management/disease/disease.component';
import { OrganSiteComponent } from './components/main-container/dictionary-management/organ-site/organ-site.component';
import { OrganSiteFormComponent } from './components/main-container/dictionary-management/organ-site/organ-site-form/organ-site-form.component';
import { GenericComponent } from './components/main-container/dictionary-management/generic/generic.component';
import { GenericFormComponent } from './components/main-container/dictionary-management/generic/generic-form/generic-form.component';
import { DrugCategoryComponent } from './components/main-container/dictionary-management/drug-category/drug-category.component';
import { DrugCategoryFormComponent } from './components/main-container/dictionary-management/drug-category/drug-category-form/drug-category-form.component';
import { ApprovedDrugsComponent } from './components/main-container/dictionary-management/approved-drugs/approved-drugs.component';
import { DrugBrandComponent } from './components/main-container/dictionary-management/drug-brand/drug-brand.component';
import { DrugBrandFormComponent } from './components/main-container/dictionary-management/drug-brand/drug-brand-form/drug-brand-form.component';
import { TempBrandGenericComponent } from './components/main-container/dictionary-management/temp-brand-generic/temp-brand-generic.component';
import { TempBrandGenericFormComponent } from './components/main-container/dictionary-management/temp-brand-generic/temp-brand-generic-form/temp-brand-generic-form.component';
import { ParticipantDataFilterComponent } from './components/main-container/participant-data-filter/participant-data-filter.component';
import { BulkSiteAssignComponent } from './components/main-container/bulk-site-assign/bulk-site-assign.component';
import { ManageHtrComponent } from './components/main-container/manage-htr/manage-htr.component';
import { HtrFormComponent } from './components/main-container/manage-htr/htr-form/htr-form.component';
import { HtrImportComponent } from './components/main-container/manage-htr/htr-import/htr-import.component';
import { ParticipantComponent } from './components/main-container/participant/participant.component';
import { SiteComponent } from './components/main-container/site/site.component';
import { SiteFormComponent } from './components/main-container/site/site-form/site-form.component';
import { DiagnosticLabComponent } from './components/main-container/site/diagnostic-lab/diagnostic-lab.component';
import { DiagnosticLabFormComponent } from './components/main-container/site/diagnostic-lab/diagnostic-lab-form/diagnostic-lab-form.component';
import { EditParticipantComponent } from './components/main-container/participant/edit-participant/edit-participant.component';
import { InformationComponent } from './components/main-container/participant/edit-participant/information/information.component';
import { ParticipantDashboardComponent } from './components/main-container/participant/edit-participant/participant-dashboard/participant-dashboard.component';
import { SiteDashboardComponent } from './components/main-container/site/site-dashboard/site-dashboard.component';
import { SiteDayWiseFormComponent } from './components/main-container/site/site-day-wise-form/site-day-wise-form.component';
import { RecontactComponent } from './components/main-container/recontact-management/recontact/recontact.component';
import { PopulationValidationComponent } from './components/main-container/reclassification/population-validation/population-validation.component';
import { RoleFormComponent } from './components/main-container/role/role-form/role-form.component';
import { ParticipantDocumentsComponent } from './components/main-container/participant-documents/participant-documents.component';
import { DocumentImportComponent } from './components/main-container/participant-documents/document-import/document-import.component';
import { EditDocumentComponent } from './components/main-container/participant-documents/edit-document/edit-document.component';
import { LabDataComponent } from './components/main-container/lab-data/lab-data.component';
import { LabDataImportComponent } from './components/main-container/lab-data/lab-data-import/lab-data-import.component';
import { LabDataViewComponent } from './components/main-container/lab-data/lab-data-view/lab-data-view.component';
import { DiseaseValidationComponent } from './components/main-container/reclassification/disease-validation/disease-validation.component';
import { MatrixComponent } from './components/main-container/site/matrix/matrix.component';
import { MatrixFormComponent } from './components/main-container/site/matrix/matrix-form/matrix-form.component';
import { BioBankSequencingComponent } from './components/main-container/bio-bank-sequencing/bio-bank-sequencing.component';
import { CampDataVerificationComponent } from './components/main-container/camp-data-verification/camp-data-verification.component';
import { SurgeryComponent } from './components/main-container/dictionary-management/surgery/surgery.component';
import { SurgeryFormComponent } from './components/main-container/dictionary-management/surgery/surgery-form/surgery-form.component';
import { OccupationsComponent } from './components/main-container/dictionary-management/other/occupations/occupations.component';
import { OccupationsFormComponent } from './components/main-container/dictionary-management/other/occupations/occupations-form/occupations-form.component';
import { UniqueIdentityComponent } from './components/main-container/dictionary-management/unique-identity/unique-identity.component';
import { UniqueIdentityFormComponent } from './components/main-container/dictionary-management/unique-identity/unique-identity-form/unique-identity-form.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    pathMatch: 'full',
    data: { routing: 'dashboard', action: 'view' },
  },
  {
    path: 'manage-user',
    children: [
      {
        path: '',
        component: ManageUsersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'manage-user', action: 'view' },
      },
      {
        path: 'add',
        component: AddUserComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'manage-user', action: 'create' },
      },
      {
        path: 'edit/:id',
        component: EditUserComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'manage-user', action: 'edit' },
      },
      {
        path: 'view/:id',
        component: EditUserComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'manage-user', action: 'view' },
      },
    ],
  },

  {
    path: 'manage-role',
    children: [
      {
        path: '',
        component: RoleComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'manage-role', action: 'view' },
      },
      {
        path: "add",
        component: RoleFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: "manage-role", action: "create" },
      },
      {
        path: "edit/:id",
        component: RoleFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: "manage-role", action: "edit" },
      },
    ],
  },

  //******** Dictionary Routes **********
  {
    path: 'dictionary-management',
    children: [
      {
        path: 'country',
        children: [
          {
            path: '',
            component: CountryComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'country', action: 'view' },
          },
          {
            path: 'add',
            component: CountryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'country', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: CountryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'country', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: CountryComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'country', action: 'view' },
              },
              {
                path: ':id',
                component: CountryFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'country', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'state',
        children: [
          {
            path: '',
            component: StateComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'state', action: 'view' },
          },
          {
            path: 'add',
            component: StateFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'state', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: StateFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'state', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: StateComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'state', action: 'view' },
              },
              {
                path: ':id',
                component: StateFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'state', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'city',
        children: [
          {
            path: '',
            component: CityComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'city', action: 'view' },
          },
          {
            path: 'add',
            component: CityFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'city', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: CityFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'city', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: CityComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'city', action: 'view' },

              },
              {
                path: ':id',
                component: CityFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'city', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'pincode',
        children: [
          {
            path: '',
            component: PincodeComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'pincode', action: 'view' },
          },
          {
            path: 'add',
            component: PincodeFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'pincode', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: PincodeFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'pincode', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: PincodeComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'pincode', action: 'view' },
              },
              {
                path: ':id',
                component: PincodeFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'pincode', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'language',
        children: [
          {
            path: '',
            component: LanguageComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'language', action: 'view' },
          },
          {
            path: 'add',
            component: LanguageFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'language', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: LanguageFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'language', action: 'edit' },
          },
        ],
      },
      {
        path: 'organ-site',
        children: [
          {
            path: '',
            component: OrganSiteComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'organ-site', action: 'view' },
          },
          {
            path: 'add',
            component: OrganSiteFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'organ-site', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: OrganSiteFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'organ-site', action: 'edit' },
          },
        ],
      },
      {
        path: 'disease',
        children: [
          {
            path: '',
            component: DiseaseComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'disease', action: 'view' },
          },
          {
            path: 'add',
            component: DiseaseFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'disease', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: DiseaseFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'disease', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: DiseaseComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'disease', action: 'view' },
              },
              {
                path: ':id',
                component: DiseaseFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'disease', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'religion',
        children: [
          {
            path: '',
            component: ReligionComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'religion', action: 'view' },
          },
          {
            path: 'add',
            component: ReligionFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'religion', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: ReligionFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'religion', action: 'edit' },
          },
        ],
      },
      {
        path: 'sub-population',
        children: [
          {
            path: '',
            component: PopulationComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'sub-population', action: 'view' },
          },
          {
            path: 'add',
            component: PopulationFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'sub-population', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: PopulationFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'sub-population', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: PopulationComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'sub-population', action: 'view' },
              },

              {
                path: ':id',
                component: PopulationFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'sub-population', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'drug-generic',
        children: [
          {
            path: '',
            component: GenericComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'generic', action: 'view' },
          },
          {
            path: 'add',
            component: GenericFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'generic', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: GenericFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'generic', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: GenericComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'generic', action: 'view' },
              },

              {
                path: ':id',
                component: GenericFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'generic', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'drug-category',
        children: [
          {
            path: '',
            component: DrugCategoryComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-category', action: 'view' },
          },
          {
            path: 'add',
            component: DrugCategoryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-category', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: DrugCategoryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-category', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: DrugCategoryComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'drug-category', action: 'view' },
              },

              {
                path: ':id',
                component: DrugCategoryFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'drug-category', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'approved-drugs',
        children: [
          {
            path: '',
            component: ApprovedDrugsComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'approved-drugs', action: 'view' },
          },
        ],
      },
      {
        path: 'drug-brand',
        children: [
          {
            path: '',
            component: DrugBrandComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-brand', action: 'view' },
          },
          {
            path: 'add',
            component: DrugBrandFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-brand', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: DrugBrandFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'drug-brand', action: 'edit' },
          },
        ],
      },
      {
        path: 'temp-brand-generic/curation-pending',
        children: [
          {
            path: '',
            component: TempBrandGenericComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'temp-brand-generic', action: 'view' },
          },
          {
            path: ':id',
            component: TempBrandGenericFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'temp-brand-generic', action: 'edit' },
          },
        ],
      },
      {
        path: 'surgery',
        children: [
          {
            path: '',
            component: SurgeryComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'surgery', action: 'view' },
          },
          {
            path: 'add',
            component: SurgeryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'surgery', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: SurgeryFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'surgery', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: SurgeryComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'surgery', action: 'view' },
              },

              {
                path: ':id',
                component: SurgeryFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'surgery', action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'occupations',
        children: [
          {
            path: '',
            component: OccupationsComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'occupations', action: 'view' },
          },
          {
            path: 'add',
            component: OccupationsFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'occupations', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: OccupationsFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'occupations', action: 'edit' },
          },
          {
            path: 'curation-pending',
            children: [
              {
                path: '',
                component: OccupationsComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'occupations', action: 'view' },
              },
              {
                path: ':id',
                component: OccupationsFormComponent,
                canActivate: [AuthGuard, RoleGuard],
                data: { routing: 'occupations', action: 'edit' },
              },
            ],
          }
        ]
      },
      {
        path: 'unique-identity',
        children: [
          {
            path: '',
            component: UniqueIdentityComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'unique-identity', action: 'view' },
          },
          {
            path: 'add',
            component: UniqueIdentityFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'unique-identity', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: UniqueIdentityFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'unique-identity', action: 'edit' },
          },
        ],
      },
    ],
  },
  {
    path: 'participant',
    children: [
      {
        path: '',
        component: ParticipantComponent,
        canActivate: [AuthGuard, RoleGuard],
        pathMatch: 'full',
        data: { routing: 'participant', action: 'view' },
      },
      {
        path: 'edit/:id',
        component: EditParticipantComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'participant', action: 'view' },
      }
    ]
  },
  {
    path: 'internal/participant',
    children: [
      {
        path: "",
        component: ParticipantComponent,
        canActivate: [RoleGuard],
        data: { routing: "participant", action: "view" },
      },
      {
        path: "edit/:id",
        component: EditParticipantComponent,
        canActivate: [RoleGuard],
        data: { routing: "participant", action: "edit" },
      },
    ]
  },
  {
    path: 'participant-data-filtration',
    component: ParticipantDataFilterComponent,
    canActivate: [AuthGuard, RoleGuard],
    pathMatch: 'full',
    data: { routing: 'participant-data-filtration', action: 'view' },
  },
  {
    path: 'bulk-site-assign',
    component: BulkSiteAssignComponent,
    canActivate: [AuthGuard, RoleGuard],
    pathMatch: 'full',
    data: { routing: 'bulk-site-assign', action: 'view' },
  },
  {
    path: 'htr',
    children: [
      {
        path: '',
        component: ManageHtrComponent,
        canActivate: [AuthGuard, RoleGuard],
        pathMatch: 'full',
        data: { routing: 'htr', action: 'view' },
      },
      {
        path: 'upload',
        component: HtrImportComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'htr', action: 'create' },
      },
      {
        path: 'add',
        component: HtrFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'htr', action: 'create' },
      },
      {
        path: 'edit/:id',
        component: HtrFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'htr', action: 'view' },
      }
    ]
  },
  {
    path: 'lab-data',
    children: [
      {
        path: '',
        component: LabDataComponent,
        canActivate: [AuthGuard, RoleGuard],
        pathMatch: 'full',
        data: { routing: 'lab-data', action: 'view' },
      },
      {
        path: 'upload',
        component: LabDataImportComponent,
        canActivate: [AuthGuard],
        data: { routing: 'lab-data', action: 'create' },
      },
      {
        path: 'view/:id',
        component: LabDataViewComponent,
        canActivate: [AuthGuard],
        data: { routing: 'lab-data', action: 'view' },
      }
    ]
  },

  //******** Site Routes **********
  {
    path: 'site',
    children: [
      {
        path: '',
        component: SiteComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'site', action: 'view' },
      },
      {
        path: 'add',
        component: SiteFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'site', action: 'create' },
      },
      {
        path: 'edit/:id',
        component: SiteFormComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'site', action: 'edit' },
      },
      {
        path: "dashboard/:id",
        component: SiteDashboardComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: "site", action: "view" },
      },
      {
        path: 'day-wise-form',
        children: [
          {
            path: '',
            component: SiteDayWiseFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'create' },
          },
          {
            path: ':site_id',
            component: SiteDayWiseFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'edit' },
          },
          {
            path: ":site_id/:id",
            component: SiteDayWiseFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: "site", action: "edit" },
          },
        ],
      },
      {
        path: 'matrix',
        children: [
          {
            path: '',
            component: MatrixComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'view' },
          },
          {
            path: "dashboard/:id",
            component: MatrixFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: "site", action: "view" },
          },
        ],
      },
      {
        path: 'project',
        children: [
          {
            path: '',
            component: ProjectComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'view' },
          },
          {
            path: 'add',
            component: ProjectFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: ProjectFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'edit' },
          },
        ],
      },
      {
        path: 'ethics-committee',
        children: [
          {
            path: '',
            component: EthicsCommitteeComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'view' },
          },
          {
            path: 'add',
            component: EthicsCommitteeFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: EthicsCommitteeFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'edit' },
          },
        ],
      },
      {
        path: 'camp-data-verification',
        children: [
          {
            path: '',
            component: CampDataVerificationComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'view' },
          },
        ],
      },
      {
        path: 'diagnostic-lab',
        children: [
          {
            path: '',
            component: DiagnosticLabComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'view' },
          },
          {
            path: 'add',
            component: DiagnosticLabFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'create' },
          },
          {
            path: 'edit/:id',
            component: DiagnosticLabFormComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: { routing: 'site', action: 'edit' },
          }
        ]
      },
    ],
  },
  {
    path: "recontact",
    children: [
      {
        path: '',
        component: RecontactComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'recontact', action: 'view' },
      },
    ]
  },
  {
    path: "participant-data-validation",
    children: [
      {
        path: 'population-validation',
        component: PopulationValidationComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'participant-data-validation', action: 'view' },
      },
      {
        path: 'disease-validation',
        component: DiseaseValidationComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'participant-data-validation', action: 'view' },
      },
    ]
  },
  {
    path: "bio-bank-sequencing",
    children: [
      {
        path: "",
        component: BioBankSequencingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: "participant", action: "view" },
      },
      {
        path: ":year/:questionKey",
        component: BioBankSequencingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: "participant", action: "view" },
      },
    ],
  },
  {
    path: 'participant-documents',
    children: [
      {
        path: '',
        component: ParticipantDocumentsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'participant-documents', action: 'view' },
      },
      {
        path: 'edit/:id',
        component: EditDocumentComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { routing: 'participant-documents', action: 'edit' },
      },
      {
        path: 'import',
        component: DocumentImportComponent,
        canActivate: [RoleGuard, RoleGuard],
        data: { routing: 'participant-documents', action: 'view' },
      },
    ],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
  },
  {
    path: "**",
    canActivate: [AuthGuard],
    component: NotFoundComponent,
  },

];
const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
