import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';

// table code start 
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
// table code end

import { FormControl, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { RecontactService } from 'src/app/service/recontact.service';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';


@Component({
  selector: 'app-recontact',
  templateUrl: './recontact.component.html',
  styleUrls: ['./recontact.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RecontactComponent implements OnInit {
  public selectedValue = {};
  public dataSources = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  public expandedElement: PeriodicElement | undefined;
  public columnsToDisplay = ['participant_id', 'new_pid', 'new_recollection_id', 'site_code', 'sample_collection_date', 'age', 'gender'];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public tableData: any = {}
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public recontactFlags: any = [
    { label: "Pending", value: "Pending" },
    { label: "Call Unanswered", value: "Call unanswered" },
    { label: "Not Provided", value: "Not provided" },
    { label: "Common Number of Lab Tech", value: "Common Number of Lab Tech" },
    { label: "Wrong Number", value: "Wrong number" },
    { label: "Invalid Number", value: "Invalid number" },
    { label: "DND Activated", value: "DND Activated" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Disconnected", value: "Disconnected" },
  ];
  public recontact_status_flag: any = [
    { label: "Call Unanswered", value: "Call unanswered" },
    { label: "Not Provided", value: "Not provided" },
    { label: "Common Number of Lab Tech", value: "Common Number of Lab Tech" },
    { label: "Wrong Number", value: "Wrong number" },
    { label: "Invalid Number", value: "Invalid number" },
    { label: "DND Activated", value: "DND Activated" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Disconnected", value: "Disconnected" },
  ];
  public selectedFilter: any = "Pending";
  public recontactFilters: string = "Pending";
  public loading: boolean = true;
  public isApproved: number = 1;
  public totalRecord: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public currentMenuPermission: any;
  public matNoDataRowTitle: string = 'Loading Data...';

  constructor(
    private commonService: CommonService,
    private recontactService: RecontactService,
    private menuService: MenuService,
  ) { }
  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.listAllRecontact();
  }

  /**
   * Get filtered values
   * @param event
   */
  changeRecontactFilter(event: any) {
    if (event !== undefined) {
      this.recontactFilters = event.value;
      this.listAllRecontact();
    }
  }

  /**
   * Get filtered values
   * @param event
   */
  clearRecontactFilter() {
    this.recontactFilters = "Pending";
    this.selectedFilter = "Pending";
    this.listAllRecontact();
  }

  listAllRecontact() {
    this.matNoDataRowTitle = 'Loading Data...';
    this.loading = true;
    this.recontactService.listRecontacts(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      this.recontactFilters
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.dataSources = new MatTableDataSource(result.data.list.data);
        this.totalRecord = result.data.list.data.length;
        this.dataSources.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = result.data.list.total;
        });
        if (this.dataSources != undefined && this.dataSources.data.length == 0) {
          this.matNoDataRowTitle = 'No Record Found';
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }
  clickEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = true;
      }
    })
  }
  cancelEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = false;
      }
    })
  }
  saveEvent(event: any) {
    if (event.recontact_status == '' || event.recontact_status == null) {
      this.commonService.showErrorToast('Please select recontact status!!')
      return;
    }
    if (event.participant_id !== "") {
      this.loading = true;
      let params: FormData = new FormData();
      params.append("participant_id", event.participant_id);
      params.append("recontact_status", event.recontact_status);
      console.log(event.recontact_remark);
      if (!this.commonService.isNullOrUndefined(event.recontact_remark)) {
        params.append("recontact_remark", event.recontact_remark);
      }
      this.recontactService.updateRecontactStatus(params).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            this.commonService.showSuccessToast("Recontact has been updated successfully");
            this.dataSources.data.find(res => {
              if (res.participant_id == event.participant_id) {
                res.editing = false;
              }
            })
            this.listAllRecontact();
          } else {
            this.commonService.showErrorToast("Please enter correct values");
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
    } else {
      this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
    }

  }
  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.listAllRecontact();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.listAllRecontact();
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.listAllRecontact();
  }
}

// table extend code start
export interface PeriodicElement {
  editing: boolean;
  participant_id: string;
  new_pid: string;
  new_recollection_id: string;
  site_code: string;
  sample_collection_date: string;
  age: number;
  gender: string,
  first_name: string;
  last_name: string;
  postal_address: string;
  phone_no: string;
  alternate_phone_no: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
