import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2'
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

export interface AllergyTable {
  allergy_type: string
  allergy_description: string
  allergic_reaction: string
  action: any
}

let ELEMENT_DATA: AllergyTable[] = [];

@Component({
  selector: 'app-allergies-drug-reactions',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss']
})
export class AllergiesComponent implements OnInit {

  public PID: any;
  public allergyDetails: any;
  public allergyForm!: FormGroup;
  public allergyDetailForm!: FormGroup;
  public allergyArray!: FormArray;
  public allergyFormStatus: boolean = false;
  public allergyUpdate: boolean = false;
  public allergyAdd: boolean = false;
  public allergyUpdateIndex: any;
  public allergyTypes: any;
  public loading: boolean = true;

  togglepopup: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  displayedColumns: string[] = ['allergy_type', 'allergy_description', 'allergic_reaction', 'action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  ngOnInit(): void {

    let allergyTypes = JSON.parse(localStorage.getItem('constants') as string);
    this.allergyTypes = allergyTypes['ALLERGY_TYPES'];

    this.allergyForm = new FormGroup({});
    this.allergyForm.setControl('option', new FormControl(null, [Validators.required]));
    this.allergyForm.setControl('details', new FormArray([]));
    this.allergyArray = <FormArray>this.allergyForm.controls["details"];

    this.allergyDetailForm = this.formBuilder.group({
      allergy_type: [null, []],
      allergy_description: [null, [Validators.required]],
      allergic_reaction: [null, []]
    });
    this.getAllergy();
    console.log("ngonit", this.dataSource.filteredData);
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to get Surgery and set Value
   */
  getAllergy() {
    this.loading = true;
    this.participantService.getAllergy(this.PID).subscribe({
      next: (result: any) => {
        this.allergyDetails = result.data;
        if (this.allergyDetails.length === 0) {
          this.loading = false;
          return;
        }
        this.allergyForm.controls['option'].setValue(this.allergyDetails?.option);
        this.dataSource = new MatTableDataSource(result.data.details);
        this.allergyDetails.details.forEach((allergy: any) => {
          this.allergyArray.push(
            new FormGroup({
              allergy_type: new FormControl(allergy.allergy_type),
              allergy_description: new FormControl(allergy.allergy_description),
              allergic_reaction: new FormControl(allergy.allergic_reaction),
            })
          );
        });
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to add Surgery and set value
   */
  addAllergy() {
    if (this.allergyDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.allergyDetailForm);
      return;
    }
    const addForm = this.allergyForm.get('details') as FormArray;
    let newFormGroup = new FormGroup({
      allergy_type: new FormControl(this.allergyDetailForm.controls['allergy_type']?.value !== "" ? this.allergyDetailForm.controls['allergy_type']?.value : null, []),
      allergy_description: new FormControl(this.allergyDetailForm.controls['allergy_description']?.value !== "" ? this.allergyDetailForm.controls['allergy_description']?.value : null, []),
      allergic_reaction: new FormControl(this.allergyDetailForm.controls['allergic_reaction']?.value !== "" ? this.allergyDetailForm.controls['allergic_reaction']?.value : null, []),
    });
    addForm.push(newFormGroup);
    // console.log(this.allergyDetailForm.value, addForm, this.allergyForm.controls['details']?.value);
    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.allergyAdd = true;
    this.allergyUpdate = false;
    this.allergyDetailForm.reset();
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to update Medication update form value
   */
  updateAllergy() {
    if (this.allergyDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.allergyDetailForm);
      return;
    }
    const updateForm = (<FormArray>this.allergyForm.get("details")).at(this.allergyUpdateIndex);
    updateForm.patchValue({
      allergy_type: this.allergyDetailForm.controls['allergy_type']?.value,
      allergy_description: this.allergyDetailForm.controls['allergy_description']?.value,
      allergic_reaction: this.allergyDetailForm.controls['allergic_reaction']?.value,
    });

    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.allergyAdd = false;
    this.allergyDetailForm.reset();

  }

  /**
   * @author Pramod Pramod Kushwaha
   * This funtion is used to get updated value based on index
   * @param id 
   */
  editAllergy(id: any) {
    this.allergyUpdateIndex = id;
    this.allergyUpdate = true;
    this.togglepopup = !this.togglepopup;
    const allergyType = (<FormArray>this.allergyForm.get("details")).at(id).get('allergy_type')?.value;
    const allergyDescription = (<FormArray>this.allergyForm.get("details")).at(id).get('allergy_description')?.value;
    const allergicReaction = (<FormArray>this.allergyForm.get("details")).at(id).get('allergic_reaction')?.value;
    // console.log(allergyType, allergyDescription, allergicReaction);
    this.allergyDetailForm.controls['allergy_type'].setValue(allergyType);
    this.allergyDetailForm.controls['allergy_description'].setValue(allergyDescription);
    this.allergyDetailForm.controls['allergic_reaction'].setValue(allergicReaction);
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function used to close and update value 
  */
  closePopup() {
    this.allergyAdd = false;
    this.allergyUpdate = false;
    this.allergyUpdateIndex = null;
    this.togglepopup = !this.togglepopup;
    this.allergyDetailForm.reset();
  }

  // allergyInfoOption: string[] = ['Drug', 'Food', 'Environmental'];

  /**
    * @author Pramod Pramod Kushwaha
    * This function is used to delete element
    * @param option 
    */
  allergyDelete(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Allergy?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // this.allergyUpdate = true;
        (<FormArray>this.allergyForm.get("details"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.allergyForm.controls['details'].value);
        this.allergySubmit();
      }
      console.log(result);
    });
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form edit 
  */
  clickOnEdit() {
    this.allergyFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close 
  */
  clickOnCancel() {
    this.allergyFormStatus = false;
    this.allergyAdd = false;
    this.allergyUpdate = false;
    this.allergyUpdateIndex = null;
    this.allergyDetailForm.reset();
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to Open Surgery and hide Update button
  */
  openAllergy() {
    // this.allergyAdd = true;
    // this.allergyUpdate = false;
    this.togglepopup = !this.togglepopup;
  }

  /**
 * @author Pramod Pramod Kushwaha
 * This function is used to submit form to server side
 */
  allergySubmit() {
    console.log(this.allergyForm.value);
    this.loading = true;
    this.participantService.updateAllergy(this.allergyForm.value, this.PID).subscribe({
      next: (result: any) => {
        console.log(result);
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.allergyForm.setControl('details', new FormArray([]));
          this.allergyArray = <FormArray>this.allergyForm.controls["details"];
          this.getAllergy();
          this.allergyAdd = false;
          this.allergyUpdate = false;
          this.allergyUpdateIndex = null;
          this.allergyDetailForm.reset();
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to reset Form 
   * @param option 
   */
  resetForm(option: string) {
    console.log(option);
    this.allergyForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details'].value);
    if (option == 'yes') {
      this.allergyAdd = true;
      this.togglepopup = !this.togglepopup;
    } else {
      this.allergyUpdate = true;
    }
    this.allergyUpdateIndex = null;
    this.allergyDetailForm.reset();
  }
}

