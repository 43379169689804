import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-disease-cancer-questions',
  templateUrl: './disease-cancer-questions.component.html',
  styleUrls: ['./disease-cancer-questions.component.scss']
})
export class DiseaseCancerQuestionsComponent implements OnInit {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() diseaseUpdateIndex: any;
  @Input() diseaseDetails: any = [];

  public cancerDetailForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    let CANCER: any = [];
    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      CANCER = this.diseaseDetails['details'][this.diseaseUpdateIndex]['CANCER'];
    }

    // Cancer Questions
    this.cancerDetailForm = this.formBuilder.group({
      secondary_site: new FormControl([null, undefined, ''].includes(CANCER?.secondary_site) ? null : CANCER?.secondary_site, []),
    });

    this.diseaseDetailForm.setControl('CANCER', this.cancerDetailForm);

  }

}
