<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
      <li>{{title}}</li>
      <li><a routerLink="{{cancelLink}}" title="Ethics Committee" class="only-link">Ethics Committee</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
    <mat-spinner *ngIf="loading"></mat-spinner>
      <div class="row justify-content-md-center">
      <div class="col-xl-8 col-md-12 col-sm-12">
        <form [formGroup]="ethicsCommitteeForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()" enctype="multipart/form-data">
          <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-6 col-md-6">
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'name')">
                                <mat-label><i>*</i> Ethics Committee (EC) Name</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        type="text"
                                        placeholder="Enter ethics committee (EC) name" 
                                        formControlName="name"     
                                        (focusout)="
                                        checkUniqueEthicsCommittee($event)"
                                    >
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['name'].invalid && (this.ethicsCommitteeForm.controls['name'].dirty || this.ethicsCommitteeForm.controls['name'].touched)">
                                    {{getEthicsCommitteeNameError()}}
                                </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'projectId')">
                                <mat-label><i>*</i> Project Name:</mat-label>
                                <div class="control-pad">
                                    <ng-select
                                        [items]="projectList"
                                        formControlName="projectId"
                                        bindLabel="name"
                                        bindValue="id"
                                        labelForId="project"
                                        placeholder="select project name"
                                    >
                                    </ng-select>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['projectId'].invalid && ( this.ethicsCommitteeForm.controls['projectId'].dirty || this.ethicsCommitteeForm.controls['projectId'].touched)">
                                        {{getProjectNameError()}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'cityId')">
                                <mat-label>City:</mat-label>
                                <div class="control-pad">
                                    <ng-select [items]="cityList" formControlName="cityId"
                                        bindLabel="name" bindValue="id" labelForId="city"
                                        placeholder="Select city"
                                        (change)="getStateByCity($event)"
                                        (search)="searchCities($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'stateId')">
                                <mat-label>State</mat-label>
                                <div class="control-pad">
                                    <ng-select [items]="stateList" formControlName="stateId"
                                        bindLabel="name" bindValue="id" labelForId="state"
                                        placeholder="Select state"
                                        (search)="searchCities($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="input-wrape calendar-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'originalApprovalDate')">
                                <mat-label>Original Approval Date </mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput 
                                        [matDatepicker]="pickerApproval" 
                                        placeholder="Select date" 
                                        (click)="pickerApproval.open()"
                                        formControlName="originalApprovalDate"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="pickerApproval">
                                        <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerApproval></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['originalApprovalDate']?.invalid && (this.ethicsCommitteeForm.controls['originalApprovalDate']?.dirty || this.ethicsCommitteeForm.controls['originalApprovalDate']?.touched)">
                                    {{getDateError('originalApprovalDate')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape calendar-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'annualExpiryDateOfPolicy')">
                                <mat-label>Renewal Date/Expiry Date </mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput 
                                        [matDatepicker]="pickerRenewal" 
                                        placeholder="Select renewal date/expiry date" 
                                        (click)="pickerRenewal.open()"
                                        formControlName="annualExpiryDateOfPolicy"
                                        [min]="annualMinDate"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="pickerRenewal">
                                        <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerRenewal></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['annualExpiryDateOfPolicy']?.invalid && (this.ethicsCommitteeForm.controls['annualExpiryDateOfPolicy']?.dirty || this.ethicsCommitteeForm.controls['annualExpiryDateOfPolicy']?.touched)">
                                    {{getDateError('annualExpiryDateOfPolicy')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape calendar-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'nextRenewalDateOfPolicy')">
                                <mat-label>Next Renewal Date of Insurance Policy </mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput
                                        [matDatepicker]="pickerInsurance"
                                        placeholder="Select next renewal date of insurance policy" 
                                        (click)="pickerInsurance.open()"
                                        formControlName="nextRenewalDateOfPolicy"
                                        [min]="nextRenewalMinDate"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="pickerInsurance">
                                        <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerInsurance></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['nextRenewalDateOfPolicy']?.invalid && (this.ethicsCommitteeForm.controls['nextRenewalDateOfPolicy']?.dirty || this.ethicsCommitteeForm.controls['nextRenewalDateOfPolicy']?.touched)">
                                    {{getDateError('nextRenewalDateOfPolicy')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'researchProtocolVersion')">
                                <mat-label><i>*</i> Research Protocol (RP) Version</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        placeholder="Enter research protocol (RP) version" 
                                        type="text"
                                        formControlName="researchProtocolVersion"
                                    >
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['researchProtocolVersion']?.invalid && (this.ethicsCommitteeForm.controls['researchProtocolVersion']?.dirty || this.ethicsCommitteeForm.controls['researchProtocolVersion']?.touched)">
                                    {{getRequiredError('researchProtocolVersion')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'informedConsentFormVersion')">
                                <mat-label><i>*</i> Informed Consent Form (ICF) Version</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        placeholder="Enter informed consent form (ICF) version" 
                                        type="text"
                                        formControlName="informedConsentFormVersion"
                                    >
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['informedConsentFormVersion']?.invalid && (this.ethicsCommitteeForm.controls['informedConsentFormVersion']?.dirty || this.ethicsCommitteeForm.controls['informedConsentFormVersion']?.touched)">
                                    {{getRequiredError('informedConsentFormVersion')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'assentFormVersion')">
                                <mat-label><i>*</i> Assent Form (AF) Version </mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        placeholder="Enter assent form (AF) version" 
                                        type="text"
                                        formControlName="assentFormVersion"
                                    >
                                </mat-form-field>
                                <mat-error *ngIf="this.ethicsCommitteeForm.controls['assentFormVersion']?.invalid && (this.ethicsCommitteeForm.controls['assentFormVersion']?.dirty || this.ethicsCommitteeForm.controls['assentFormVersion']?.touched)">
                                    {{getRequiredError('assentFormVersion')}}
                                </mat-error>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'patientHistoryFormVersion')">
                                <mat-label>Patient History Form (PHF) Version</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        placeholder="Enter patient history form (PHF) version" 
                                        type="text"
                                        formControlName="patientHistoryFormVersion"
                                    >
                                </mat-form-field>
                            </div>
                            <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'patientInformationFormVersion')">
                                <mat-label>Participant Information Sheet (PIS) Version </mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput #input 
                                        placeholder="Enter participant information sheet (PIS) version " 
                                        type="text"
                                        formControlName="patientInformationFormVersion"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-md-6">
                            <div class="upload-thumb-wrape full">
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'projectApprovalLetter')">
                                    <mat-label><i>*</i> Project Approval Letter</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="projectApprovalLetter"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'projectApprovalLetterFiles',
                                                    'projectApprovalLetterFile',
                                                    'projectApprovalLetter'
                                                )
                                            " 
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.project_approval_letter_attachment?.length > 0 && 
                                        projectApprovalLetterFiles?.length == 0 && 
                                        this.ethicsCommitteeForm.controls['projectApprovalLetter']?.valid
                                        " class="upload-filename"
                                        >
                                        
                                        <a href="{{ ethicsCommitteeDetails?.project_approval_letter_attachment_url }}"
                                        download="download"
                                        target="_blank"
                                        title="{{ ethicsCommitteeDetails?.project_approval_letter_attachment }}"
                                        >File</a>
                                    </span>
                                    <span *ngIf="projectApprovalLetterFiles.length > 0 && this.ethicsCommitteeForm.controls['projectApprovalLetter']?.valid" class="upload-filename">
                                        <a *ngFor="let file of projectApprovalLetterFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['projectApprovalLetter']?.invalid && (this.ethicsCommitteeForm.controls['projectApprovalLetter']?.dirty || this.ethicsCommitteeForm.controls['projectApprovalLetter']?.touched)">
                                        {{getPDFFileError('projectApprovalLetter')}}
                                    </mat-error> 
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'researchProtocolAttachment')">
                                    <mat-label><i>*</i> Research Protocol (RP) Attachment</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="researchProtocolAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event,
                                                    'researchProtocolFiles',
                                                    'researchProtocolFile',
                                                    'researchProtocolAttachment'
                                                )
                                            "
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.research_protocol_attachment?.length > 0 && 
                                            researchProtocolFiles?.length == 0 && 
                                            this.ethicsCommitteeForm.controls['researchProtocolAttachment']?.valid
                                            " class="upload-filename"
                                            >
                                            <a href="{{ ethicsCommitteeDetails?.research_protocol_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.research_protocol_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="researchProtocolFiles.length > 0 && this.ethicsCommitteeForm.controls['researchProtocolAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of researchProtocolFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['researchProtocolAttachment']?.invalid && (this.ethicsCommitteeForm.controls['researchProtocolAttachment']?.dirty || this.ethicsCommitteeForm.controls['researchProtocolAttachment']?.touched)">
                                        {{getPDFFileError('researchProtocolAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'informedConsentFormAttachment')">
                                    <mat-label><i>*</i> Informed Consent Form (ICF) Attachment</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="informedConsentFormAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event,  
                                                    'informedConsentFormFiles',
                                                    'informedConsentFormFile',
                                                    'informedConsentFormAttachment'
                                                )
                                            "
                                            multiple
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.informed_consent_form_attachment?.length > 0 && 
                                        informedConsentFormFiles?.length == 0 && 
                                            this.ethicsCommitteeForm.controls['informedConsentFormAttachment']?.valid
                                        " class="upload-filename"   
                                    >
                                        <a
                                            *ngFor="
                                            let file of ethicsCommitteeDetails?.informed_consent_form_attachment;
                                            let i = index
                                            "
                                            href="{{ file.url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ file.name }}"
                                        >
                                            File{{ i + 1 }}
                                        </a>
                                    </span>
                                    <span *ngIf="informedConsentFormFiles!.length > 0 && this.ethicsCommitteeForm.controls['informedConsentFormAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of informedConsentFormFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['informedConsentFormAttachment']?.invalid && (this.ethicsCommitteeForm.controls['informedConsentFormAttachment']?.dirty || this.ethicsCommitteeForm.controls['informedConsentFormAttachment']?.touched)">
                                        {{getPDFFileError('informedConsentFormAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'assentFormAttachment')">
                                    <mat-label>Assent Form (AF) Attachment</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="assentFormAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'assentFormFiles',
                                                    'assentFormFile',
                                                    'assentFormAttachment'
                                                )
                                            "
                                            multiple
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.assent_form_attachment?.length > 0 && 
                                        assentFormFiles?.length == 0 && 
                                        this.ethicsCommitteeForm.controls['assentFormAttachment']?.valid
                                        " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.assent_form_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.assent_form_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="assentFormFiles!.length > 0 && this.ethicsCommitteeForm.controls['assentFormAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of assentFormFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['assentFormAttachment']?.invalid && (this.ethicsCommitteeForm.controls['assentFormAttachment']?.dirty || this.ethicsCommitteeForm.controls['assentFormAttachment']?.touched)">
                                        {{getPDFFileError('assentFormAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'patientHistoryFormAttachment')">
                                    <mat-label>Patient History Form (PHF) Attachment</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="patientHistoryFormAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'patientHistoryFormFiles',
                                                    'patientHistoryFormFile',
                                                    'patientHistoryFormAttachment'
                                                )
                                            "
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.patient_history_form_attachment?.length > 0 && 
                                                patientHistoryFormFiles?.length == 0 && 
                                                this.ethicsCommitteeForm.controls['patientHistoryFormAttachment']?.valid
                                            " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.patient_history_form_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.patient_history_form_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="patientHistoryFormFiles.length > 0 && this.ethicsCommitteeForm.controls['patientHistoryFormAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of patientHistoryFormFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['patientHistoryFormAttachment']?.invalid && (this.ethicsCommitteeForm.controls['patientHistoryFormAttachment']?.dirty || this.ethicsCommitteeForm.controls['patientHistoryFormAttachment']?.touched)">
                                        {{getPDFFileError('patientHistoryFormAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'patientInformationFormAttachment')">
                                    <mat-label>Participant Information Sheet (PIS) Attachment </mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="patientInformationFormAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'patientInformationFormFiles',
                                                    'patientInformationFormFile',
                                                    'patientInformationFormAttachment'
                                                )
                                            "
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.patient_information_form_attachment?.length > 0 && 
                                        patientInformationFormFiles?.length == 0 && 
                                            this.ethicsCommitteeForm.controls['patientInformationFormAttachment']?.valid
                                        " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.patient_information_form_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.patient_information_form_attachment }}"
                                            >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="patientInformationFormFiles.length > 0 && this.ethicsCommitteeForm.controls['patientInformationFormAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of patientInformationFormFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['patientInformationFormAttachment']?.invalid && (this.ethicsCommitteeForm.controls['patientInformationFormAttachment']?.dirty || this.ethicsCommitteeForm.controls['patientInformationFormAttachment']?.touched)">
                                        {{getPDFFileError('patientInformationFormAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'translationCertificateAttachment')">
                                    <mat-label>Translation Certificate</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="translationCertificateAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event,
                                                    'translationCertificateFiles',
                                                    'translationCertificateFile', 
                                                    'translationCertificateAttachment'
                                                )
                                            "
                                            multiple
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.translation_certificate_attachment?.length > 0 && 
                                        translationCertificateFiles?.length == 0 && 
                                        this.ethicsCommitteeForm.controls['translationCertificateAttachment']?.valid
                                        " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.translation_certificate_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.translation_certificate_attachment }}"
                                            >
                                            File
                                        </a>
                                    </span>
                                    <span *ngIf="translationCertificateFiles!.length > 0 && this.ethicsCommitteeForm.controls['translationCertificateAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of translationCertificateFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['translationCertificateAttachment']?.invalid && (this.ethicsCommitteeForm.controls['translationCertificateAttachment']?.dirty || this.ethicsCommitteeForm.controls['translationCertificateAttachment']?.touched)">
                                        {{getPDFFileError('translationCertificateAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'investigatorDeclarationAttachment')">
                                    <mat-label>Principal Investigator Declaration(PI)/Local Site Investigator Declaration(SI)</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="investigatorDeclarationAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event,   
                                                    'investigatorDeclarationFiles',
                                                    'investigatorDeclarationFile',
                                                    'investigatorDeclarationAttachment'
                                                )
                                            "
                                            multiple
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.investigator_declaration_attachment?.length > 0 && 
                                        investigatorDeclarationFiles?.length == 0 && 
                                                this.ethicsCommitteeForm.controls['investigatorDeclarationAttachment']?.valid
                                            " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.investigator_declaration_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.investigator_declaration_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="investigatorDeclarationFiles!.length > 0 && this.ethicsCommitteeForm.controls['investigatorDeclarationAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of investigatorDeclarationFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['investigatorDeclarationAttachment']?.invalid && (this.ethicsCommitteeForm.controls['investigatorDeclarationAttachment']?.dirty || this.ethicsCommitteeForm.controls['investigatorDeclarationAttachment']?.touched)">
                                        {{getPDFFileError('investigatorDeclarationAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'cvOfPISIAttachment')">
                                    <mat-label>CV of PI/SI</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="cvOfPISIAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'cvOfPISIFiles',
                                                    'cvOfPISIFile',
                                                    'cvOfPISIAttachment'
                                                )
                                            "
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.cv_of_pi_si_attachment?.length > 0 && 
                                        cvOfPISIFiles?.length == 0 && 
                                                this.ethicsCommitteeForm.controls['cvOfPISIAttachment']?.valid
                                            " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.cv_of_pi_si_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.cv_of_pi_si_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="cvOfPISIFiles.length > 0 && this.ethicsCommitteeForm.controls['cvOfPISIAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of cvOfPISIFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['cvOfPISIAttachment']?.invalid && (this.ethicsCommitteeForm.controls['cvOfPISIAttachment']?.dirty || this.ethicsCommitteeForm.controls['cvOfPISIAttachment']?.touched)">
                                        {{getPDFFileError('cvOfPISIAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'insurancePolicyAttachment')">
                                    <mat-label>Insurance Policy</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="insurancePolicyAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'insurancePolicyFiles',
                                                    'insurancePolicyFile',
                                                    'insurancePolicyAttachment'
                                                )
                                            " 
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.insurance_policy_attachment?.length > 0 && 
                                        insurancePolicyFiles?.length == 0 && 
                                                this.ethicsCommitteeForm.controls['insurancePolicyAttachment']?.valid
                                            " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.insurance_policy_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.insurance_policy_attachment }}"
                                        >
                                        File
                                        </a>
                                    </span>
                                    <span *ngIf="insurancePolicyFiles.length > 0 && this.ethicsCommitteeForm.controls['insurancePolicyAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of insurancePolicyFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['insurancePolicyAttachment']?.invalid && (this.ethicsCommitteeForm.controls['insurancePolicyAttachment']?.dirty || this.ethicsCommitteeForm.controls['insurancePolicyAttachment']?.touched)">
                                        {{getPDFFileError('insurancePolicyAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.ethicsCommitteeForm, 'annualProgressReportAttachment')">
                                    <mat-label><i>*</i> Annual Progress Report</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input 
                                            id="fileInput" 
                                            type="file" 
                                            class="ng-hide" 
                                            placeholder="upload"
                                            formControlName="annualProgressReportAttachment"
                                            (change)="
                                                selectPDFFiles(
                                                    $event, 
                                                    'annualProgressReportFiles',
                                                    'annualProgressReportFile',
                                                    'annualProgressReportAttachment'
                                                )
                                            "
                                            accept=".pdf"   
                                        >
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .pdf file only
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="ethicsCommitteeDetails?.annual_progress_report_attachment?.length > 0 && 
                                        annualProgressReportFiles?.length == 0 && 
                                            this.ethicsCommitteeForm.controls['annualProgressReportAttachment']?.valid
                                            " class="upload-filename"
                                    >
                                        <a href="{{ ethicsCommitteeDetails?.annual_progress_report_attachment_url }}"
                                            download="download"
                                            target="_blank"
                                            title="{{ ethicsCommitteeDetails?.annual_progress_report_attachment }}"
                                        >
                                        File
                                    </a>
                                    </span>
                                    <span *ngIf="annualProgressReportFiles.length > 0 && this.ethicsCommitteeForm.controls['annualProgressReportAttachment']?.valid" class="upload-filename">
                                        <a *ngFor="let file of annualProgressReportFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                            File{{i + 1}}
                                        </a>
                                    </span>
                                    <mat-error *ngIf="this.ethicsCommitteeForm.controls['annualProgressReportAttachment']?.invalid && (this.ethicsCommitteeForm.controls['annualProgressReportAttachment']?.dirty || this.ethicsCommitteeForm.controls['annualProgressReportAttachment']?.touched)">
                                        {{getPDFFileError('annualProgressReportAttachment')}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape">
                                    <mat-checkbox-groups class="checkbox-section">
                                        <mat-checkbox formControlName="isActive" class="example-margin">Is Active?</mat-checkbox>
                                    </mat-checkbox-groups>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}" title="Cancel">Cancel</button>
                    <button mat-raised-button class="btn" type="submit" color="secoundary" title="{{buttonText}}">{{buttonText}}</button>
                </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  