<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="progress-overlay"></div>
    <form [ngClass]="statusTableForm ? 'edit-mode' : 'view-mode'" id="diseaseForm" [formGroup]="cbdForm"
        autocomplete="off">
        <div class="container-fluid pi-wrape">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-wrape">
                        <div class="card-head">
                            <h4 class="left-text">Physiological Measurement</h4>
                        </div>
                        <div class="card-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-12 m-b-0">
                                        <div class="input-wrape cbd-form-wrape" [ngClass]="displayFieldCss('height')">
                                            <mat-label><i>*</i>Height:</mat-label>
                                            <div class="big-field">
                                                <mat-form-field appearance="outline">
                                                    <input matInput #input placeholder="Enter height" type="text"
                                                        formControlName="height" appDecimalvaliation autocomplete="off">
                                                </mat-form-field>
                                                <mat-error
                                                    *ngIf="(cbdForm.controls['height']?.invalid || cbdForm.controls['unitHeight'] ?.invalid) && cbdForm.controls['height'].errors && (cbdForm.controls['height'].touched || cbdForm.controls['height'].dirty )">
                                                    {{ heightMsg }}
                                                </mat-error>
                                            </div>
                                            <div class="small-field">
                                                <div class="control-pad">
                                                    <ng-select [searchable]="false" placeholder="Select UnitHeight"
                                                        formControlName="unitHeight">
                                                        <ng-option *ngFor="let height of unitListH"
                                                            [value]="height.measure">{{height.measure}}</ng-option>
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-wrape cbd-form-wrape" [ngClass]="displayFieldCss('weight')">
                                            <mat-label><i>*</i>Weight:</mat-label>
                                            <div class="big-field">
                                                <mat-form-field appearance="outline">
                                                    <input matInput #input placeholder="Enter weight" type="text"
                                                        formControlName="weight" appDecimalvaliation autocomplete="off">
                                                </mat-form-field>
                                                <mat-error
                                                    *ngIf="(cbdForm.controls['weight']?.invalid || cbdForm.controls['unitWeight'] ?.invalid) && cbdForm.controls['weight'].errors && (cbdForm.controls['weight'].touched || cbdForm.controls['weight'].dirty )">
                                                    {{ weightMsg }}
                                                </mat-error>
                                            </div>
                                            <div class="small-field">
                                                <div class="control-pad">
                                                    <ng-select [searchable]="false" placeholder="Select Unit Weight"
                                                        formControlName="unitWeight">
                                                        <ng-option *ngFor="let weight of unitListW"
                                                            value="{{ weight.measure }}">
                                                            {{ weight.measure }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-wrape cbd-form-wrape three-form"
                                            [ngClass]="displayFieldCss('blood_pressure_systolic','blood_pressure_diastolic')">
                                            <mat-label><i *ngIf="isRequiredDiseases.length > 0" >*</i>High BP and Low BP:</mat-label>
                                            <div class="one-third">
                                                <div class="same-field">
                                                    <mat-form-field appearance="outline">
                                                        <input matInput #input placeholder="Enter high BP"
                                                            formControlName="blood_pressure_systolic" type="text"
                                                            appDecimalvaliation autocomplete="off">
                                                    </mat-form-field>
                                                    <mat-error
                                                        *ngIf="cbdForm.controls['blood_pressure_diastolic']?.invalid  || cbdForm.controls['blood_pressure_systolic']?.invalid  || cbdForm.controls['unitBP']?.invalid ">
                                                        {{ validateMsg ? validateMsg : "High/Low BP values are required"
                                                        }}
                                                    </mat-error>
                                                </div>
                                                <div class="same-field">
                                                    <mat-form-field appearance="outline">
                                                        <input matInput #input placeholder="Enter low BP"
                                                            formControlName="blood_pressure_diastolic" type="text"
                                                            appDecimalvaliation autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="one-third small tooltip">
                                                <div class="same-field">
                                                    <div class="control-pad">
                                                        <ng-select [searchable]="false" placeholder="Select Unit BP"
                                                            formControlName="unitBP">
                                                            <ng-option *ngFor="let blood_pressure_unit of unitListBP"
                                                                value="{{ blood_pressure_unit.measure }}">
                                                                {{ blood_pressure_unit.measure }}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="custom-icon-tooltip yellow">
                                                        <i class="info-icon"></i>
                                                        <div class="info-section">
                                                            Enter BP value if available in MR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickEventTableForm()" *ngIf="disableTableForm" mat-raised-button class="btn"
                color="secoundary">Edit</button>
            <button type="button" (click)="cancelEventTableForm()" *ngIf="enableTableForm" mat-raised-button class="btn"
                color="warn">Cancel</button>
            <button type="button" (click)="submitCbdInfoForm()" *ngIf="enableTableForm"
                [disabled]="!cbdAdd && !cbdUpdate" mat-raised-button class="btn" color="secoundary">Save</button>
        </div>
    </form>
</div>