import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { ModuleService } from 'src/app/service/module.service';
import { RoleService } from 'src/app/service/role.service';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {

  public title: any;
  public roleID: any = 0;
  public roles = ['view', 'create', 'edit', 'delete', 'download', 'import', 'export'];
  public moduleArray: any = [];
  public moduleList: any;
  public showLoading: boolean = false;
  public roleForm!: FormGroup;
  public roleKeyList: any;
  public moduleError: boolean = true;
  public merror: boolean = false;
  public roleDetail: any;
  public buttonTitle: any;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private roleService: RoleService,
    private menuService: MenuService
  ) {
    this.roleID = this.route.snapshot.paramMap.get('id');
    if (this.roleID !== 0 && this.roleID !== null) {
      this.getRoleInfo();
      this.title = 'Edit Role';
      this.buttonTitle = 'Save';
    } else {
      this.title = 'Add Role';
      this.buttonTitle = 'Add';
      this.getModuleWithChild();
    }
  }

  ngOnInit(): void {
    this.roleForm = this.formBuilder.group({
      'roleName': ['', Validators.required],
      'roleKey': [null, Validators.required],
    });
    this.roleKeyList = AppConstants.getRoleKey;
  }

  /**
  * It will store role with its permission
  */
  onSubmit() {

    let flagError = false;
    if (this.roleForm.invalid) {
      this.commonService.validateAllFormFields(this.roleForm);
      flagError = true;
      this.commonService.showErrorToast('Please fill all the fields');
    }
    this.moduleError = true;
    this.checkModuleArray(this.moduleArray);
    if (this.moduleError === true) {
      this.merror = true;
      this.commonService.showErrorToast('Please select permission');
    } else {
      this.merror = false;
    }
    if (this.moduleError === true || flagError === true) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('role_name', this.roleForm.controls['roleName'].value);
    formData.append('role_key', this.roleForm.controls['roleKey'].value);
    formData.append('permission', JSON.stringify(this.moduleArray));
    if (this.roleID !== 0 && this.roleID !== null) {
      formData.append('id', this.roleID);
    }
    this.showLoading = true;
    this.roleService.store(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            if (this.roleID !== 0 && this.roleID !== null) {
              this.commonService.showSuccessToast('Role updated successfully');
            } else {
              this.commonService.showSuccessToast('Role added successfully');
            }
            this.router.navigate(['/manage-role']);
          }
          this.showLoading = false;
        },
        error: (err) => {
          // console.log('storeRole err :: ' , err);
          this.showLoading = false;
        }
      });
  }

  getRoleInfo() {
    let roleID = this.route.snapshot.paramMap.get('id');
    this.showLoading = true;
    this.roleService.getRow(roleID).subscribe({
      next: (result: any) => {
        this.showLoading = false;
        this.roleDetail = result.data;
        this.moduleArray = result.data.permission;
        this.roleForm.patchValue({
          roleName: this.roleDetail.role_name,
          roleKey: this.roleDetail.role_key,
        });

      },
      error: () => {
        this.showLoading = false;
      },
    });
  }

  /**
   * It will check Unique role
   * @param event
   */
  checkRole(event: any) {
    const role = event.target.value;
    // this.roleError = 'Role Name is required';
    this.roleService.checkRoleUnique(role, this.roleID)
      .subscribe({
        next: (result: any) => {
          if (result.status === 0) {
            this.roleForm.controls['roleName'].setErrors({ 'unique': result.message });
            // this.roleError = result.message;
          }
        },
        error: () => {
          // console.log('checkRole err :: ' , err);
        }
      });
  }

  /**
  * It will change Role
  * @param index
  * @param fieldType
  * @param moduleData
  * @param event
  * @param childIndex
  * @author Dhaval Bera
  */
  changeRole(index: number, fieldType: string, moduleData: any, event: any, parentList: any, childList: any) {

    switch (fieldType) {
      case 'create':
        childList.forEach((res: string | number) => {
          this.moduleArray[res].create = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].create = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });


        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].create = event.checked ? true : 0;
        break;
      case 'view':

        childList.forEach((res: string | number) => {
          this.moduleArray[res].delete = event.checked ? 0 : 0;
          this.moduleArray[res].create = event.checked ? 0 : 0;
          // this.moduleArray[res].edit = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : 0;
        });

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].delete = event.checked ? 0 : 0;
          this.moduleArray[res].create = event.checked ? 0 : 0;
          // this.moduleArray[res].edit = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });
        this.moduleArray[index].delete = event.checked ? 0 : 0;
        this.moduleArray[index].create = event.checked ? 0 : 0;
        this.moduleArray[index].edit = event.checked ? 0 : 0;
        this.moduleArray[index].view = event.checked ? true : 0;
        break;
      case 'edit':
        parentList.forEach((res: string | number) => {
          this.moduleArray[res].edit = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        childList.forEach((res: string | number) => {
          this.moduleArray[res].edit = event.checked ? true : 0;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].edit = event.checked ? true : 0;
        break;
      case 'delete':

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].delete = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        childList.forEach((res: string | number) => {
          this.moduleArray[res].delete = event.checked ? true : 0;
          this.moduleArray[res].view = event.checked ? true : true;
        });


        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].delete = event.checked ? true : 0;
        break;
      case 'download':

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].download = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        childList.forEach((res: string | number) => {
          this.moduleArray[res].download = event.checked ? true : 0;
          this.moduleArray[res].view = event.checked ? true : true;
        });


        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].download = event.checked ? true : 0;

        break;
      case 'import':

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].import = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        childList.forEach((res: string | number) => {
          this.moduleArray[res].import = event.checked ? true : 0;
          this.moduleArray[res].view = event.checked ? true : true;
        });


        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].import = event.checked ? true : 0;
        break;
      case 'export':

        parentList.forEach((res: string | number) => {
          this.moduleArray[res].export = event.checked ? true : true;
          this.moduleArray[res].view = event.checked ? true : true;
        });

        childList.forEach((res: string | number) => {
          this.moduleArray[res].export = event.checked ? true : 0;
          this.moduleArray[res].view = event.checked ? true : true;
        });


        this.moduleArray[index].view = event.checked ? true : true;
        this.moduleArray[index].export = event.checked ? true : 0;
        break;
    }
    this.checkModuleArray(this.moduleArray);
    // console.log('updated moduleArray => ', this.moduleArray);
  }
  /**
   * @author Dhaval Bera
   */
  checkModuleArray(moduleArr: any[]) {
    moduleArr.forEach(module => {
      if (module.create === true || module.edit === true || module.view === true || module.delete === true || module.download === true || module.import === true || module.export === true) {
        this.moduleError = false;
      }
    });
    return this.moduleError;
  }

  /**
   * get active module listing
   */
  getModuleWithChild() {
    this.showLoading = true;
    this.moduleService.getModuleList().subscribe({
      next: (result: any) => {
        this.showLoading = false;
        this.moduleList = result.data;
        this.moduleList.forEach((res: any) => {
          this.moduleArray.push({
            moduleId: res.id,
            displayName: res.name,
            levelNumber: res.level_number,
            isChild: res.is_child,
            parentList: res.parentList,
            childList: res.childList,
            view: 0,
            create: 0,
            edit: 0,
            delete: 0,
            download: 0,
            import: 0,
            export: 0,
          });
        });
      },
      error: (err) => {
        this.showLoading = false;
      }
    });
  }

}
