import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Sites } from 'src/app/interfaces/sites';
import { Disease } from 'src/app/interfaces/Disease';
import { DiseaseService } from 'src/app/service/disease.service';
import { PopulationService } from 'src/app/service/population.service';
import { SiteService } from 'src/app/service/site.service';
import { Populations } from 'src/app/interfaces/populations';
import { Data } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { ParticipantDataFilterService } from 'src/app/service/participant-data-filter.service';

@Component({
  selector: 'app-participant-data-filter',
  templateUrl: './participant-data-filter.component.html',
  styleUrls: ['./participant-data-filter.component.scss']
})
export class ParticipantDataFilterComponent implements OnInit {

  public participantDataFilterForm!: FormGroup;
  public siteList: Sites[] = [];
  public selectedSiteList: any = [];

  public diseaseList: Disease[] = [];
  public selectedDiseaseList: any = [];

  public exportModule = new FormControl('');
  // public exportModuleControl = new FormControl('');
  public selectedExportModuleList: any = [];

  public populationList: Populations[] = [];
  public selectedPopulationList: any = [];

  public filteredParticipants = null;
  public exportModuleList: any =
    [
      { label: 'Disease History', value: 'dh' },
      { label: 'Medication History', value: 'mh' },
      { label: 'Surgeries', value: 'sur' },
      { label: 'Allergies OR Drug Reactions', value: 'adr' },
      { label: 'Recreational Habits', value: 'rh' },
      { label: 'Physiological Measurement', value: 'pm' },
      { label: 'Medical Record', value: 'mrs' },
      { label: 'Family Medical History', value: 'fmh' },
      { label: 'Sample & Test Information', value: 'sti' },
      { label: 'Lab Data', value: 'lbd' },
      { label: 'HTR', value: 'htr' },      
      { label: 'Population Reclassification', value: 'pr' },
    ];

  constructor(
    private formBuilder: FormBuilder,
    private siteService: SiteService,
    private diseaseService: DiseaseService,
    private subPopulationService: PopulationService,
    private commonService: CommonService,
    private participantDataFilterService: ParticipantDataFilterService,
  ) { }

  ngOnInit(): void {

    this.participantDataFilterForm = this.formBuilder.group({
      site_id: [null, []],
      participantPopulations: [null, []],
      participantDiseases: [null, []],
      startDate: [null, []],
      endDate: [null, []],
      new_pids: [null, []],
      getCounts: ['count', []],
      export_modules: [null, []],

    });

    this.getSite();
    this.getDisease();
    this.getPopulations();
  }

  /**
   * Get Site
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  getSite() {
    this.siteService.getAllList().subscribe((result: any) => {
      this.siteList = result.data;
    });
  }

  /**
   * Get Site List
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  getDisease() {
    this.diseaseService.getParticipantDisease()
      .subscribe((result: any) => {
        this.diseaseList = result.data;
      });
  }

  /**
   * Get Site List
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  getPopulations() {
    this.subPopulationService.getParticipantPopulation()
      .subscribe((result: any) => {
        this.populationList = result.data;
      });
  }

  /**
   * It will search Participant
   * @param event
   * @author Jaydeep Patel
   */
  getParticipantData() {

    // console.log(this.participantDataFilterForm.value);
    // return;
    const formData: FormData = new FormData();

    if (this.participantDataFilterForm.controls['startDate'].value != '' && this.participantDataFilterForm.controls['startDate'].value !== null && this.participantDataFilterForm.controls['startDate'].value !== undefined) {
      formData.append(
        'collectionStartDate',
        this.commonService.transformDate(
          this.participantDataFilterForm.controls['startDate'].value
        ) as unknown as Blob
      );
    }

    if (this.participantDataFilterForm.controls['endDate'].value != '' && this.participantDataFilterForm.controls['endDate'].value !== null && this.participantDataFilterForm.controls['endDate'].value !== undefined) {
      formData.append(
        'collectionEndDate',
        this.commonService.transformDate(
          this.participantDataFilterForm.controls['endDate'].value
        ) as unknown as Blob
      );
    }

    if (this.participantDataFilterForm.controls['site_id'].value != '' && this.participantDataFilterForm.controls['site_id'].value !== null && this.participantDataFilterForm.controls['site_id'].value !== undefined) {

      formData.append("site_id", this.participantDataFilterForm.controls["site_id"].value);
    }

    if (this.participantDataFilterForm.controls['participantPopulations'].value != '' && this.participantDataFilterForm.controls['participantPopulations'].value !== null && this.participantDataFilterForm.controls['participantPopulations'].value !== undefined) {

      formData.append("population_ids", this.participantDataFilterForm.controls["participantPopulations"].value);
    }

    if (this.participantDataFilterForm.controls['participantDiseases'].value != '' && this.participantDataFilterForm.controls['participantDiseases'].value !== null && this.participantDataFilterForm.controls['participantDiseases'].value !== undefined) {
      formData.append("disease_ids", this.participantDataFilterForm.controls["participantDiseases"].value);
    }

    if (this.participantDataFilterForm.controls['new_pids'].value != '' && this.participantDataFilterForm.controls['new_pids'].value !== null && this.participantDataFilterForm.controls['new_pids'].value !== undefined) {
      formData.append('new_pids', this.participantDataFilterForm.controls['new_pids'].value);
    }

    if (this.participantDataFilterForm.controls['getCounts'].value != '' && this.participantDataFilterForm.controls['getCounts'].value !== null && this.participantDataFilterForm.controls['getCounts'].value !== undefined) {

      if (this.participantDataFilterForm.controls['getCounts'].value === 'count') {

        formData.append('exportType', 'count');

      } else if (this.participantDataFilterForm.controls['getCounts'].value === 'data_point_count') {

        formData.append('dataPointCount', 'true');

      } else {

        let selectedExportModules: any = ['pi'];

        if (this.participantDataFilterForm.controls['export_modules'].value != '' && this.participantDataFilterForm.controls['export_modules'].value !== null && this.participantDataFilterForm.controls['export_modules'].value !== undefined) {
          selectedExportModules.push(this.participantDataFilterForm.controls["export_modules"].value);
        }

        formData.append('export_modules', selectedExportModules);
      }

    }

    this.participantDataFilterService.filterParticipant(formData).subscribe((result: any) => {

      if (this.participantDataFilterForm.controls['getCounts'].value == 'count') {
        this.filteredParticipants = result.data.participantCount;
      } else {
        this.commonService.showSuccessToast(
          'We will notify you once the file is ready to download!'
        );
        // this.csvDownloadService.downloadFile(result.data.jsonData, result.data.fileName, result.data.headers);
        // this.toastr.success('We will notify you once the file is ready to download!', 'Success');
      }
      // return false;
    }, err => {
      // console.log('Listing err :: ', err);
      // this.showLoading = false;
      // this.toastr.danger('Something went wrong!', 'Oops');
      this.commonService.showErrorToast(
        'Something went wrong!'
      );
    }, () => {
      // console.log('Listing complete :: ');
      // this.showLoading = false;
    });
  }

}
