<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form [ngClass]="recreationalHabitsFormStatus ? 'edit-mode' : 'view-mode'" id="recreationalHabitsForm"
        [formGroup]="recreationalHabitsForm">
        <div class="container-fluid">
            <!-- PMH medical history 1 start -->
            <div class="col-lg-12">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Recreational Habits
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel-body>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-wrape">
                                    <mat-label>Do You Exercise?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="exercise">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Do You Chew Tobacco?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="chew_tobacco">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Do You Smoke?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="smoking">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Do You Consume Caffeine?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="caffeine">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Do You Consume Alcohol?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="alcohol">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                        <mat-radio-button value="prefer not to say">Prefer Not to Say</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape" [ngClass]="displayFieldCss('is_taking_drugs')">
                                    <mat-label><i *ngIf="isRequiredDiseases.length > 0">*</i>Do You Take Any
                                        Recreational Drugs?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="is_taking_drugs">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                        <mat-radio-button value="occasionally">Occasionally</mat-radio-button>
                                        <mat-radio-button value="prefer not to say">Prefer Not to Say</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="(recreationalHabitsForm.controls['is_taking_drugs']?.errors && (recreationalHabitsForm.controls['is_taking_drugs']?.pristine || recreationalHabitsForm.controls['is_taking_drugs']?.invalid)) || (recreationalHabitsForm.controls['is_taking_drugs']?.errors && (recreationalHabitsForm.controls['is_taking_drugs']?.pristine || recreationalHabitsForm.controls['is_taking_drugs']?.invalid))">
                                        This field is required
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <h6>Your Dietary Habits</h6>
                                <div class="input-wrape" [ngClass]="displayFieldCss('diet_type')">
                                    <mat-label><i>*</i>What Is Your Diet Type?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="diet_type">
                                        <mat-radio-button value="VEGETARIAN">Vegetarian</mat-radio-button>
                                        <mat-radio-button value="NON-VEGETARIAN">Non-vegetarian</mat-radio-button>
                                        <!-- <mat-radio-button value="OTHERS">Others</mat-radio-button> -->
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="(recreationalHabitsForm.controls['diet_type']?.errors && (recreationalHabitsForm.controls['diet_type']?.pristine || recreationalHabitsForm.controls['diet_type']?.invalid)) || (recreationalHabitsForm.controls['diet_type']?.errors && (recreationalHabitsForm.controls['diet_type']?.pristine || recreationalHabitsForm.controls['diet_type']?.invalid))">
                                        This field is required
                                    </mat-error>
                                </div>

                                <div class="input-wrape">
                                    <mat-label>Additional Diet Type Owing to Health Issues?</mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="additional_diet_types"
                                            placeholder="What is your diet type?" [multiple]="true" (change)="changeWhatisyourdiettype($event)">
                                            <ng-option *ngFor="let option of dietTypeList" [value]="option">{{ option | titlecase
                                            }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="input-wrape tree one heighed" *ngIf="whatisyourdiettypeOther === true">
                                    <mat-label>What is the additional diet type?</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <textarea matInput placeholder="What is the additional diet type?"
                                            formControlName="describe_additional_diet_type"></textarea>
                                    </mat-form-field>
                                    <i class="ruler"></i>
                                </div>
                                <h6>Literacy (Education)</h6>
                                <div class="input-wrape">
                                    <mat-label>Highest Level of Formal Education?</mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="highest_education" placeholder="Enter highest level of formal education">
                                            <ng-option *ngFor="let option of highestFormalEducationItems" [value]="option">{{ option | titlecase
                                            }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <h6>Occupation Details</h6>
                                <div class="input-wrape" [ngClass]="displayFieldCss('occupation_id')">
                                    <mat-label><i>*</i>Occupation:</mat-label>
                                    <div class="control-pad">
                                        <ng-select [searchable]="true" formControlName="occupation_id" [addTag]="addTagFn" placeholder="Enter occupation">
                                            <ng-option *ngFor="let option of occupationTypeItems" [value]="option?.id">{{ option?.name | titlecase
                                            }}</ng-option>
                                        </ng-select>
                                    </div>
                                    <mat-error
                                        *ngIf="(recreationalHabitsForm.controls['occupation_id']?.errors && (recreationalHabitsForm.controls['occupation_id']?.pristine || recreationalHabitsForm.controls['occupation_id']?.invalid)) || (recreationalHabitsForm.controls['occupation_id']?.errors && (recreationalHabitsForm.controls['occupation_id']?.pristine || recreationalHabitsForm.controls['occupation_id']?.invalid))">
                                        This field is required
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel-body>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickOnEdit()" *ngIf="!recreationalHabitsFormStatus" mat-raised-button
                class="btn" color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="recreationalHabitsFormStatus" mat-raised-button
                class="btn" color="warn">Cancel</button>
            <button type="button" (click)="recreationalHabitsSubmit()" *ngIf="recreationalHabitsFormStatus"
                mat-raised-button class="btn" color="secoundary">Save</button>
        </div>
    </form>
</div>