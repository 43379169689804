import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { ParticipantElement } from 'src/app/interfaces/Participant';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { ParticipantService } from 'src/app/service/participant.service';
import { RecontactService } from 'src/app/service/recontact.service';
import Swal from 'sweetalert2';

const ELEMENT_DATA: ParticipantElement[] = [];

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {

  public displayedColumns: string[] = [
    'id',
    'new_pid',
    'new_recollection_id',
    'site_code',
    'collection_date',
    'first_name',
    'last_name',
    'age',
    'gender',
    'state_name',
    'is_phf_qced',
    'pmr_available',
    'action',
  ];
  public dataSource = new MatTableDataSource<ParticipantElement>(ELEMENT_DATA);
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public totalOtherParticipants = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public currentMenuPermission: any;
  public statistics: any;
  public loading: boolean = false;
  public matNoDataRowTitle: string = 'Loading Data...';
  public IsInternalParticipant: boolean = true;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private participantService: ParticipantService,
    protected router: Router,
    private menuService: MenuService,
    private recontactService: RecontactService,
    private commonService: CommonService,
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.IsInternalParticipant = this.router.url === "/internal/participant" ? false : true;

    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAllParticipant();
    this.getStatistics();
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllParticipant();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      if (sortState.active == 'created_date_formatted') {
        this.sortField = 'created_at';
      }
      this.sortOrder = sortState.direction;
      this.getAllParticipant();
    }
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getAllParticipant() {
    this.participantService
      .getRecordByFilter(
        this.IsInternalParticipant,
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          if (result.data.data != undefined && result.data.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
        },
        error: (e) => {
          console.log('Participant Listing err :: ', e);
        },
        complete: () => { },
      });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllParticipant();
  }

  getStatistics() {
    this.participantService.getAllParticipantStatistics(this.IsInternalParticipant).subscribe({
      next: (result: any) => {
        this.statistics = result.data;
        this.totalOtherParticipants = parseFloat(this.statistics?.totalOtherParticipants) + parseFloat(this.statistics?.totalTransgenderParticipants) + parseFloat(this.statistics?.totalNotToSayParticipants);
      }, error: (e) => {
        console.log('Participant Statistics err :: ', e);
      }
    });
  }

  /**
 * @author Pramod Kushwaha
 * This function is used to delete Participant record
 * @param PID
 */
  reContactParticipant(PID: any) {
    Swal.fire({
      title: "Recontact?",
      html: 'Are you sure want to recontact this participant?',
      imageUrl: '../../../../../assets/images/reclassificaiton-icon-1.png',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        // return false;
        this.loading = true;
        const reFormData: FormData = new FormData();
        reFormData.append("participant_id", PID);
        this.recontactService.recontact(reFormData).subscribe({
          next: (res: any) => {
            if (res.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast("Recontact record has been created successfully");
              this.getAllParticipant();
            } else {
              this.commonService.showErrorToast(res.message);
            }
          },
          error: (err) => {
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }


  /**
  * @author Pramod Kushwaha
  * This function is used to delete Participant record
  * @param PID
  */
  deleteParticipant(PID: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the participant?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.participantService.deleteParticipant(PID).subscribe({
          next: (res: any) => {
            if (res.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(res.message);
              this.getAllParticipant();
            } else {
              this.commonService.showErrorToast(res.message);
            }
          },
          error: (err) => {
            // console.log('err :: ', err);
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }


}
