import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disease-mi-questions',
  templateUrl: './disease-mi-questions.component.html',
  styleUrls: ['./disease-mi-questions.component.scss']
})
export class DiseaseMiQuestionsComponent implements OnInit {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() diseaseUpdateIndex: any;
  @Input() diseaseDetails: any = [];

  public miForm!: FormGroup;
  public MI: any = [];
  public participantID: any;

  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private route: ActivatedRoute
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {

    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      this.MI = this.diseaseDetails['details'][this.diseaseUpdateIndex]['MYOCARDIAL_INFARCTION'];
    }

    console.log(this.MI, this.diseaseUpdateIndex, this.diseaseDetails['details']);

    // MYOCARDIAL_INFARCTION Questions
    this.miForm = this.formBuilder.group({
      is_mi_diagnosed_by_clinician: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.is_mi_diagnosed_by_clinician, [Validators.required]),
      photo_mi_prescription_note: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.photo_mi_prescription_note, []),
      photo_mi_prescription_note_url: new FormControl([null, undefined, ''].includes(this.MI?.photo_mi_prescription_note_url) ? null : this.MI.photo_mi_prescription_note_url, []),
      have_mi_echocardiography_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.have_mi_echocardiography_report, [Validators.required]),
      photo_mi_echocardiography_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.photo_mi_echocardiography_report, []),
      photo_mi_echocardiography_report_url: new FormControl([null, undefined, ''].includes(this.MI?.photo_mi_echocardiography_report_url) ? null : this.MI.photo_mi_echocardiography_report_url, []),
      have_troponin_i_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.have_troponin_i_report, [Validators.required]),
      photo_troponin_i_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.photo_troponin_i_report, []),
      photo_troponin_i_report_url: new FormControl([null, undefined, ''].includes(this.MI?.photo_troponin_i_report_url) ? null : this.MI.photo_troponin_i_report_url, []),
      have_troponin_t_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.have_troponin_t_report, [Validators.required]),
      photo_troponin_t_report: new FormControl([null, undefined, ''].includes(this.MI) ? null : this.MI?.photo_troponin_t_report, []),
      photo_troponin_t_report_url: new FormControl([null, undefined, ''].includes(this.MI?.photo_troponin_t_report_url) ? null : this.MI.photo_troponin_t_report_url, []),
    });

    this.diseaseDetailForm.setControl('MYOCARDIAL_INFARCTION', this.miForm);

    // MYOCARDIAL_INFARCTION Dynamic Validation 
    const miForm = this.diseaseDetailForm.get('MYOCARDIAL_INFARCTION') as FormArray;
    miForm.get('is_mi_diagnosed_by_clinician')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.miForm.controls['photo_mi_prescription_note'].setValidators([Validators.required]);
      } else {
        this.miForm.controls['photo_mi_prescription_note'].clearValidators();
      }
      this.miForm.controls['photo_mi_prescription_note'].updateValueAndValidity();
    });

    miForm.get('have_troponin_i_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.miForm.controls['photo_troponin_i_report'].setValidators([Validators.required]);
      } else {
        this.miForm.controls['photo_troponin_i_report'].clearValidators();
      }
      this.miForm.controls['photo_troponin_i_report'].updateValueAndValidity();
    });

    miForm.get('have_troponin_t_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.miForm.controls['photo_troponin_t_report'].setValidators([Validators.required]);
      } else {
        this.miForm.controls['photo_troponin_t_report'].clearValidators();
      }
      this.miForm.controls['photo_troponin_t_report'].updateValueAndValidity();
    });

    miForm.get('have_mi_echocardiography_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.miForm.controls['photo_mi_echocardiography_report'].setValidators([Validators.required]);
      } else {
        this.miForm.controls['photo_mi_echocardiography_report'].clearValidators();
      }
      this.miForm.controls['photo_mi_echocardiography_report'].updateValueAndValidity();
    });
  }

  /**
* This funtion is used to upload report
* @param event
* @param key
*/
  onSelectFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('disease_type', 'CHRONIC_KIDNEY_DISEASE');
      formData.append('previous_file', [null, undefined, ''].includes(this.MI?.key) ? '' : this.MI.key);
      this.participantService.uploadDiseaseQuestionReport(formData, this.participantID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            console.log(key);
            this.miForm.controls[key]?.setValue(result.data.file);
            this.miForm.controls[key + '_url']?.setValue(result.data.file_with_path);
            // console.log(this.diseaseDetails['details'][this.diseaseUpdateIndex]['MYOCARDIAL_INFARCTION']);
          }
        },
        error: (e: any) => {
          console.log('err :: ', e);
        },
        complete: () => {

        },
      });
    }
  }

  /**
   * Delete Disease Question Report File
   * @param fileName 
   * @param index 
   */
  deleteDiseaseQuestionReport(fileName: string, key: string) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the file?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.participantService.deleteDiseaseQuestionReport(fileName).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Report deleted successfully.', icon: 'success', });
              this.miForm.controls[key]?.setValue(null);
              this.diseaseDetails['details'][this.diseaseUpdateIndex]['MYOCARDIAL_INFARCTION'][key + '_url'] = null;
              this.miForm.controls[key].setValidators([Validators.required]);
              this.miForm.controls[key].updateValueAndValidity();
            } else {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: result.message, icon: 'warning', });
            }
          },
          error: (e: any) => {
            console.log('err :: ', e);
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: e, icon: 'warning', });
          },
          complete: () => {

          },
        });
      }
    });
  }

  showImage(fileUrl: string) {
    Swal.fire({
      imageUrl: fileUrl,
      showConfirmButton: false,
      showCloseButton: true
    });
  }
}
