<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form [ngClass]="allergyFormStatus ? 'edit-mode' : 'view-mode'" id="allergyForm" [formGroup]="allergyForm">
        <div class="container-fluid">
            <div class="card-wrape">
                <div class="card-body p-b-0">
                    <div class="input-wrape title-controller">
                        <mat-label>Do You Have Or Had Any Allergies Or Drug Reactions?</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="option"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button value="yes" (click)="resetForm('yes')">Yes</mat-radio-button>
                            <mat-radio-button value="no" (click)="resetForm('no')">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <button mat-raised-button class="btn add-btn" color="secoundary" [disabled]="allergyUpdateIndex!=null || allergyAdd || allergyUpdate"
                        *ngIf="allergyForm.controls['option'].value === 'yes'" (click)="openAllergy()">Add</button>
                </div>
            </div>
        </div>

        <!-- no record found wrape start -->
        <div class="No record" *ngIf="allergyForm.controls['option'].value === 'no'" class="no-record-pad">
            <img src="../../../../../../assets/images/edit-participant-no-data/allergy-no-data.svg"
                alt="Allergies and Drug Reactions">
            <span>No Allergies And Drug Reactions Found </span>
        </div>
        <!-- no record found wrape end -->

        <!-- popup code start -->
        <section class="popup-wrape success" [ngClass]="togglepopup ? 'success' : 'danger'"
            [formGroup]="allergyDetailForm">
            <div class="overlays"></div>
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text"> Allergies Or Drug Reactions</h4>
                    <a href="javascript:;" class="popup-close-btn" (click)="closePopup()">×</a>
                </div>
                <div class="card-body">
                    <div class="input-wrape">
                        <mat-label>Allergy Type:</mat-label>
                        <div class="control-pad">
                            <ng-select bindValue="option" bindLabel="option" formControlName="allergy_type"
                                placeholder="Enter allergy type">
                                <ng-option *ngFor="let option of allergyTypes" [value]="option">{{
                                    option | titlecase
                                    }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': allergyDetailForm.controls['allergy_description'].invalid && (allergyDetailForm.controls['allergy_description'].dirty || allergyDetailForm.controls['allergy_description'].touched)}">
                        <mat-label><i>*</i>Allergic to What?</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput #input placeholder="Enter allergic to what" type="text"
                                formControlName="allergy_description">
                        </mat-form-field>
                        <mat-error
                            *ngIf="allergyDetailForm.controls['allergy_description'].invalid && (allergyDetailForm.controls['allergy_description'].dirty || allergyDetailForm.controls['allergy_description'].touched)">
                            Please Enter allergic to what
                        </mat-error>
                    </div>

                    <div class="input-wrape">
                        <mat-label>What Allergic Reaction or Symptoms Developed?</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput #input placeholder="Enter what allergic reaction or symptoms developed"
                                type="text" formControlName="allergic_reaction">
                        </mat-form-field>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-raised-button class="btn" color="secoundary" *ngIf="!allergyUpdate"
                        [disabled]="!allergyDetailForm.controls['allergy_type'].value"
                        (click)="addAllergy()">Add</button>
                    <button mat-raised-button class="btn" color="secoundary" *ngIf="allergyUpdate"
                        [disabled]="!allergyDetailForm.controls['allergy_type'].value"
                        (click)="updateAllergy()">Update</button>
                </div>
            </div>
        </section>
        <!-- popup code end -->

        <!-- Table start -->
        <div class="container-fluid" *ngIf="allergyForm.controls['option'].value === 'yes'">
            <div class="row">
                <div class="col-xl-12" *ngIf="dataSource.filteredData.length">
                    <div class="card-wrape">
                        <div class="card-head">
                            <h4 class="left-text"> Allergy List</h4>
                        </div>
                        <div class="card-body">
                            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                <ng-container matColumnDef="allergy_type">
                                    <mat-header-cell *matHeaderCellDef>Allergy Type </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.allergy_type | titlecase}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="allergy_description">
                                    <mat-header-cell *matHeaderCellDef>Allergic to what?</mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.allergy_description}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="allergic_reaction">
                                    <mat-header-cell *matHeaderCellDef>What Allergic Reaction or Symptoms
                                        Developed?</mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.allergic_reaction}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                    <mat-cell *matCellDef="let element; let i=index;">
                                        <div class="icons-wrape">
                                            <a (click)="editAllergy(i)" class="theme-icon edit-icon"
                                                title="Edit Allergies"></a>
                                            <a (click)="allergyDelete(i)" class="theme-icon delete-icon"
                                                title="Delete Allergies"></a>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                <div *matNoDataRow>No records found</div>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table end -->

        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickOnEdit()" *ngIf="!allergyFormStatus" mat-raised-button class="btn"
                color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="allergyFormStatus" mat-raised-button class="btn"
                color="warn">Cancel</button>
            <button type="button" (click)="allergySubmit()" [disabled]="(!allergyAdd && !allergyUpdate) || loading" *ngIf="allergyFormStatus" mat-raised-button class="btn"
                color="secoundary">Save</button>
        </div>
    </form>
</div>
