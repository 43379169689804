import { AfterViewInit, Component } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { OrganSiteService } from 'src/app/service/organ-site.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';
@Component({
  selector: 'app-organ-site',
  templateUrl: './organ-site.component.html',
  styleUrls: ['./organ-site.component.scss'],
})
export class OrganSiteComponent implements AfterViewInit {
  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Description',
      dataKey: 'description',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['description']}`,
    },
  ];
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/organ-site/edit/';
  public title = 'Organ/Site';
  public data: any;
  public currentMenuPermission: any;

  constructor(
    private organSiteService: OrganSiteService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService,

  ) { }

  ngAfterViewInit() { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getAll() {
    this.loading = true;
    this.organSiteService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('organ-site Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }

  /**
   * Delete Organ site api
   * @returns null
   */
  deleteOrganSite(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the  Organ/Site?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.organSiteService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                'Organ/Site deleted successfully'
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                "Organ/Site can't be deleted. Please try again later."
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Language not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  SortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
