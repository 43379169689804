import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2'

export interface FamilyMemberTable {
  id: Number
  family_member: string
  disease_names: any
  is_other_diseases_names: any
  action: any
}

const ELEMENT_DATA: FamilyMemberTable[] = [];

@Component({
  selector: 'app-family-medical-history',
  templateUrl: './family-medical-history.component.html',
  styleUrls: ['./family-medical-history.component.scss']
})
export class FamilyMedicalHistoryComponent implements OnInit {

  public PID: any;
  public diseaseFamilyHistory: any;
  public diseaseFamilyHistoryForm!: FormGroup;
  public familyList: any;
  public diseasesList: any;
  public participantDiseasesList: any;
  public fdUpdateIndex: any;
  public fdUpdate: boolean = false;
  public fdAdd: boolean = false;
  public loading:boolean=false;

  diseaseFamilyHistoryFormStatus: boolean = false;
  familyOtherdiseases: boolean = false;


  displayedColumns: string[] = ['family_member', 'disease_names', 'action'];
  public dataSource = new MatTableDataSource<FamilyMemberTable>(ELEMENT_DATA);

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    let familyList = JSON.parse(localStorage.getItem('constants') as string);
    this.familyList = familyList['FAMILY_MEMBERS'];
    this.getDiseaseList();
    this.getParticipantDiseasesList();

    this.diseaseFamilyHistoryForm = this.formBuilder.group({
      family_member: [null, []],
      disease_ids: [[], []],
      disease_names: [[], []],
      is_other_diseases_names: [[], []],
      is_other_diseases: [null, []],
      tmp_disease_ids: [null, []],
    });

    this.getFamilyDiseases();

    console.log(this.familyList);
  }

  // sweet alert code end  

  /**
   * @author Pramod Pramod Kushwaha
   * Enable form edit 
   */
  clickOnEdit() {
    console.log(this.fdAdd,this.fdUpdate);
    this.diseaseFamilyHistoryFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close 
  */
  clickOnCancel() {
    this.diseaseFamilyHistoryFormStatus = false;
    this.fdUpdate = false;
    this.fdAdd = false;
    this.fdUpdateIndex = null;
  }

  familyDiseaseSubmit() {
    this.loading = true;
    console.log(this.diseaseFamilyHistory);
    
    this.participantService.updateFD(this.diseaseFamilyHistory, this.PID).subscribe({
      next: (result: any) => {
        console.log(result);
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.getFamilyDiseases();
          this.diseaseFamilyHistoryForm.reset();
          this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
          this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
          this.familyOtherdiseases = false;
          this.fdAdd = false;
          this.fdUpdate = false;
          this.fdUpdateIndex = null;
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => { },
    });
  }

  /**
 * This function is used to get disease list
 */
  getDiseaseList() {
    this.dictionaryService.getDiseaseList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.diseasesList = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
* This function is used to get disease list
*/
  getParticipantDiseasesList() {
    this.participantService.getParticipantDiseasesList(this.PID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.participantDiseasesList = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
* This function is used to get disease list
*/
  getFamilyDiseases() {
    this.loading = true;
    this.participantService.getFamilyDiseases(this.PID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.diseaseFamilyHistory = result.data;
          this.dataSource = new MatTableDataSource(result.data);
        }
        this.loading = false;
      },
      error: (e) => { 
        this.loading = false;
      },
      complete: () => { 
        this.loading = false;
      },
    });
  }

  selectOthers(event: any) {
    this.familyOtherdiseases = false;
    if (event) {
      this.familyOtherdiseases = true;
    }
  }

  selectParticpantDiseases(event: any) {
    console.log(event);
    if (event.checked) {
      this.diseaseFamilyHistoryForm.controls['disease_ids'].value.push(Number(event.source.value));
      this.diseaseFamilyHistoryForm.controls['disease_names'].value.push(event.source.name);
    } else {
      this.deleteTestSelect(
        this.diseaseFamilyHistoryForm.controls['disease_ids'].value,
        Number(event.source.value)
      );
      this.deleteTestSelect(
        this.diseaseFamilyHistoryForm.controls['disease_names'].value,
        event.source.name
      );
    }
    // console.log(event.source.value,this.diseaseFamilyHistoryForm.controls['disease_ids'].value);
    // console.log(event.source.value,this.diseaseFamilyHistoryForm.controls['disease_name'].value);
  }

  diseasesSelect(event: any) {
    console.log(event);
    let is_other_diseases_names: any = [];
    this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue([]);
    if (event.length > 0) {
      event.forEach((value: any) => {
        is_other_diseases_names.push(value.name);
      });
      // console.log(is_other_diseases_names);
    }
    this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].value.push(is_other_diseases_names);
    // console.log(event.source.value,this.diseaseFamilyHistoryForm.controls['is_other_diseases'].value);
    // console.log(is_other_diseases_names, this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].value);
  }

  deleteTestSelect(data: any, value: Number) {
    const index: number = data.indexOf(value);
    if (index !== -1) {
      data.splice(index, 1);
    }
  }

  addFD() {
    this.fdAdd = true;
    this.diseaseFamilyHistory.push(this.diseaseFamilyHistoryForm.value);
    this.dataSource = new MatTableDataSource(this.diseaseFamilyHistory);
    this.diseaseFamilyHistoryForm.reset();
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
    this.familyOtherdiseases = false;
  }

  updateFD() {
    this.deleteObject(this.fdUpdateIndex);
    if(!this.familyOtherdiseases) {
      this.diseaseFamilyHistoryForm.value.is_other_diseases = [];
      this.diseaseFamilyHistoryForm.value.is_other_diseases_names = [];
    }
    this.diseaseFamilyHistory.push(this.diseaseFamilyHistoryForm.value);
    this.dataSource = new MatTableDataSource(this.diseaseFamilyHistory);
    this.diseaseFamilyHistoryForm.reset();
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
    this.familyOtherdiseases = false;
  }

  editFD(index: any) {
    this.fdUpdateIndex = index;
    this.fdUpdate = true;
    let formValues = this.diseaseFamilyHistory[index];
    let disease_ids = [null, undefined].includes(formValues.disease_ids) ? [] : formValues.disease_ids;
    let disease_names = [null, undefined].includes(formValues.disease_names) ? [] : formValues.disease_names;
    let is_other_diseases = [null, undefined].includes(formValues.is_other_diseases) ? [] : formValues.is_other_diseases;
    let is_other_diseases_names = [null, undefined].includes(formValues.is_other_diseases_names) ? [] : formValues.is_other_diseases_names;
    console.log(formValues, disease_ids, disease_names, is_other_diseases, is_other_diseases_names);
    if (is_other_diseases.length > 0) {
      this.familyOtherdiseases = true;
    }
    this.diseaseFamilyHistoryForm.controls['family_member'].setValue(formValues.family_member);
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue(disease_ids);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue(disease_names);
    this.diseaseFamilyHistoryForm.controls['is_other_diseases'].setValue(is_other_diseases);
    this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue(is_other_diseases_names);
  }

  deleteFD(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Family Member Medical History?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // this.fdUpdate = true;
        this.deleteObject(id);
        this.dataSource = new MatTableDataSource(this.diseaseFamilyHistory);
        this.familyDiseaseSubmit();
      }
      console.log(result);
    });
  }

  deleteObject(index: Number) {
    if (index !== -1) {
      this.diseaseFamilyHistory.splice(index, 1);
    }
  }

}
