import { AfterViewInit, Component } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { OrganSite } from 'src/app/interfaces/OrganSite';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { OrganSiteService } from 'src/app/service/organ-site.service';

@Component({
  selector: 'app-disease-form',
  templateUrl: './disease-form.component.html',
  styleUrls: ['./disease-form.component.scss'],
})
export class DiseaseFormComponent implements AfterViewInit {
  public title: any;
  public loading: Boolean = false;
  public isAddMode: boolean = true;
  public userId: any = '';
  public diseaseId: any = '';
  public diseaseDetails: any;
  public diseaseForm!: FormGroup;
  public nameMinLength: number = 3;
  public is_disease_or_symptom_checked: boolean = false;
  public buttonText: string;
  public selectedOrganSiteList: any = [];
  public isCurate: boolean = false;
  public selectMapOption: boolean = false;
  public disableSubmitBtn: boolean = false;
  public organSiteList: OrganSite[] = [];
  public diseaseList: any[] = [];
  public cancelLink: string = '/dictionary-management/disease';

  constructor(
    private organSiteService: OrganSiteService,
    private diseaseService: DiseaseService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.diseaseId = this.route.snapshot.paramMap.get('id');

    if (this.diseaseId !== 0 && this.diseaseId !== null) {
      this.setEditData();
      this.title = 'Edit Disease';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Disease';
      this.buttonText = 'Add';
      this.diseaseId = 0;
    }
  }

  ngAfterViewInit() { }

  ngOnInit() {
    this.isAddMode = !this.diseaseId;
    const routerSubscription = this.route.queryParams.subscribe(
      (queryParams: any) => {
        if (this.router.url.indexOf('curation-pending') >= 0) {
          this.isCurate = true;
          this.title = 'Curate Disease';
          this.cancelLink = '/dictionary-management/disease/curation-pending';
          // Get Disease Dictionary
          this.getDiseaseList();
        }
      }
    );
    this.userId = localStorage.getItem('userId');
    this.getOrganSite();
    this.diseaseForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      snomed_name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      snomed_id: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      display_term: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      remarks: ['', []],
      organ_site_id: [null, [Validators.required]],
      is_disease_or_symptom: ['', [Validators.required]],
      isApproved: [1, []],
      mapWithOtherDisease: [null, []],
      icd10_terminology: ['', []],
      icd10_id: ['', []],
      icd9_terminology: ['', []],
      icd9_id: ['', []],
    });
  }

  /**
   * Add and update organ site records
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  onSubmit() {
    if (this.diseaseForm.invalid) {
      this.commonService.validateAllFormFields(this.diseaseForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.diseaseForm.controls['name'].value);
    formData.append(
      'organ_site_id',
      this.diseaseForm.controls['organ_site_id'].value
    );
    formData.append(
      'snomed_name',
      this.diseaseForm.controls['snomed_name'].value
    );
    formData.append('snomed_id', this.diseaseForm.controls['snomed_id'].value);
    formData.append(
      'display_term',
      this.diseaseForm.controls['display_term'].value
    );
    formData.append('remarks', this.diseaseForm.controls['remarks'].value);
    formData.append(
      'is_disease_or_symptom',
      this.diseaseForm.controls['is_disease_or_symptom'].value
    );
    formData.append(
      'is_approved',
      this.diseaseForm.controls['isApproved'].value
    );
    formData.append(
      'map_with_other_disease',
      this.diseaseForm.controls['mapWithOtherDisease'].value
        ? this.diseaseForm.controls['mapWithOtherDisease'].value
        : []
    );
    formData.append(
      'icd10_terminology',
      this.diseaseForm.controls['icd10_terminology'].value
    );
    formData.append('icd10_id', this.diseaseForm.controls['icd10_id'].value);
    formData.append(
      'icd9_terminology',
      this.diseaseForm.controls['icd9_terminology'].value
    );
    formData.append('icd9_id', this.diseaseForm.controls['icd9_id'].value);

    if (this.diseaseId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    if (this.isAddMode) {
      this.createDisease(formData);
    } else {
      this.updateDisease(formData);
    }
  }

  /**
   * call Create Disease api
   * @returns null
   */
  private createDisease(formData: any): void {
    this.diseaseService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast('Disease added successfully');
          this.router.navigate(['/dictionary-management/disease']);
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err) => {
        console.log('error from create Disease', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update Disease api
   * @returns null
   */
  private updateDisease(formData: any): void {
    this.diseaseService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast('Disease updated successfully');
            if (this.isCurate) {
              this.router.navigate(['/dictionary-management/disease/curation-pending']);
            } else {
              this.router.navigate(['/dictionary-management/disease']);
            }
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err) => {
          this.disableSubmitBtn = false;
          console.log('error from update country', err);
        },
      });
  }

  setEditData() {
    this.loading = true;
    this.diseaseService.getRow(this.diseaseId).subscribe((result: any) => {
      if (result.status !== AppConstants.serverSuccessStatus) {
        this.router.navigate(['page-not-found']);
      }
      this.diseaseDetails = result['data'];
      let organSiteIds: any[] = [];
      this.diseaseDetails.organ_sites.forEach((res: any) => {
        // this.selectedOrganSiteList.push(res.name);
        organSiteIds.push(res.id);
      });


      this.diseaseForm.patchValue({
        name: this.diseaseDetails.name,
        display_term: this.diseaseDetails.display_term,
        snomed_name: this.diseaseDetails.snomed_name,
        snomed_id: this.diseaseDetails.snomed_id,
        remarks: this.diseaseDetails.remarks,
        icd10_terminology: this.diseaseDetails.icd10_terminology,
        icd10_id: this.diseaseDetails.icd10_id,
        icd9_terminology: this.diseaseDetails.icd9_terminology,
        icd9_id: this.diseaseDetails.icd9_id,
        is_disease_or_symptom: this.diseaseDetails.is_disease_or_symptom,
        organ_site_id: organSiteIds,
      });
      this.loading = false;
    });
  }

  diseaseOrSymptomChange(event: any) {
    if (!this.isAddMode) {
      this.diseaseDetails.is_disease_or_symptom =
        !this.diseaseDetails.is_disease_or_symptom;
    }
    this.diseaseForm.controls['is_disease_or_symptom'].setValue(event.value);
  }

  /**
   * Check Disease field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueRecord(event: any) {
    let name = (event.target as HTMLInputElement).value;
    if (name !== '' && name.length > 3 && name.length < 255) {
      this.diseaseService
        .checkUniqueDiseaseName(name, this.diseaseId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.diseaseForm.controls['name'].setErrors(null);
            } else {
              this.diseaseForm.controls['name'].setErrors({
                unique: 'Disease name already exists',
              });
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.diseaseForm.controls['name'].setErrors({
                unique: 'Disease name already exists',
              });
            } else {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
        });
    }
  }

  /**
   * Get OrganSite
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  getOrganSite() {
    this.organSiteService.getActiveList().subscribe((result: any) => {
      this.organSiteList = result.data;
    });
  }

  /**
   * Get all disease for validate disease
   * @returns object
   */
  // Get disease Dictionary
  getDiseaseList() {
    this.diseaseService.getActiveList().subscribe({
      next: (result: any) => {
        this.diseaseList = result.data;
      },
      error: (e) => {
        console.log('error from get disease list', e.error);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * Select Disease Details
   *
   * @returns object
   */
  getDiseaseDetails(event: any) {
      this.diseaseService.getRow(event.id).subscribe((result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          let organSiteIds: any[] = [];
          result.data.organ_sites.forEach((res: any) => {
            organSiteIds.push(res.id);
          });
          
          this.diseaseForm.patchValue({
            display_term: result.data.display_term,
            snomed_name: result.data.snomed_name,
            snomed_id: result.data.snomed_id,
            is_disease_or_symptom: result.data.is_disease_or_symptom, // do not set disabled radio button
            remarks: result.data.remarks,
            icd10_terminology: result.data.icd10_terminology,
            icd10_id: result.data.icd10_id,
            icd9_terminology: result.data.icd9_terminology,
            icd9_id: result.data.icd9_id,
            organ_site_id: organSiteIds,
          });
        }
      });
    }

  /**
   * Select Approved Option
   *
   * @returns object
   */
  selectApprovedOption(value: number) {
    
    if (value == 0) {
      this.selectMapOption = true;
      this.diseaseForm
      .get('mapWithOtherDisease')!
      .setValidators([
        Validators.required,
        Validators.pattern('^[1-9][0-9]*$'),
      ]);
      this.diseaseForm.controls['organ_site_id'].disable();
      this.diseaseForm.get('organ_site_id')!.setValidators(null);
      this.diseaseForm.get('snomed_id')!.setValidators(null);
    } else {
      this.setEditData();
      this.diseaseForm.controls['organ_site_id'].enable();
      this.selectMapOption = false;
      this.diseaseForm.get('mapWithOtherDisease')!.setValidators(null);
      this.diseaseForm.get('organ_site_id')!.setValidators(Validators.required);
      this.diseaseForm.get('snomed_id')!.setValidators(
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ]
      );
    }
    this.diseaseForm.controls['mapWithOtherDisease'].updateValueAndValidity();
    this.diseaseForm.controls['organ_site_id'].updateValueAndValidity();
    this.diseaseForm.controls['snomed_id'].updateValueAndValidity();
    this.getMapWithDiseaseError();
  }

  getMapWithDiseaseError() {
    if (this.diseaseForm.controls['mapWithOtherDisease'].invalid) {
      if (
        this.diseaseForm.controls['mapWithOtherDisease'].errors!['required']
      ) {
        return 'Select SNOMED';
      }
      if (this.diseaseForm.controls['mapWithOtherDisease'].errors!['pattern']) {
        return 'Please select valid SNOMED';
      }
    }
    return '';
  }
}
