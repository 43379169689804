import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { PopulationService } from 'src/app/service/population.service';
import { SiteService } from 'src/app/service/site.service';
import { StateService } from 'src/app/service/state.service';
import { UserService } from '../../../service/user.service';
import { DiseaseChartComponent } from './disease-chart/disease-chart.component';
import { GenderChartComponent } from './gender-chart/gender-chart.component';
import { LatestSitesComponent } from './latest-sites/latest-sites.component';
import { ParticipantCountChartComponent } from './participant-count-chart/participant-count-chart.component';
import { PopulationChartComponent } from './population-chart/population-chart.component';
import { SiteWiseParticipantCountsComponent } from './site-wise-participant-counts/site-wise-participant-counts.component';
import { StateChartComponent } from './state-chart/state-chart.component';
import { StateMapChartComponent } from './state-map-chart/state-map-chart.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  public totalParticipantEnrolled: number = 0;
  public totalCampsHeld: number = 0;
  public totalCounselors: number = 0;
  public totalHtr: number = 0;
  public loading: boolean = false;
  public status: boolean = false;
  public dashboardFilterForm!: FormGroup;
  public sites: any = [];
  public genders: any = [];
  public states: any = [];

  public selectedSiteCodes: any = [];
  public selectedGenders: any = [];
  public selectedStates: any = [];
  public selectedSubpopulations: any = [];
  public selectedDiseases: any = [];
  public selectedStateDate: any = "";
  public populations: any = [];
  public diseases: any = [];
  public minDate = new Date();
  public fromDate: any = "";
  public toDate: any = "";
  public disableFilterSubmitBtn: boolean = false;

  @ViewChild("loadDiseaseChart", { static: true })
  diseaseChart!: DiseaseChartComponent;

  @ViewChild("loadParticipantCountChart", { static: true })
  participantCountChart!: ParticipantCountChartComponent;

  @ViewChild("loadSiteChart", { static: true })
  siteChart!: SiteWiseParticipantCountsComponent;

  @ViewChild("loadPopulationChart", { static: true })
  subpopulationChart!: PopulationChartComponent;

  @ViewChild("loadStateChart", { static: true })
  stateChart!: StateChartComponent;

  @ViewChild("loadGenderChart", { static: true })
  genderChart!: GenderChartComponent;

  @ViewChild("loadStateMapChart", { static: true })
  stateMapChart!: StateMapChartComponent;

  @ViewChild("loadLatestSites", { static: true })
  latestSitesChart!: LatestSitesComponent;

  constructor(
    private userService: UserService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private siteService: SiteService,
    private stateService: StateService,
    private diseaseService: DiseaseService,
    private populationService: PopulationService,
    private commonService: CommonService
  ) {
    this.userService.getConstants().subscribe({
      next: (result: any) => {
        localStorage.setItem('constants', JSON.stringify(result));
        this.genders = JSON.parse(localStorage.getItem('constants') as string)['GENDERS'];
      },
      error: (e) => { },
      complete: () => { },
    });
  }
  ngOnInit(): void {
    this.getSites();
    this.getStates();
    this.getPopulations();
    this.getDiseases();

    this.getDashboardCounts("total_participant_enrolled", "");
    this.getDashboardCounts("total_counselors", "");
    this.getDashboardCounts("total_camps_held", "");
    this.getDashboardCounts("total_htr", "");

    this.dashboardFilterForm = this.formBuilder.group({
      siteCodes: ["", ""],
      genders: ["", ""],
      states: ["", ""],
      populations: ["", ""],
      diseases: ["", ""],
      fromDate: ["", ""],
      toDate: ["", ""],
    });
  }
  clickEvent() {
    this.status = !this.status;
  }

  /**
   * Get Participants counts
   */
  getDashboardCounts(type: string, formData: any) {
    this.loading = true;
    this.dashboardService
      .getDashboardCounts(type, formData)
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          switch (type) {
            case "total_participant_enrolled":
              this.totalParticipantEnrolled = result.data;
              break;

            case "total_counselors":
              this.totalCounselors = result.data;
              break;

            case "total_camps_held":
              this.totalCampsHeld = result.data;
              break;

            case "total_htr":
              this.totalHtr = result.data;
              break;
          }
        }
      });
  }

  filterDashboardData(isRemoveItemMethod: boolean = false) {
    if (this.dashboardFilterForm.invalid) {
      this.commonService.validateAllFormFields(this.dashboardFilterForm);
      return;
    }
    const formData: FormData = new FormData();
  
    if (this.dashboardFilterForm.controls["fromDate"].value !== "" && this.dashboardFilterForm.controls["fromDate"].value !== null) {
      formData.append(
        'from_date',
        this.commonService.transformDate(
          this.dashboardFilterForm.controls['fromDate'].value
        ) as unknown as Blob
      );
    }
    if (this.dashboardFilterForm.controls["toDate"].value !== "" && this.dashboardFilterForm.controls["toDate"].value !== null) {
      formData.append(
        'to_date',
        this.commonService.transformDate(
          this.dashboardFilterForm.controls['toDate'].value
        ) as unknown as Blob
      );
    }
    formData.append(
      "site_codes",
      this.dashboardFilterForm.controls["siteCodes"].value
    );
    formData.append(
      "genders",
      this.dashboardFilterForm.controls["genders"].value
    );
    formData.append(
      "states",
      this.dashboardFilterForm.controls["states"].value
    );
    formData.append(
      "populations",
      this.dashboardFilterForm.controls["populations"].value
    );
    formData.append(
      "diseases",
      this.dashboardFilterForm.controls["diseases"].value
    );
    // this.getParticipantsCount(formData);

    this.getDashboardCounts("total_participant_enrolled", formData);
    this.getDashboardCounts("total_counselors", formData);
    this.getDashboardCounts("total_camps_held", formData);
    this.getDashboardCounts("total_htr", formData);

    // this.getDashboardChartData("state_map", formData, 1, "year", 1);

    this.diseaseChart.filterDashboardData(formData);
    this.participantCountChart.filterDashboardData(formData);
    this.siteChart.filterDashboardData(formData);
    this.subpopulationChart.filterDashboardData(formData);
    this.stateChart.filterDashboardData(formData);
    this.genderChart.filterDashboardData(formData);
    this.stateMapChart.filterDashboardData(formData);
    this.latestSitesChart.filterDashboardData(formData);

    if (typeof isRemoveItemMethod === 'undefined') {
      isRemoveItemMethod = false;
    }

    if (isRemoveItemMethod !== true) {
      this.status = !this.status;
    }
  }

  /**
  * Get Participants counts
  */
  // getParticipantsCount(formData: any) {
  //   this.dashboardService
  //     .getParticipantsCount(formData)
  //     .subscribe((result: Data) => {
  //       this.totalAppSourceParticipantsCount =
  //         result.data.totalAppSourceParticipantsCount;
  //       this.totalExcelSourceParticipantsCount =
  //         result.data.totalExcelSourceParticipantsCount;
  //       this.totalParticipantsCount = result.data.totalParticipantsCount;
  //     });
  // }

  /**
   * Get Site List
   * 
   * @returns object
   */
  getSites() {
    this.siteService.getAllList().subscribe((result: any) => {
      this.sites = result.data;
    });
  }
  /**
   * Get State List
   *
   * @returns object
   */
  getStates() {
    this.stateService.getActiveList().subscribe((result: any) => {
      if (result.data.length > 1) {
        this.states = result.data;
      }
    });
  }

  /**
   * Get Population List
   *
   * @returns object
   */
  getPopulations() {
    this.populationService.getActiveList().subscribe((result: any) => {
      this.populations = result.data;
    });
  }

  /**
   * Get Disease List
   *
   * @returns object
   */
  getDiseases() {
    this.diseaseService.getActiveList().subscribe((result: any) => {
      this.diseases = result.data;
    });
  }



  /**
  *
  * @param filterType
  * @param $event
  */
  addSelectedItem(filterType: string, $event: any) {
    switch (filterType) {
      case "site_code":
        this.selectedSiteCodes.push($event);
        break;

      case "gender":
        this.selectedGenders.push($event);
        break;

      case "state":
        this.selectedStates.push($event);
        break;

      case "population":
        this.selectedSubpopulations.push($event);
        break;

      case "disease":
        this.selectedDiseases.push($event);
        break;
    }
  }

  /**
   *
   * @param filterType
   */
  clearSelectedItem(filterType: string) {

    switch (filterType) {
      case "site_code":
        this.selectedSiteCodes = [];
        break;

      case "gender":
        this.selectedGenders = [];
        break;

      case "state":
        this.selectedStates = [];
        break;

      case "population":
        this.selectedSubpopulations = [];
        break;

      case "disease":
        this.selectedDiseases = [];
        break;
    }

    this.filterDashboardData(true);

  }

  /**
  *
  * @param filterType
  * @param $event
  */
  removeSelectedItem(filterType: string, $event: any) {
    switch (filterType) {
      case "site_code":
        this.selectedSiteCodes = this.selectedSiteCodes.filter(
          (e: any) => e !== $event.value
        );
        break;

      case "gender":
        this.selectedGenders = this.selectedGenders.filter(
          (e: any) => e !== $event.value
        );
        break;

      case "state":
        this.selectedStates = this.selectedStates.filter(
          (e: any) => e !== $event.value
        );
        break;

      case "population":
        this.selectedSubpopulations = this.selectedSubpopulations.filter(
          (e: any) => e !== $event.value
        );
        break;

      case "disease":
        this.selectedDiseases = this.selectedDiseases.filter(
          (e: any) => e !== $event.value
        );
        break;
    }
    this.filterDashboardData(true);

  }

  /**
   *
   * @param filterForm
   */
  clearForm() {
    this.dashboardFilterForm.reset();

    this.selectedSiteCodes = [];
    this.selectedGenders = [];
    this.selectedStates = [];
    this.selectedSubpopulations = [];
    this.selectedDiseases = [];
    this.toDate = '';
    this.fromDate = '';

    this.filterDashboardData(true);
  }

  /**
   *
   * @param filterType
   * @param event
   */
  removeItem(filterType: string, event: any) {
    switch (filterType) {
      case "site_code":
        this.selectedSiteCodes = this.selectedSiteCodes.filter(
          (e: any) => e !== event
        );

        let remainingSiteCodes: any[] = [];
        this.selectedSiteCodes.forEach((element: any) => {
          remainingSiteCodes.push(element.id);
        });

        this.dashboardFilterForm.patchValue({ siteCodes: remainingSiteCodes });
        break;

      case "gender":
        this.selectedGenders = this.selectedGenders.filter((e: any) => e !== event);

        let remainingGenders: any[] = [];
        this.selectedGenders.forEach((element: any) => {
          remainingGenders.push(element.id);
        });

        this.dashboardFilterForm.patchValue({ genders: remainingGenders });
        break;

      case "state":
        this.selectedStates = this.selectedStates.filter((e: any) => e !== event);

        let remainingStates: any[] = [];
        this.selectedStates.forEach((element: any) => {
          remainingStates.push(element.id);
        });

        this.dashboardFilterForm.patchValue({ states: remainingStates });
        break;

      case "population":
        this.selectedSubpopulations = this.selectedSubpopulations.filter(
          (e: any) => e !== event
        );
        let remainingSubpopulations: any[] = [];
        this.selectedSubpopulations.forEach((element: any) => {
          remainingSubpopulations.push(element.id);
        });

        this.dashboardFilterForm.patchValue({
          populations: remainingSubpopulations,
        });
        break;

      case "disease":
        this.selectedDiseases = this.selectedDiseases.filter(
          (e: any) => e !== event
        );

        let remainingDiseases: any[] = [];
        this.selectedDiseases.forEach((element: any) => {
          remainingDiseases.push(element.id);
        });

        this.dashboardFilterForm.patchValue({ diseases: remainingDiseases });
        break;
    }
    this.filterDashboardData(true);
  }


  /**
  *
  * @param dateType
  * @param vicDate
  */
  compareTwoDates(dateType: any, vicDate: any) {
    if (dateType === "fromDate") {
      this.fromDate = vicDate.value;
    }
    if (dateType === "toDate") {
      this.toDate = vicDate.value;
    }
    if (this.toDate !== null) {
      let toDate = this.commonService.transformDate(this.toDate) as string;
      let fromDate = this.commonService.transformDate(this.fromDate) as string;
      if (toDate < fromDate) {
        this.disableFilterSubmitBtn = true;
        this.commonService.showErrorToast("Start date should be less than end date");
      } else {
        this.disableFilterSubmitBtn = false;
      }
    } else {
      this.disableFilterSubmitBtn = false;
    }
  }

    /**
   *
   */
    clearFromDateFilter() {
      this.fromDate = "";
      this.dashboardFilterForm.patchValue({
        fromDate: "",
      });
      this.filterDashboardData(true);
    }
  
    /**
     *
     */
    clearToDateFilter() {
      this.toDate = "";
      this.dashboardFilterForm.patchValue({
        toDate: "",
      });
      this.filterDashboardData(true);
    }
}
