import { Data } from '@angular/router';

export class AppConstants {
  /**
   * @author Dhaval Bera
   */
  public static get hospitalRole(): string {
    return '2';
  }

  /**
   * @author Dhaval Bera
   */
  public static get doctorRole(): string {
    return '3';
  }

  /**
   * @author Dhaval Bera
   */
  public static get patientRole(): string {
    return '4';
  }

  public static get serverSuccessStatus(): number {
    return 1;
  }

  public static get serverFailStatus(): number {
    return 0;
  }

  public static get logoutStatus(): number {
    return 401;
  }

  public static get TokenExpiredStatus(): number {
    return 701;
  }

  public static get ActiveStatusName(): string {
    return 'Active';
  }

  public static get DeactiveStatusName(): string {
    return 'Deactive';
  }

  public static get loginSource(): string {
    return 'dashboard';
  }

  public static get paginationSize(): number {
    return 50;
  }

  public static get defaultProfileImage(): string {
    return 'user.jpg';
  }

  public static get getConsentArray(): any {
    const arr: Data[] = [
      { id: 'Version 1', name: 'Version 1' },
      { id: 'Version 2', name: 'Version 2' },
      { id: 'Version 3', name: 'Version 3' },
      { id: 'Other', name: 'Other' },
    ];
    return arr;
  }

  public static get getRoleKey(): any {
    const arr: Data[] = [
      { id: 'super_admin', name: 'SUPER_ADMIN' },
      { id: 'site_admin', name: 'SITE_ADMIN' },
      { id: 'counsellors', name: 'COUNSELLORS' },
      { id: 'developers', name: 'DEVELOPERS' },
      { id: 'dev_admin', name: 'DEV_ADMIN' },
      { id: 'ass_admin', name: 'ASSESSMENT_ADMIN' },
      { id: 'qc', name: "Q'C" },
      { id: 'reviewer', name: 'REVIEWER' },
      { id: 'curator', name: 'CURATOR' },
    ];

    return arr;
  }

  public static get pageSizeOptions(): any {
    const arr: number[] =  [10, 25, 50, 100];
    return arr;
  }
}
