<div class="page-title-box">
    <h1> Site Listing for {{title}} </h1>
    <ul class="bradcrumb">
        <li> Site {{title}}</li>
        <li><a routerLink="/site" title="Site" class="only-link">Manage Site</a></li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12 m-b-0">
            <div class="stat-wrape five">
                <div class="stat-pad">
                    <p>SITES</p>
                    <h2><i class="theme-icon participant-enroll"></i> {{totalSites}}</h2>
                </div>
                <div class="stat-pad">
                    <p>PARTICIPANTS</p>
                    <h2><i class="theme-icon camp-held"></i> {{totalPatricipants}}</h2>
                </div>
                <div class="stat-pad">
                    <p>IMAGES</p>
                    <h2><i class="theme-icon image"></i> {{totalImages}}</h2>
                </div>
                <div class="stat-pad">
                    <p>DISEASES</p>
                    <h2><i class="theme-icon disease"></i> {{totalDiseases}}</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <mat-spinner *ngIf="loading"></mat-spinner>
                <app-table [tableData]="matrixList" [tableColumns]="displayedColumns" [isFilterable]="true"
                    [isPageable]="true" [paginationSizes]="pageSizeOptions" [defaultPageSize]="10"
                    [totalRows]="totalRows" [pageIndex]="currentPage" [rowActionIcon]="'Action'"
                    (sort)="sortFilter($event)" (viewAction)="viewAction($event)" (pageChanged)="pageChanged($event)"
                    [isMatrix]="true" [exportOrangeDmCohortLink]="exportLink" (searchFilter)="searchFilter($event)"
                    [pageTitle]="title" [currentMenuPermission]="currentMenuPermission">
                </app-table>
            </div>
        </div>
    </div>
</section>