import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { AppConstants } from 'src/app/app.constant';
import { Country } from 'src/app/interfaces/Country';
import { Gender } from 'src/app/interfaces/Gender';
import { Role } from 'src/app/interfaces/Role';
import { CommonService } from '../../../../service/common.service';
import { DictionaryService } from '../../../../service/dictionary.service';
import { RoleService } from '../../../../service/role.service';
import { UserService } from '../../../../service/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public selectedCountryCode = {};
  public userID: any;
  public userDetail: any = [];
  public userForm!: FormGroup;
  public birthDate: any;
  public genderOptions: Gender[] = [];
  public roleList!: Role[];
  public countryCodes: any[] = [];
  public title: string;
  public type: string;
  public minDate = new Date();
  public maxDate = new Date();

  constructor(
    private dictionaryService: DictionaryService,
    private route: ActivatedRoute,
    protected router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private roleService: RoleService,
    private commonService: CommonService
  ) {
    this.minDate.setDate(this.minDate.getDate() - 54750);
    this.maxDate.setDate(this.maxDate.getDate() + 0);

    let genderList = JSON.parse(localStorage.getItem('constants') as string);
    genderList['GENDERS'].forEach((element: string, key: number) => {
      this.genderOptions.push({
        name: element,
        value: element,
        checked: false,
      });
    });
    this.type = this.route.snapshot.data['action'];
    if (this.type == 'edit') {
      this.title = 'Edit User';
    } else {
      this.title = 'View User';
      this.userID = 0;
    }
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      first_name: [
        '',
        [Validators.required, this.commonService.noWhitespaceValidator],
      ],
      middle_name: ['', []],
      last_name: [
        '',
        [Validators.required, this.commonService.noWhitespaceValidator],
      ],
      date_of_birth: [null, []],
      gender: ['', [Validators.required]],
      gender_other: ['', ''],
      phone_country_code: [null, []],
      mobile: ['', []],
      email: [
        {
          value: '',
          disabled: true
        },
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]),
      ],
      roleName: [null, [Validators.required]],
      is_deactivate: [false],
    });
    this.getUserDetail();
    this.getActiveRole();
    this.getCountryPhoneCode();
  }

  /**
   * get Active Role
   * @author Dhaval Bera
   */
  getActiveRole() {
    this.roleService.getActiveRole().subscribe({
      next: (result: any) => {
        if (result.data.length > 0) {
          this.roleList = result.data;
        } else {
          this.commonService.showErrorToast('User role is not found!');
        }
      },
      error: (e) => {
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.!'
        );
      },
      complete: () => { },
    });
  }

  /**
   * @author Dhaval Bera
   *
   * This function is used to get gender dictionary
   */
  getCountryPhoneCode() {
    this.dictionaryService.getCountryList().subscribe({
      next: (result: any) => {
        this.countryCodes = result.data;
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
   * It will get  user detail
   * @author Dhaval Bera
   */
  getUserDetail() {
    this.userID = this.route.snapshot.paramMap.get('id');
    this.userService.getUserById(this.userID).subscribe({
      next: (result: any) => {
        this.userDetail = result.data;
        const role_ids: any[] = [];
        this.userDetail.roleList.forEach((res: any) => {
          role_ids.push(res.role_id);
        });
        this.genderOptions.filter((element) => {
          if (element.value == this.userDetail.gender) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });

        const mobileCountryId =
          this.userDetail.mobile_country_id === '' ||
            this.userDetail.mobile_country_id === 0
            ? null
            : this.userDetail.mobile_country_id;
        this.userForm.patchValue({
          first_name: this.userDetail.first_name,
          middle_name: this.userDetail.middle_name,
          last_name: !this.commonService.isNullOrUndefined(this.userDetail.last_name)
            ? this.userDetail.last_name
            : '',
          date_of_birth: !this.commonService.isNullOrUndefined(this.userDetail.date_of_birth)
            ? this.userDetail.date_of_birth
            : null,
          gender: this.userDetail.gender,
          gender_other: this.userDetail.gender_other,
          phone_country_code: mobileCountryId,
          mobile: this.userDetail.mobile,
          email: this.userDetail.email,
          is_deactivate: this.userDetail.is_deactivate == null ? false : this.userDetail.is_deactivate,
          roleName: role_ids,
        });
      },
      error: (err) => { },
      complete: () => { },
    });
  }

  /**
   * update User Details
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  onSubmit(data: any) {
    if (this.userForm.invalid) {
      this.commonService.validateAllFormFields(this.userForm);
      return;
    }

    let formData: FormData = new FormData();
    formData.append('first_name', this.userForm.controls['first_name'].value);
    if (!this.commonService.isNullOrUndefined(this.userForm.controls['middle_name'].value)) {
      formData.append(
        'middle_name',
        this.userForm.controls['middle_name'].value
      );
    }
    formData.append('last_name', this.userForm.controls['last_name'].value);
    if (
      !this.commonService.isNullOrUndefined(this.userForm.controls['date_of_birth'].value)
    ) {
      formData.append(
        'date_of_birth',
        this.commonService.transformDate(
          this.userForm.controls['date_of_birth'].value
        ) as unknown as Blob
      );
    }
    if (!this.commonService.isNullOrUndefined(this.userForm.controls['gender'].value)) {
      formData.append('gender', this.userForm.controls['gender'].value);
    }
    if (
      !this.commonService.isNullOrUndefined(
        this.userForm.controls['phone_country_code'].value
      )
    ) {
      formData.append(
        'mobile_country_id',
        this.userForm.controls['phone_country_code'].value
      );
    }
    if (
      !this.commonService.isNullOrUndefined(
        this.userForm.controls['phone_country_code'].value
      )
    ) {
      formData.append('mobile', this.userForm.controls['mobile'].value);
    }
    formData.append('is_deactivate', this.userForm.controls['is_deactivate'].value);
    for (const val of this.userForm.controls['roleName'].value) {
      formData.append(`role_id[]`, val);
    }
    this.userService.update(formData, this.userID).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast('User updated successfully');
          this.router.navigate(['/manage-user']);
        } else {
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      },
      error: (e) => {
        this.commonService.showErrorToast('Please enter correct values');
      },
      complete: () => { },
    });
  }


  genderChange(event: any) {
    this.setGenderChange(event.value);
  }
  setGenderChange(gender: any) {
    this.genderOptions.filter((element) => {
      if (element.value == gender) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }
  /**
  *
  * @param event
  */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
