import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constant';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { CommonService } from 'src/app/service/common.service';
import { ParticipantDocumentsService } from 'src/app/service/participant-documents.service';

@Component({
  selector: 'app-participant-documents',
  templateUrl: './participant-documents.component.html',
  styleUrls: ['./participant-documents.component.scss']
})
export class ParticipantDocumentsComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'new_pid',
    'new_recollection_id',
    'site_code',
    'name',
    'type',
    'uploaded_date',
    'action'
  ];
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/language/edit/';
  public data: any;
  public title = 'Participant Documents';
  public mappedDocumentFilters: any;
  public totalRecord: number = 1;
  public dataSource = new MatTableDataSource<any>();
  public matNoDataRowTitle: string = 'Loading Data...';

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private commonService: CommonService,
    private participantDocumentsService: ParticipantDocumentsService,
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.getAllDocuments();
  }

  /**
  * It will search Participant documents
  * @param event
  * @author Dhaval Bera
  */
  getAllDocuments() {
    this.loading = true;
    this.participantDocumentsService
      .getAllDocuments(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.mappedDocumentFilters
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.data = result.data.data.length;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
          if (this.dataSource != undefined && this.dataSource.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  /**
   * Get filtered values
   * @param event
   * @author Dhaval Bera
   */
  changeParticipantDocumentFilter(event: any) {
    if (!this.commonService.isNullOrUndefined(event)) {
      this.mappedDocumentFilters = event;
    } else {
      this.mappedDocumentFilters = '';
    }
    this.getAllDocuments();
  }

  searchFilter(filterValue: any) {
    this.filterValue = (filterValue.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllDocuments();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAllDocuments();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllDocuments();
  }

}
