import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { GenericService } from 'src/app/service/generic.service';
import { DrugCategoryService } from 'src/app/service/drug-category.service';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent implements OnInit {

  public title: any;
  public loading: Boolean = false;
  public isAddMode: boolean = true;
  public disableSubmitBtn: boolean = false;
  public userId: any = '';
  public genericId: any = '';
  public genericDetails: any = '';
  public genericForm!: FormGroup;
  public nameMinLength: number = 3;
  public buttonText: string;
  public isApproved: number = 1;
  public snomedIdList: any[] = [];
  public categoryList: any[] = [];
  public genericList: any[] = [];
  public cancelLink: string = '/dictionary-management/drug-generic';

  constructor(
    private diseaseService: DiseaseService,
    private drugCategoryService: DrugCategoryService,
    private genericService: GenericService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.genericId = this.route.snapshot.paramMap.get('id');

    if (this.genericId !== 0 && this.genericId !== null) {
      this.title = 'Edit Drug Generic';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Drug Generic';
      this.buttonText = 'Add';
      this.genericId = 0;
    }
  }

  ngOnInit(): void {
    this.getMedicineCategory();
    this.getDisease();
    if (this.genericId !== 0 && this.genericId !== null) {
      this.setEditData();
    }
    this.isAddMode = !this.genericId;
    this.userId = localStorage.getItem('userId');
    this.genericForm = this.formBuilder.group({
      generic_name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      rxcui_id: ['', [Validators.maxLength(255)]],
      category: [null, ''],
      snomed_id: [null, ''],
    });

  }

  onSubmit(data: any) {
    if (this.genericForm.invalid) {
      this.commonService.validateAllFormFields(this.genericForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append(
      'generic_name',
      this.genericForm.controls['generic_name'].value
    );
    if (
      this.genericForm.controls['rxcui_id'].value != '' &&
      this.genericForm.controls['rxcui_id'].value != null
    ) {
      formData.append('rxcui_id', this.genericForm.controls['rxcui_id'].value);
    }
    if (
        this.genericForm.controls['category'].value != '' &&
        this.genericForm.controls['category'].value != null &&
        this.genericForm.controls['category'].value.length > 0
    ) {
      formData.append('category', this.genericForm.controls['category'].value);
    }
    if (
        this.genericForm.controls['snomed_id'].value != '' &&
        this.genericForm.controls['snomed_id'].value != null &&
        this.genericForm.controls['snomed_id'].value.length > 0
    ) {
      formData.append('snomed_id', JSON.stringify(this.genericForm.controls['snomed_id'].value));
    }
    if (this.genericId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    if (this.isAddMode) {
      this.createGeneric(formData);
    } else {
      this.updateGeneric(formData);
    }
  }

  /**
   * call Create Generic api
   * @returns null
   */
  private createGeneric(formData: any): void {
    this.genericService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast(result.message);
          this.router.navigate(['/dictionary-management/drug-generic']);
        } else {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
          if (typeof result.data === 'object') {
            for (const key in result.data) {
              this.genericForm
                .get(key)!
                .setErrors({ validField: result.data[key] });
            }
          }
        }
      },
      error: (err) => {
        console.log('error from create Generic', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update Generic api
   * @returns null
   */
  private updateGeneric(formData: any): void {
    this.genericService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(result.message);
            this.router.navigate(['/dictionary-management/drug-generic']);
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err: any) => {
          this.disableSubmitBtn = false;
          console.log('error from update generic', err);
        },
      });
  }

  getMedicineCategory() {
    this.drugCategoryService.getActiveList().subscribe({
      next: (result: any) => {
        if (result.status === 1 || result.status === 200) {
          this.categoryList = result.data;
        } else {
          this.genericForm.controls['genericName'].setErrors({
            unique: 'Drug generic name already exists',
          });
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  setEditData() {
    this.genericService.getRow(this.genericId).subscribe((result: any) => {
      if (result.status !== AppConstants.serverSuccessStatus) {
        this.router.navigate(['page-not-found']);
      }
      this.genericDetails = result['data'];
      // Set Selected category
      let selected_categories: any[] = [];
      this.genericDetails.get_categories.forEach((res: any) => {
        selected_categories.push(res.get_category.id);
      });

      this.genericForm.patchValue({
        generic_name: this.genericDetails.generic_name,
        rxcui_id: this.genericDetails.rxcui_id,
        category: selected_categories,
        snomed_id: this.genericDetails.get_diseases.length > 0 ? this.genericDetails.get_diseases : null,
      });
    });
  }

  /**
   * Check generic field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueGeneric(event: any) {
    let name = (event.target as HTMLInputElement).value;
    if (name !== '' && name.length > this.nameMinLength && name.length < 255) {
      this.genericService.checkUniqueGeneric(name, this.genericId).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.genericForm.controls['generic_name'].setErrors(null);
          } else {
            this.genericForm.controls['generic_name'].setErrors({
              unique: 'Drug generic name already exists',
            });
          }
        },
        error: (error: any) => {
          // console.log(error);
          if (error.status === 400) {
            this.genericForm.controls['name'].setErrors({
              unique: 'Drug generic name already exists',
            });
          } else {
            this.commonService.showErrorToast(
              'Something went wrong. Please contact to administrator.'
            );
          }
        },
      });
    }
  }

  getDisease() {
    this.diseaseService.getActiveList().subscribe({
      next: (result: any) => {
        if (result.status === 1 || result.status === 200) {
          this.snomedIdList = result.data;
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }


}
