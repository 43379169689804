<div class="tabbing-content-wrape">
  <mat-spinner *ngIf="loading"></mat-spinner>
  <form [ngClass]="medicationFormStatus ? 'edit-mode' : 'view-mode'" id="medicationForm" [formGroup]="medicationForm">
    <!-- parent access start -->
    <div class="container-fluid">
      <div class="card-wrape">
        <div class="card-body p-b-0">
          <div class="input-wrape title-controller">
            <mat-label>Any Medication/Dietary Supplements/Traditional/Mineral/Herbal Medication? (e.g.: Protein, B12,
              D3, etc.)?</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="option">
              <mat-radio-button value="yes" (click)="resetForm('yes')">Yes</mat-radio-button>
              <mat-radio-button value="no" (click)="resetForm('no')">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <button *ngIf="medicationForm.controls['option'].value === 'yes'" mat-raised-button class="btn add-btn"
            color="secoundary" [disabled]="medicationAdd || medicationUpdate" (click)="openMedication()">Add</button>
        </div>
      </div>
    </div>

    <!-- no record found wrape start -->
    <div class="No record" class="no-record-pad" *ngIf="medicationForm.controls['option'].value === 'no'">
      <img src="../../../../../../assets/images/edit-participant-no-data/medical-history-no-data.svg" alt="Medication">
      <span>No participant medication found</span>
    </div>
    <!-- no record found wrape end -->

    <!-- popup code start  *ngIf="mhSelectwrape == 'Yes'"-->
    <section class="popup-wrape success" [ngClass]="togglepopup ? 'success' : 'danger'"
      [formGroup]="medicationDetailForm">
      <div class="overlays"></div>
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text"> Medication</h4>
          <a href="javascript:;" class="popup-close-btn" (click)="closePopup()">×</a>
        </div>
        <div class="card-body">
          <div class="input-wrape" [ngClass]="displayFieldCss('medication_name')">
            <mat-label><i>*</i>Medication Name:</mat-label>
            <div class="control-pad">
              <ng-select [addTag]="true" (keyup)="searchDrugs($event.target)" placeholder="Enter medication name"
                formControlName="medication_name" [loading]="loading">
                <ng-option *ngFor="let medication of medications"
                  [value]="medication.medicine_name">{{medication.medicine_name | titlecase}}</ng-option>
              </ng-select>
            </div>
            <!-- <mat-form-field class="example-full-width" appearance="fill" class="no-padding">
              <input [ngClass]="displayFieldCss('medication_name')" type="text" placeholder="Enter medication name"
                aria-label="Number" matInput formControlName="medication_name" [matAutocomplete]="autoMedication">
              <mat-autocomplete autoActiveFirstOption #autoMedication="matAutocomplete">
                <mat-option *ngFor="let option of medicationFilteredOptions | async" [value]="option.medicine_name">
                  {{option.medicine_name | titlecase}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-error *ngIf="isFieldValid('medication_name')">
              Enter medication name
            </mat-error>
          </div>
        </div>
        <div class="card-footer">
          <button mat-raised-button class="btn"
            [disabled]="medicationDetailForm.controls['medication_name'].value == null" *ngIf="!medicationUpdate"
            color="secoundary" (click)="addMedication()">Add</button>
          <button mat-raised-button class="btn"
            [disabled]="medicationDetailForm.controls['medication_name'].value == null" *ngIf="medicationUpdate"
            color="secoundary" (click)="updateMedication()">Update</button>
        </div>
      </div>
    </section>
    <!-- popup code end -->

    <!-- Table start -->
    <div class="container-fluid" *ngIf="medicationForm.controls['option'].value === 'yes'">
      <div class="row">
        <div class="col-xl-12">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Medication ID </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="100px" [style.display]="'none'"> {{element.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="medication_name">
              <mat-header-cell *matHeaderCellDef> Medication Name </mat-header-cell>
              <mat-cell *matCellDef="let element;">
                {{element.medication_name | titlecase}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
              <mat-cell *matCellDef="let element;let i = index;">
                <div class="icons-wrape">
                  <a (click)="editMedication(i)" class="theme-icon edit-icon" title="Edit Medication"></a>
                  <a (click)="medicationDelete(i)" class="theme-icon delete-icon" title="Delete Medication"></a>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <div *matNoDataRow>No records found</div>
          </mat-table>
        </div>
      </div>
    </div>
    <!-- Table end -->

    <div class="stic-form-btn-wrape">
      <button type="button" (click)="clickOnEdit()" *ngIf="!medicationFormStatus" mat-raised-button class="btn"
        color="secoundary">Edit</button>
      <button type="button" (click)="clickOnCancel()" *ngIf="medicationFormStatus" mat-raised-button class="btn"
        color="warn">Cancel</button>
      <button type="button" (click)="medicationSubmit()" [disabled]="(!medicationAdd && !medicationUpdate) || loading"
        *ngIf="medicationFormStatus" mat-raised-button class="btn" color="secoundary">Save</button>
    </div>
  </form>
</div>
