import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { ParticipantService } from 'src/app/service/participant.service';

@Component({
  selector: 'app-clinical-biochemical-data',
  templateUrl: './clinical-biochemical-data.component.html',
  styleUrls: ['./clinical-biochemical-data.component.scss']
})
export class ClinicalBiochemicalDataComponent implements OnInit {

  public loading: boolean = false;
  public statusTableForm: boolean = false;
  public disableTableForm: boolean = true;
  public enableTableForm: boolean = false;
  public cbdAdd: boolean = false;
  public cbdUpdate: boolean = false;
  public participantID: any;
  public cbdForm!: FormGroup;
  public unitListH: any;
  public unitListW: any;
  public unitListBP: any;
  public unitListHBP: any;
  public heightMsg: string = '';
  public weightMsg: string = '';
  public validateMsg: string = '';
  public isRequiredDiseases: any = [];

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder,
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');

    this.getUnitDictionary();
  }

  ngOnInit(): void {
    this.getCBIDetails();
    this.cbdForm = this.formBuilder.group({
      height: [null, [Validators.required, customHeightValidation(this, "height", "unitHeight")]],
      unitHeight: [null, [Validators.required, customHeightValidation(this, "height", "unitHeight")]],
      weight: [null, [Validators.required, customWeightValidation(this, "weight", "unitWeight")]],
      unitWeight: [null, [Validators.required, customWeightValidation(this, "weight", "unitWeight")]],
      blood_pressure_systolic: [null, [validateBloodUnit(this)]],
      blood_pressure_diastolic: [null, [validateBloodUnit(this)]],
      unitBP: [null, [validateBloodUnit(this)]],
    });
    this.getParticipantDiseasesList();
  }

  getCBIDetails() {
    this.loading = true;
    this.participantService.getCBIDetails(this.participantID).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.cbdForm.patchValue({
          height: result.data.height,
          unitHeight: result.data.height_unit,
          weight: result.data.weight,
          unitWeight: result.data.weight_unit,
          blood_pressure_systolic: result.data.high_blood_pressure_systolic,
          blood_pressure_diastolic: result.data.low_blood_pressure_diastolic,
          unitBP: result.data.blood_pressure_unit,
        });
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }
  getUnitDictionary() {
    this.loading = true;
    this.dictionaryService.getUnitDictionary().subscribe({
      next: (result: any) => {
        this.loading = false;
        this.unitListH = result.data.height;
        this.unitListW = result.data.weight;
        this.unitListBP = result.data.blood_pressure;
        this.unitListHBP = result.data.blood_pressure_2;
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }

  submitCbdInfoForm() {
    if (this.cbdForm.invalid) {
      this.commonService.validateAllFormFields(this.cbdForm);
      return;
    }
    this.loading = true;
    this.participantService
      .updateCBIDetails(this.cbdForm.value, this.participantID)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast("Participant updated successfully");
            this.cbdForm.markAsPristine();
            this.statusTableForm = !this.statusTableForm;
            this.disableTableForm = true;
            this.enableTableForm = false;
          } else {
            this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          this.loading = false;
        },
      });

  }

  clickEventTableForm() {
    this.statusTableForm = !this.statusTableForm;
    this.disableTableForm = false;
    this.enableTableForm = true;
    this.cbdAdd = true;
    this.cbdUpdate = false;
  }

  cancelEventTableForm() {
    this.statusTableForm = !this.statusTableForm;
    this.disableTableForm = true;
    this.enableTableForm = false;
    this.cbdAdd = false;
    this.cbdUpdate = false;
  }

  /**
  * This function is used to get disease list
  */
  getParticipantDiseasesList() {
    this.participantService.getParticipantDiseasesList(this.participantID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          let requiredDiseases = ['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'];
          if (result.data.length > 0) {
            result.data.forEach((disease: any) => {
              if (disease.category != null) {
                let requiredD = requiredDiseases.find(temp => temp == disease.category.slug);
                if (requiredD !== undefined) {
                  this.isRequiredDiseases.push(requiredD);
                }
              }
            });
          }
          if (this.isRequiredDiseases.length > 0) {
            this.commonService.validateFieldBasedOnDisease("blood_pressure_systolic", this.cbdForm, [Validators.required]);
            this.commonService.validateFieldBasedOnDisease("blood_pressure_diastolic", this.cbdForm, [Validators.required]);
          }

        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
  * @author Dhaval Bera
  * @param field
  */
  displayFieldCss(field1: string, field2: string = '') {
    let field = field1;
    if (field2 != '') {
      return {
        "has-error": this.isFieldValid(field1) || this.isFieldValid(field2)
      };
    }
    return {
      "has-error": this.isFieldValid(field)
    };
  }
  /**
  * @author Dhaval Bera
  * This function is used to submit Form Details
  * @param field
  */
  isFieldValid(field: string) {
    return (
      (this.cbdForm.controls[field].invalid && this.cbdForm.controls[field].dirty) ||
      (this.cbdForm.controls[field].touched && this.cbdForm.controls[field].invalid)
    );
  }

}
export function customHeightValidation(data: any, fieldName: string, fieldUnit: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (control) {
      const fieldValue = control.root.get(fieldName);
      const fieldUnitValue = control.root.get(fieldUnit);
      if (fieldValue != null) {
        if (data.commonService.isNullOrUndefined(fieldUnitValue?.value) || fieldUnitValue?.value === "") {
          data.cbdForm.controls["unitHeight"].setValue("CM");
        }
        if (!fieldValue.value) {
          data.heightMsg = "Height is required";
          return { hfieldRequired: true };
        }
        if (data.unitListH !== undefined) {
          const range = data.unitListH.find((x: any) => x.measure === fieldUnitValue?.value);
          if (range == undefined && fieldValue.value !== "" && fieldValue.value !== null && !fieldUnitValue?.value) {
            data.heightMsg = "Please select height unit";
            return { hfieldRequired: true };
          } else if (range == undefined && (fieldValue.value === "" || fieldValue.value === null)) {
            fieldValue.setErrors(null);
            fieldUnitValue?.setErrors(null);
            data.heightMsg = "";
            return null;
          } else {
            if (data.unitListH === undefined) {
              fieldValue.setErrors(null);
              fieldUnitValue?.setErrors(null);
              data.heightMsg = "";
              return null;
            }

            const min_range = range.min_range;
            const max_range = range.max_range;

            if (fieldValue.value < min_range) {
              data.heightMsg = "Please enter a valid value between " + min_range + " - " + max_range + "";
              return { hfieldRequired: data.heightMsg };
            } else if (fieldValue.value > max_range) {
              data.heightMsg = "Please enter a valid value between " + min_range + " - " + max_range + "";
              return { hfieldRequired: data.heightMsg };
            } else {
              fieldValue.setErrors(null);
              fieldUnitValue?.setErrors(null);
              data.heightMsg = "";
              return null;
            }
          }
        }
      }
    }
    return null
  };
}

export function customWeightValidation(data: any, fieldName: string, fieldUnit: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control) {
      const fieldValue = control.root.get(fieldName);
      const fieldUnitValue = control.root.get(fieldUnit);
      if (fieldValue) {
        if (data.commonService.isNullOrUndefined(fieldUnitValue?.value) || fieldUnitValue?.value === "") {
          data.cbdForm.controls["unitWeight"].setValue("Kg");
        }

        if (!fieldValue.value) {
          data.weightMsg = "Weight is required";
          return { wfieldRequired: true };
        }
        if (data.unitListW !== undefined) {
          const range = data.unitListW.find((x: any) => x.measure === fieldUnitValue?.value);
          if (range == undefined && fieldValue.value !== "" && fieldValue.value !== null && !fieldUnitValue?.value) {
            data.weightMsg = "Please select weight unit";
            return { wfieldRequired: true };
          } else if (range == undefined && (fieldValue.value === "" || fieldValue.value === null)) {
            fieldValue.setErrors(null);
            fieldUnitValue?.setErrors(null);
            data.weightMsg = "";
            return null;
          } else {
            if (data.unitListW === undefined) {
              fieldValue.setErrors(null);
              fieldUnitValue?.setErrors(null);
              data.weightMsg = "";
              return null;
            }

            const min_range = range.min_range;
            const max_range = range.max_range;

            if (fieldValue.value < min_range) {
              data.weightMsg = "Please enter a valid value between " + min_range + " - " + max_range + "";
              return { wfieldRequired: true };
            } else if (fieldValue.value > max_range) {
              data.weightMsg = "Please enter a valid value between " + min_range + " - " + max_range + "";
              return { wfieldRequired: true };
            } else {
              fieldValue.setErrors(null);
              fieldUnitValue?.setErrors(null);
              data.weightMsg = "";
              return null;
            }
          }
        }
      }
    }
    return null
  };
}


function validateBloodUnit(data: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control) {
      const lowValue = control.root.get("blood_pressure_diastolic");
      const highValue = control.root.get("blood_pressure_systolic");
      const fieldUnitValue = control.root.get("unitBP");
      if (fieldUnitValue != null && data.commonService.isNullOrUndefined(fieldUnitValue?.value) || fieldUnitValue?.value === "") {
        data.cbdForm.controls["unitBP"].setValue("mmHg");
      }
      if (data.unitListBP != undefined && lowValue && highValue) {

        const range = data.unitListBP.find((x: any) => x.measure === fieldUnitValue?.value);
        const unitListHBP = data.unitListHBP.find((x: any) => x.measure === fieldUnitValue?.value);

        if (range == undefined && lowValue.value === "" && highValue.value === "") {
          lowValue.setErrors(null);
          highValue.setErrors(null);
          fieldUnitValue?.setErrors(null);
          data.validateMsg = "";
          return null;
        }

        if (range == undefined && (lowValue.value !== "" || highValue.value !== "") && !fieldUnitValue?.value) {
          data.validateMsg = "Please select BP unit";
          return { fieldRequired: true };
        }

        if (data.unitListBP === undefined) {
          lowValue.setErrors(null);
          highValue.setErrors(null);
          fieldUnitValue?.setErrors(null);
          data.validateMsg = "";
          return null;
        }
        const min_range = unitListHBP.min_range;
        const max_range = unitListHBP.max_range;

        const low_min_range = range.min_range;
        const low_max_range = range.max_range;

        if (parseFloat(highValue.value) < parseFloat(min_range)) {
          data.validateMsg = "Please enter high BP a valid value between " + min_range + " - " + max_range + "";
          return { fieldRequired: true };
        } else if (parseFloat(highValue.value) > parseFloat(max_range)) {
          data.validateMsg = "Please enter high BP a valid value between " + min_range + " - " + max_range + "";
          return { fieldRequired: true };
        } else if (parseFloat(lowValue.value) < parseFloat(low_min_range)) {
          data.validateMsg = "Please enter low BP a valid value between " + low_min_range + " - " + low_max_range + "";
          return { fieldRequired: true };
        } else if (parseFloat(lowValue.value) > parseFloat(low_max_range)) {
          data.validateMsg = "Please enter low BP a valid value between " + low_min_range + " - " + low_max_range + "";
          return { fieldRequired: true };
        } else if (parseFloat(highValue.value) < parseFloat(lowValue.value)) {
          data.validateMsg = "Low BP less then high BP";
          return { fieldRequired: true };
        } else {
          lowValue.setErrors(null);
          highValue.setErrors(null);
          fieldUnitValue?.setErrors(null);
          data.validateMsg = "";
          return null;
        }
      }
    }
    return null;
  };
}
