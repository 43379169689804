import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BioBankSequencingService } from 'src/app/service/bio-bank-sequencing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bio-bank-sequencing',
  templateUrl: './bio-bank-sequencing.component.html',
  styleUrls: ['./bio-bank-sequencing.component.scss']
})
export class BioBankSequencingComponent implements OnInit {

  public title = 'Biobank and Sequencing Statistics';
  public bioBankSequencingCounts: any = [];
  public yearArray: any = [0];
  public totalYears: any = 11;
  public loading: boolean = false;
  public baseUrl = `${environment.baseURL}` + 'bio-bank-sequencing/';

  constructor(
    private bioBankSequencingService: BioBankSequencingService,
    protected router: Router,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getBioBankSequencingCounts(0);
    let countTotalYears = 0;
    for (let i = new Date().getFullYear(); i >= 2014; i--) {
      countTotalYears++;
      this.yearArray.push(i);
      this.getBioBankSequencingCounts(i);
    }
    this.totalYears = countTotalYears + 2;
  }

  /**
 * This function is used to get BioBank Sequencing Counts
 */
  getBioBankSequencingCounts(year: number) {
    this.bioBankSequencingService.getAllBioBank(year).subscribe({
      next: (result: any) => {
        this.bioBankSequencingCounts[year] = result.data;
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }
  downloadBioBank(year: number, questionKey: string) {
    this.loading = true;
    this.bioBankSequencingService.downloadBioBank(year, questionKey).subscribe({
      next: (result: any) => {
        console.log(result);
        const file = new Blob([result.data.body], { type: 'text/csv' });
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = result.data.file_name;
        link.click();
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
