<!-- Cancer que section start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="CANCER">
        <div class="card-head">
            <h4 class="left-text">Cancer Details</h4>
        </div>
        <div class="card-body">
            <div class="col-lg-6">
                <div class="cancer-detail-que">
                    <div class="input-wrape">
                        <mat-label>Secondary Site (If Known):</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput #input placeholder="Enter secondary site" type="text"
                                formControlName="secondary_site">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Cancer que section end -->