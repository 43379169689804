import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-curation-notes',
  templateUrl: './curation-notes.component.html',
  styleUrls: ['./curation-notes.component.scss']
})
export class CurationNotesComponent implements OnInit {

  public PID: any;
  public curationNotesDetails: any;
  public curationNotesForm!: FormGroup;
  public curationNotesArray!: FormArray;
  public curationNotesFormStatus: boolean = false;
  public curationNotesAdd: boolean = false;
  public curationNotesTypes: any;
  public showLoading: boolean = false;
  togglepopup: boolean = false;

  constructor(
    private participantService: ParticipantService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.curationNotesForm = this.formBuilder.group({
      notes: ['', [Validators.required]],
    });
    this.getCurationNotes();
  }

  /**
   * @author Farhan Shaikh
   * This function is used to get Curation Notes and set Value
   */
  getCurationNotes() {
    this.showLoading = true;
    this.participantService.getCurationNotes(this.PID).subscribe({
      next: (result: any) => {
        this.curationNotesDetails = result.data;
        this.showLoading = false;
      }, error: (e) => {
        this.showLoading = false;
        console.log('Curation Notes err :: ', e);
      }
    })
  }

  /**
  * @author Farhan Shaikh
  * Enable form edit 
  */
  clickOnEdit() {
    this.curationNotesFormStatus = true;
    this.curationNotesAdd = true;
  }

  /**
  * @author Farhan Shaikh
  * Enable form Close 
  */
  clickOnCancel() {
    this.curationNotesFormStatus = false;
    this.curationNotesAdd = false;
    this.curationNotesForm.reset();
  }

  /**
 * @author Farhan Shaikh
 * This function is used to submit form to server side
 */
  curationNotesSubmit() {
    this.showLoading = true;
    const formData: FormData = new FormData();
    if (this.curationNotesForm.invalid) {
      this.commonService.validateAllFormFields(this.curationNotesForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      this.showLoading = false;
      return;
    }
    formData.append('notes', this.curationNotesForm.controls['notes'].value);
    this.curationNotesForm.controls['notes'].setValue(null);
    this.participantService.updateCurationNotes(formData, this.PID).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Curation Notes updated successfully.', icon: 'success', });
          this.getCurationNotes();
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.showLoading = false;
        this.curationNotesFormStatus = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.showLoading = false;
        this.curationNotesForm.reset();
      },
      complete: () => {
        this.showLoading = false;
        this.curationNotesForm.reset();

      },
    });
  }

  /**
   * @author Farhan Shaikh
   * This function is used to reset Form 
   * @param option 
   */
  resetForm(option: string) {
    console.log(option);
    this.curationNotesAdd = true;
    this.togglepopup = !this.togglepopup;
  }
}

