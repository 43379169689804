<div class="page-title-box">
    <h1>Camp Data Verification</h1>
    <ul class="bradcrumb">
      <li>Camp Data Verification</li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card-wrape">
            <div class="card-head">
              <h4 class="left-text">Camp Data Verification</h4>
              <div class="right-control">
                  <mat-checkbox class="checkbox-pad" (click)="toggleHiddenData()" (checked)="is_hidden">Is Hidden</mat-checkbox>
                  <div class="table-search-wrape side-info">
                  <span class="icon"></span>
                  <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
                  <div class="info-pad popup-over">
                    <button class="info-icon"></button>
                    <div class="info-section popupcontent"> PID
                        <!-- {{filterColumns}} -->
                    </div>
                  </div>
                </div>
                  <div class="calendar-pad">
                      <div class="input-wrape calendar-wrape">
                        <mat-label>Collection Start Date:</mat-label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput [matDatepicker]="pickerStartDate" 
                          [value]="startDate" 
                          [min]="minDate"
                          placeholder="Select date" 
                          (click)="pickerStartDate.open()"
                          (dateChange)="compareTwoDates('startDate', $event)"
                        >
                        <mat-datepicker-toggle matSuffix [for]="pickerStartDate">
                          <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerStartDate></mat-datepicker>
                        <button *ngIf="startDate" type="button" class="fa fa-clear-icons clear-button-calender" (click)="clearDate('startDate')">×</button>
                      </mat-form-field>
                    </div>
                    <div class="input-wrape calendar-wrape">
                        <mat-label>End Date</mat-label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput [matDatepicker]="pickerEndDate" 
                            [value]="endDate" 
                            placeholder="Select date" 
                            (click)="pickerEndDate.open()"
                            (dateChange)="compareTwoDates('endDate', $event)"
                        >
                        <mat-datepicker-toggle matSuffix [for]="pickerEndDate">
                          <mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEndDate></mat-datepicker>
                        <button *ngIf="endDate" type="button" class="fa fa-clear-icons clear-button-calender" (click)="clearDate('endDate')">×</button>
                      </mat-form-field>
                    </div>
                  </div>              
                <a (click)="refreshParticipantData()" mat-raised-button class="custom-btn">Refresh</a>
              </div>
  
            </div>
            <mat-spinner *ngIf="loading"></mat-spinner>
            <div class="card-body table-section">
              <div class="table-wrape">
                <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortFilter($event)">
                  <ng-container matColumnDef="participant_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="sr_no"> PID </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="sr_no" fxFlex="100px"> <a href="javascript:;" routerLink="/participant/edit/{{element.participant_id}}"
                      target="_blank" title="View Participant">{{element.new_pid}}</a> </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="collection_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Collection Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.collection_date}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="disease_question">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Disease's Images </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.disease_question | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="barcode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.barcode | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="pmr">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> PMRs </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.pmr | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="profile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> PC - Profile </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.profile | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="pc_signature">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> PC - Signatures </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.pc_signature | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="icf_form">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> PC - ICF Form </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.icf_form | titlecase}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="cv_signature">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> CV - Signature </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.cv_signature | titlecase}} </mat-cell>
                  </ng-container>
  
                  <!-- <ng-container matColumnDef="audio_file">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Audio File </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.audio_file}} </mat-cell>
                  </ng-container> -->
  
                  <ng-container matColumnDef="comment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Comment </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="input-wrape">
                            <mat-form-field class="example-full-width m-b-0" appearance="outline">
                                <textarea matInput 
                                  name="comment"
                                  rows="2"
                                  (change)="storeComment($event,element.participant_id)" 
                                >{{element.comment}}</textarea>
                            </mat-form-field>
                        </div>
                    </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="action" fxFlex="140px"> 
                      <div class="icons-wrape">
                        <!-- <a href="javascript:;" class="theme-icon sync-icon" title="Refresh"></a>
                        <a href="javascript:;" class="theme-icon view-icon" title="Hide"></a> -->
                        <a (click)="refreshParticipantData(element.participant_id)" title="Refresh"
                          class="theme-icon sync-icon">
                        </a>


                        <a (click)="toggleIsHiddenValue(element.is_hidden,element.participant_id)"
                          title="{{element.is_hidden?'Unhide':'Hide'}}"
                          class="theme-icon {{element.is_hidden?'hide-icon':'view-icon'}}">
                        </a>
                      </div>
                    </mat-cell>
                  </ng-container>
  
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  <div *matNoDataRow>{{matNoDataRowTitle}}</div>
                </mat-table>
  
                <!-- Pagination -->
                <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage"
                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                    (page)="pageChanged($event)" showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>