import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DrugBrandService } from 'src/app/service/drug-brand.service';
import { GenericService } from 'src/app/service/generic.service';
import { TempBrandGenericService } from 'src/app/service/temp-brand-generic.service';
import { DrugCategoryService } from 'src/app/service/drug-category.service';

@Component({
  selector: 'app-temp-brand-generic-form',
  templateUrl: './temp-brand-generic-form.component.html',
  styleUrls: ['./temp-brand-generic-form.component.scss'],
})
export class TempBrandGenericFormComponent implements OnInit {
  public title: any;
  public loading: Boolean = false;
  public userId: any = '';
  public tempBrandGenericForm!: FormGroup;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public cancelLink: string =
    '/dictionary-management/temp-brand-generic/curation-pending';
  public buttonText: string;
  public tempBrandGenericId: any = '';
  public mapWithBrand: any = 'brand';
  public isBrand: any = 'brand';
  public selectMapOption: number = 1;
  public approvedDictionary: boolean = true;
  public disableSubmitBtn: boolean = false;
  public nameMinLength: number = 3;

  public snomedIdList: any[] = [];
  public genericList: any[] = [];
  public categoryList: any[] = [];
  public brandList: any[] = [];

  constructor(
    private tempBrandGenericService: TempBrandGenericService,
    private diseaseService: DiseaseService,
    private genericService: GenericService,
    private drugBrandService: DrugBrandService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router,
    private drugCategoryService: DrugCategoryService
  ) {
    this.tempBrandGenericId = this.route.snapshot.paramMap.get('id');
    this.title = 'Curate Drug Brand / Generic / Category';
    this.buttonText = 'Save';
  }

  ngOnInit(): void {
    this.loading = true;
    this.userId = localStorage.getItem('userId');
    this.tempBrandGenericForm = this.formBuilder.group({
      medicineName: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      isApproved: ['1', [Validators.required]],
      isBrandOrGeneric: ['brand', ''],
      mapWithBrandOrGeneric: ['brand', ''],
      category: [null, []],
      rxcuiId: ['', [Validators.maxLength(255)]],
      generic: [null, ''],
      mapWithGeneric: [null, []],
      mapWithBrands: [null, []],
      mapWithCategories: [null, []],
      disease: [null, []],
    });
    // Fetch medicine brand record by medicine brand id
    this.tempBrandGenericService
      .getRow(this.tempBrandGenericId)
      .subscribe((result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(['page-not-found']);
        }
        this.tempBrandGenericForm.patchValue({
          medicineName: result.data.medicine_name,
        });
        this.loading = false;
      });

  }

  /**
   * Validate medicine brand records
   *
   * @returns object
   */
  onSubmit() {
    if (this.tempBrandGenericForm.controls['isApproved'].value == 1) {
      this.tempBrandGenericForm.controls['mapWithBrands'].setValidators(null);
      this.tempBrandGenericForm.controls['mapWithBrands'].updateValueAndValidity();

      this.tempBrandGenericForm.controls['mapWithCategories'].setValidators(null);
      this.tempBrandGenericForm.controls['mapWithCategories'].updateValueAndValidity();

      this.tempBrandGenericForm.controls['mapWithGeneric'].setValidators(null);
      this.tempBrandGenericForm.controls['mapWithGeneric'].updateValueAndValidity();

      this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].setValidators(null);
      this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].updateValueAndValidity();
    } else {
      if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'brand') {
        this.tempBrandGenericForm.controls['mapWithBrands'].setValidators(Validators.required)
        this.tempBrandGenericForm.controls['mapWithBrands'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithGeneric'].setValidators(null);
        this.tempBrandGenericForm.controls['mapWithGeneric'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithCategories'].setValidators(null)
        this.tempBrandGenericForm.controls['mapWithCategories'].updateValueAndValidity();
      } else if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'generic') {

        this.tempBrandGenericForm.controls['mapWithBrands'].setValidators(null)
        this.tempBrandGenericForm.controls['mapWithBrands'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithGeneric'].setValidators(Validators.required);
        this.tempBrandGenericForm.controls['mapWithGeneric'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithCategories'].setValidators(null)
        this.tempBrandGenericForm.controls['mapWithCategories'].updateValueAndValidity();

      } else {

        this.tempBrandGenericForm.controls['mapWithBrands'].setValidators(null);
        this.tempBrandGenericForm.controls['mapWithBrands'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithGeneric'].setValidators(null)
        this.tempBrandGenericForm.controls['mapWithGeneric'].updateValueAndValidity();

        this.tempBrandGenericForm.controls['mapWithCategories'].setValidators(Validators.required);
        this.tempBrandGenericForm.controls['mapWithCategories'].updateValueAndValidity();
      }
    }

    const formData: FormData = new FormData();
    if (this.tempBrandGenericForm.invalid) {
      if (!this.tempBrandGenericForm.controls['isApproved'].value) {

        if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'brand') {
          this.tempBrandGenericForm.controls['mapWithBrands'].markAsTouched();
          this.commonService.showErrorToast('Please enter correct values');
          return;

        } else if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'category') {
          this.tempBrandGenericForm.controls['mapWithCategories'].markAsTouched();
          this.commonService.showErrorToast('Please enter correct values');
          return;

        } else {
          this.tempBrandGenericForm.controls['mapWithGeneric'].markAsTouched();
          this.commonService.showErrorToast('Please enter correct values');
          return;

        }
      }

      this.tempBrandGenericForm.controls['isBrandOrGeneric'].markAsTouched();
      this.commonService.showErrorToast('Please enter correct values');
      return;
    }
    formData.append('medicine_name', this.tempBrandGenericForm.controls['medicineName'].value);
    formData.append('is_approved', this.tempBrandGenericForm.controls['isApproved'].value);
    if (this.tempBrandGenericForm.controls['isApproved'].value == 1) {

      formData.append('is_brand_or_generic', this.tempBrandGenericForm.controls['isBrandOrGeneric'].value);
      if (
        this.tempBrandGenericForm.controls['disease'].value != '' &&
        this.tempBrandGenericForm.controls['disease'].value != null &&
        this.tempBrandGenericForm.controls['disease'].value.length > 0
      ) {
        formData.append('disease', JSON.stringify(this.tempBrandGenericForm.controls['disease'].value));
      }

      if (this.isBrand == 'brand') {
        if (
          this.tempBrandGenericForm.controls['generic'].value != '' &&
          this.tempBrandGenericForm.controls['generic'].value != null &&
          this.tempBrandGenericForm.controls['generic'].value.length > 0
        ) {
          
          formData.append('generic', this.tempBrandGenericForm.controls['generic'].value);
        }
      } else if (this.isBrand == 'generic') {
        formData.append('rxcuiId', this.tempBrandGenericForm.controls['rxcuiId'].value);
        console.log(this.tempBrandGenericForm.controls['category'].value);
        
        if (
          this.tempBrandGenericForm.controls['category'].value != '' &&
          this.tempBrandGenericForm.controls['category'].value != null &&
          this.tempBrandGenericForm.controls['category'].value.length > 0
        ) {
          formData.append('category', this.tempBrandGenericForm.controls['category'].value);
        }
      }

    } else {
      formData.append('mapWithBrandOrGeneric', this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value);

      if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'brand') {
        formData.append('mapWithBrands', this.tempBrandGenericForm.controls['mapWithBrands'].value);
      } else if (this.tempBrandGenericForm.controls['mapWithBrandOrGeneric'].value == 'category') {
        formData.append('mapWithCategories', this.tempBrandGenericForm.controls['mapWithCategories'].value);
      } else {
        formData.append('mapWithGeneric', this.tempBrandGenericForm.controls['mapWithGeneric'].value);
      }
    }
    formData.append('updated_by', this.userId);
    console.log(formData);
    this.loading = true;
    this.disableSubmitBtn = true;
    this.tempBrandGenericService.update(formData, parseInt(this.tempBrandGenericId))
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast('Brand/Generic/Category Drug validated successfully');
            this.router.navigate(['/dictionary-management/temp-brand-generic/curation-pending']);
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
          }
          this.loading = false;
        },
        error: (error) => {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast('Please enter correct values');
          this.loading = false;
        },
      });
  }

  changeMapWith(value: any) {
    this.mapWithBrand = value;
  }

  changeMedicationType(value: string) {
    this.isBrand = value;
    this.tempBrandGenericForm.patchValue({
      generic: '',
      rxcuiId: '',
    });
  }

  selectOption(value: number) {
    this.selectMapOption = value;
    if (value == 0) {
      this.approvedDictionary = false;
      this.tempBrandGenericForm.controls['isBrandOrGeneric'].setValidators(
        null
      );
    } else {
      this.approvedDictionary = true;
      this.tempBrandGenericForm.controls['isBrandOrGeneric'].setValidators(
        Validators.required
      );
    }
    this.tempBrandGenericForm.controls[
      'isBrandOrGeneric'
    ].updateValueAndValidity();
  }

  getBrand(event: any) {
    let fieldValue = event.term;
    if(
      fieldValue !== undefined && fieldValue.length >= 2
    ){
      this.loading = true;
      this.drugBrandService
        .getActiveList(fieldValue)
        .subscribe((result: any) => {
          this.loading = false;
          if (result.status === 1 || result.status === 200) {
            this.brandList = result.data;
            if (result.data.length == 0) {
              this.commonService.showErrorToast('Brand is not available, try another.');
              this.tempBrandGenericForm.controls['mapWithBrands'].setValue(null);
            }
          }
        });
    }
  }

  getMedicineCategory(event: any) {
    let fieldValue = event.term;
    if (fieldValue !== undefined && fieldValue.length >= 2) {
      this.loading = true;
      this.drugCategoryService.getActiveList(fieldValue).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.categoryList = result.data;
            if (result.data.length == 0) {
              this.commonService.showErrorToast('Category is not available, try another.');
              this.tempBrandGenericForm.controls['category'].setValue(null);
              this.tempBrandGenericForm.controls['mapWithCategories'].setValue(null);
            }
            this.loading = false;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    }
  }

  getDisease(event: any) {
    let fieldValue = event.term;
    if (fieldValue !== undefined && fieldValue.length >= 2) {
      this.loading = true;
      this.diseaseService.getActiveList(fieldValue).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.snomedIdList = result.data;
            if (result.data.length == 0) {
              this.commonService.showErrorToast('Disease is not available, try another.');
              this.tempBrandGenericForm.controls['disease'].setValue(null);
            }
            this.loading = false;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    }
  }

  getGeneric(event: any) {
    let fieldValue = event.term;
    if (fieldValue !== undefined && fieldValue.length >= 2) {
      this.loading = true;
      this.genericService.getActiveList(fieldValue).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.genericList = result.data;
            if (result.data.length == 0) {
              this.commonService.showErrorToast('Generic is not available, try another.');
              this.tempBrandGenericForm.controls['generic'].setValue(null);
              this.tempBrandGenericForm.controls['mapWithGeneric'].setValue(null);
            }
            
            this.loading = false;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    }
  }
}
