<div class="tabbing-content-wrape">
    <form [ngClass]="curationNotesFormStatus ? 'edit-mode' : 'view-mode'" id="curationNotesForm" [formGroup]="curationNotesForm">
        <!-- popup code start -->
        <section class="popup-wrape success" [ngClass]="togglepopup ? 'success' : 'danger'"
            [formGroup]="curationNotesForm">
            <div class="overlays"></div>
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text"> Curation Notes</h4>
                </div>
                <div class="card-body">
                    <mat-spinner *ngIf="showLoading"></mat-spinner>
                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.curationNotesForm, 'notes')">
                        <mat-label>Curation Notes:</mat-label>   
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput formControlName="notes" rows="500" placeholder="Enter curation notes" maxlength="3000"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="curationNotesDetails?.length == '0'">
                    <div class="no-data-found">
                      <div class="no-data-container">
                        <div class="no-data-icon"></div>
                        <div class="no-data-text">No Curation Note(s) Found</div>
                      </div>
                    </div>
                </div>
                <div class="row card-head" *ngIf="curationNotesDetails?.length != '0'">
                    <div class="col-md-12">
                    <div class="times-wrape">
                        <h4>Curation Notes History</h4>
                        <ul class="timestamp">
    
                        <li *ngFor="
                                    let note of curationNotesDetails;
                                    index as i
                                    ">
                            <p class="note-text">{{note.notes}}</p>
                            <span class="bottom-wrape">
                            <p class="user-name">By: {{note.first_name}} {{note.last_name}}</p>
                            <p class="date" *ngIf="note.updated_at">On: {{note.updated_at | date: 'dd-MMM-yyyy HH:mm:ss'}}</p>
                            </span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- popup code end -->

        <div class="stic-form-btn-wrape">
            <button type="button" (click)="clickOnEdit()" *ngIf="!curationNotesFormStatus" mat-raised-button class="btn"
                color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="curationNotesFormStatus" mat-raised-button class="btn"
                color="warn">Cancel</button>
            <button type="button" (click)="curationNotesSubmit()" [disabled]="!curationNotesAdd" *ngIf="curationNotesFormStatus" mat-raised-button class="btn"
                color="secoundary">Save</button>
        </div>
    </form>
</div>