<section class="my-profile-wrape">
	<div class="page-title-box">
		<h1>Site Dashboard</h1>
		<ul class="bradcrumb">
			<li>Site Dashboard</li>
			<li><a routerLink="/site" title="site" class="only-link" (click)="redirectSitePage()">Manage Site</a></li>
			<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
		</ul>
	</div>
</section>

<section class="container-fluid">
	<div class="row">
		<mat-spinner *ngIf="siteDetailLoader"></mat-spinner>
		<div class="col-xl-12 col-md-12 col-sm-12" *ngIf="siteDetail">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{siteDetail?siteDetail.site_name:""}} -
						{{siteDetail?siteDetail.site_code:""}}</h4>
				</div>
			</div>
		</div>

		<div class="col-xl-6 col-md-6">
			<app-disease-chart></app-disease-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-participant-count-chart></app-participant-count-chart>
		</div>
		<div class="col-xl-4 col-md-4">
			<app-population-chart></app-population-chart>
		</div>
		<div class="col-xl-4 col-md-4">
			<app-state-chart></app-state-chart>
		</div>
		<div class="col-xl-4 col-md-4">
			<app-gender-chart></app-gender-chart>
		</div>
	</div>
</section>

<section class="container-fluid">
	<div class="row equal">
		<div class="col-lg-4">
			<div class="site-info-wrape">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">Site Basic Information </h4>
						<a class="indi-icon edit-icon" routerLink="site/edit" (click)="redirectSite(siteId)"
							title="Edit"><i class="fa fa-edit"></i></a>
					</div>
					<div class="card-body">
						<p>
							<b>Site Name</b>
							<span>{{ siteDetail.site_name }}</span>
						</p>
						<p>
							<b>Site Code</b>
							<span>{{ siteDetail.site_code | uppercase }}</span>
						</p>
						<p>
							<b>Start Date</b>
							<span>{{ siteDetail.start_date | date: "dd MMM yyyy" }}</span>
						</p>
						<p>
							<b>End Date</b>
							<span>{{ siteDetail.end_date | date: "dd MMM yyyy" }}</span>
						</p>
						<p>
							<b>Number of Days Camp Was Conducted/Number of Camp Days</b>
							<span>{{ siteDetail.number_of_camp_days }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-4">
			<div class="site-info-wrape">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">Camp Address </h4>
						<a class="indi-icon edit-icon" (click)="redirectSite(siteId)" title="Edit"><i
								class="fa fa-edit"></i></a>
					</div>
					<div class="card-body">
						<p>
							<b>Pincode</b>
							<span>{{ siteDetail.pincode }}</span>
						</p>
						<p>
							<b>State</b>
							<span>{{ siteDetail.state_name }}</span>
						</p>
						<p>
							<b>City</b>
							<span>{{ siteDetail.city_name }}</span>
						</p>
						<p>
							<b>Locality or Neighbourhood</b>
							<span>{{ siteDetail.locality }}</span>
						</p>
						<p>
							<b>Street Number, Street Name</b>
							<span>{{ siteDetail.street }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-4">
			<div class="site-info-wrape">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">Site Coordinator Details </h4>
						<a class="indi-icon edit-icon" (click)="redirectSite(siteId)" title="Edit"><i
								class="fa fa-edit"></i></a>
					</div>
					<div class="card-body">
						<p>
							<b>Site Coordinator Name</b>
							<span>{{ siteDetail.site_coordinator_name }}</span>
						</p>
						<p>
							<b>Site Coordinator Phone 1</b>
							<span>{{ siteDetail.site_coordinator_phone }}</span>
						</p>
						<p>
							<b>Site Coordinator Phone 2</b>
							<span>{{ siteDetail.site_coordinator_phone2 }}</span>
						</p>
						<p>
							<b>Site Coordinator Email</b>
							<span>{{ siteDetail.site_coordinator_email }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-4">
			<div class="site-info-wrape">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">Ethics Committee Information </h4>
						<a class="indi-icon edit-icon" (click)="redirectSite(siteId)" title="Edit"><i
								class="fa fa-edit"></i></a>
					</div>
					<div class="card-body">
						<p>
							<b>Data Shareable with Third Party</b>
							<span>
								{{ siteDetail.data_sharable ? siteDetail.data_sharable == "YES" || siteDetail.data_sharable == "yes" ? "Yes" : "No" : "" }}
							</span>
						</p>
						<p>
							<b>Project Name</b>
							<span>{{ siteDetail.project_name }}</span>
						</p>
						<p>
							<b>Ethics Committee Name</b>
							<span>{{ siteDetail.ethics_committee_name }}</span>
						</p>
						<p>
							<b>Consent Type</b>
							<span>{{ siteDetail.informed_consent_form_version }}</span>
						</p>
						<p>
							<b>Research Protocol Version</b>
							<span>{{
								siteDetail.research_protocol_version | number: "1.1"
								}}</span>
						</p>
						<p>
							<b>EC Annual Renewal Date/Expiry Date</b>
							<span *ngIf="siteDetail.annual_expiry_at">
								{{ siteDetail.annual_expiry_at | date: "dd MMM yyyy" }}
							</span>
						</p>
						<p>
							<b>Ec Patient History From (PHF) Version</b>
							<span>{{
								siteDetail.patient_history_form_version | number: "1.1"
								}}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="container-fluid">
	<div class="row">
		<div class="col-xl-12 col-md-12">
			<!-- table expand code start -->
			<div class="row">
				<div class="col-xl-12 col-md-12">
					<div class="card-wrape">
						<div class="card-head">
							<h4 class="left-text">Day Wise Applicant Entry</h4>
							<div class="right-control">
								<div class="table-search-wrape side-info">
									<span class="icon"></span>
									<input (keyup)="searchFilter($event)" placeholder="Search" #input
										class="form-control" type="text">
									<div class="info-pad popup-over">
										<button class="info-icon"></button>
										<div class="info-section popupcontent">
											Day, Date, Camp Coordinator, Camp Timing From, Camp Timing To, Diagnostic
											Lab Name
										</div>
									</div>
								</div>
								<button class="primary btn" mat-raised-button color="secoundary"
									routerLink="/site/site-day-wise-form" (click)="redirectSiteDayWise(0)">Add Day Wise
									Data</button>
							</div>
						</div>
						<div class="card-body table-section">
							<mat-spinner *ngIf="siteDayWiseDetailLoader"></mat-spinner>
							<div class="progress-overlay"></div>
							<div class="table-wrape">
								<table mat-table [dataSource]="dataSources" multiTemplateDataRows
									class="mat-elevation-z8" #expandSort="matSort" matSort
									(matSortChange)="SortFilter($event)">
									<ng-container matColumnDef="expand">
										<th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
										<td mat-cell *matCellDef="let element">
											<button mat-icon-button aria-label="expand row"
												(click)="getDayWiseDataById(element)"
												(click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
												<mat-icon
													*ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
												<mat-icon
													*ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
											</button>

											<a class="indi-icon edit-icon" title="Edit Day Wise Data"
												(click)="redirectSiteDayWise(element.id)"></a>
										</td>
									</ng-container>

									<ng-container matColumnDef="day">
										<th mat-header-cell *matHeaderCellDef> Day </th>
										<td mat-cell *matCellDef="let element">{{element.day}}</td>
									</ng-container>
									<ng-container matColumnDef="site_date">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
										<td mat-cell *matCellDef="let element">{{element.site_date | date: "dd MMM yyyy"
											}}</td>
									</ng-container>
									<ng-container matColumnDef="camp_coordinator">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Camp Coordinator </th>
										<td mat-cell *matCellDef="let element">{{element.camp_coordinator.first_name}}
											{{element.camp_coordinator.last_name}} </td>
									</ng-container>
									<ng-container matColumnDef="camp_timing_from">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Camp Timing From </th>
										<td mat-cell *matCellDef="let element">{{element.camp_timing_from}}</td>
									</ng-container>
									<ng-container matColumnDef="camp_timing_to">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Camp Timing To </th>
										<td mat-cell *matCellDef="let element">{{element.camp_timing_to}}</td>
									</ng-container>
									<ng-container matColumnDef="diagnostic_lab_name">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Diagnostic Lab Name </th>
										<td mat-cell *matCellDef="let element">{{element.diagnostic_lab.name}}</td>
									</ng-container>

									<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
									<ng-container matColumnDef="expandedDetail">
										<td mat-cell *matCellDef="let element"
											[attr.colspan]="columnsToDisplayWithExpand.length">
											<div class="example-element-detail" [@detailExpand]="isExpanded(element)">
												<div class="example-element-position"> </div>
												<div class="inner-bg-gray" *ngIf="siteDayWiseDetail[element.id]">
													<div class="col-xl-12">
														<table class="detail-table-wise" cellpadding="0"
															cellspacing="0">
															<tr class="row-view full">
																<td colspan="2" class="full">
																	<h6>Camp Site Management </h6>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Date - Materials Packed for Camp</b>
																	<span>{{siteDayWiseDetail[element.id].date_of_materials_packed
																		| date: dateFormate }}</span>
																</td>
																<td>
																	<b>Total Number of Participants Expected</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].total_participants_expected
																		> 0 ?
																		siteDayWiseDetail[element.id].total_participants_expected
																		: ''
																		}}
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Materials Packed for Number of Participants</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].materials_packed_total_participants
																		> 0 ?
																		siteDayWiseDetail[element.id].materials_packed_total_participants
																		: ''
																		}}
																	</span>
																</td>
																<td>
																	<b> Data Logger given To</b>
																	<span>{{siteDayWiseDetail[element.id].data_logger_given_to}}</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Camp Supervisor (Optional) </b>
																	<span
																		*ngIf="siteDayWiseDetail[element.id].camp_supervisor">
																		{{
																		siteDayWiseDetail[element.id].camp_supervisor.first_name
																		+ " " +
																		siteDayWiseDetail[element.id].camp_supervisor.last_name
																		}}
																	</span>
																</td>
																<td>
																	<b>Attachment for Temp Logger</b>
																	<!-- <span>{{siteDayWiseDetail[element.id].temp_logger_attachment}}</span> -->
																	<span
																		*ngIf=" siteDayWiseDetail[element.id] && siteDayWiseDetail[element.id].temp_logger_attachment ">
																		<a href="{{ siteDayWiseDetail[element.id] .temp_logger_attachment_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id] .temp_logger_attachment }}">
																			{{ siteDayWiseDetail[element.id]
																			.temp_logger_attachment_ext | uppercase }}
																		</a>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Camp Counsellor</b>
																	<span>{{siteDayWiseDetail[element.id].camp_counsellors}}</span>
																</td>
																<td rowspan="2" class="v-middle">
																	<b>Any Other Camp Deliverable Status - Invoice,
																		Material Restock etc. </b>
																	<span
																		*ngIf="siteDayWiseDetail[element.id].any_other_camp_deliverable_status">
																		<a href="{{siteDayWiseDetail[element.id].any_other_camp_deliverable_status_url
																				  }}" target="_blank" title="{{
																					siteDayWiseDetail[element.id].any_other_camp_deliverable_status
																				  }}">
																			{{
																			siteDayWiseDetail[element.id].any_other_camp_deliverable_status_ext
																			| uppercase
																			}}
																		</a>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Camp Phlebotomist</b>
																	<span>{{siteDayWiseDetail[element.id].camp_phlebotomists}}</span>
																</td>
															</tr>
														</table>
													</div>

													<div class="col-xl-12">
														<table class="detail-table-wise" cellpadding="0"
															cellspacing="0">
															<tr class="row-view full">
																<td colspan="2" class="full">
																	<h6>Health Test Record Management </h6>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Acknowledgment of Sample Pickup </b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].acknowledgment_of_sample_pickup_delivery_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].acknowledgment_of_sample_pickup_delivery }}">
																			{{
																			siteDayWiseDetail[element.id].acknowledgment_of_sample_pickup_delivery_ext
																			| uppercase
																			}}
																		</a>
																	</span>
																</td>
																<td>
																	<b>Reports Checked and Invoice Verified By</b>
																	<span>{{siteDayWiseDetail[element.id].reports_checked_invoice_verified_by}}</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Test Request Form (TRF) Available</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].is_available_test_request_form
																		? siteDayWiseDetail[element.id]
																		.is_available_test_request_form == "YES" || siteDayWiseDetail[element.id]
																		.is_available_test_request_form == "yes" ? "Yes"
																		: "No" : "" }}
																	</span>
																</td>
																<td>
																	<b>Camp Report Delivery Status
																		(Delivered/Pending)</b>
																	<span>{{siteDayWiseDetail[element.id].camp_report_delivery_status}}</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Test Request Form (TRF) Attachment</b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].test_request_form_attachment_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].test_request_form_attachment }}">
																			{{ siteDayWiseDetail[element.id]
																			.test_request_form_attachment_ext |
																			uppercase }}
																		</a>
																	</span>
																</td>
																<td>
																	<b>Date on Which Reports Dispatched to Site</b>
																	<span>{{ siteDayWiseDetail[element.id]
																		.reports_dispatched_to_site_date | date:
																		dateFormate }}</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Date-HTR Received in Office</b>
																	<span>{{
																		siteDayWiseDetail[element.id].htr_received_date
																		| date: dateFormate }}</span>
																</td>
																<td>
																	<b>Reports Delivered By</b>
																	<span> {{
																		siteDayWiseDetail[element.id].reports_deliverd_by
																		}}</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Reports Received By</b>
																	<span>{{siteDayWiseDetail[element.id].reports_received_by}}</span>
																</td>
																<td>
																	<b>Soft Copies of Reports Received an Uploaded</b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].soft_copies_of_reports_received_and_uploaded_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].soft_copies_of_reports_received_and_uploaded }}">
																			{{
																			siteDayWiseDetail[element.id].soft_copies_of_reports_received_and_uploaded_ext
																			|
																			uppercase
																			}}
																		</a>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Number of Reports Received</b>
																	<!-- <span>{{siteDayWiseDetail[element.id].total_reports_received}}</span> -->
																	<span>{{siteDayWiseDetail[element.id].total_reports_received > 0 ? siteDayWiseDetail[element.id].total_reports_received : ''}}</span>
																</td>
																<td rowspan="2" class="v-middle">
																	<b>Soft Copies of Invoice Received and Uploaded </b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].soft_copies_of_invoice_received_and_uploaded_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].soft_copies_of_invoice_received_and_uploaded }}">
																			{{
																			siteDayWiseDetail[element.id].soft_copies_of_invoice_received_and_uploaded_ext
																			| uppercase }}
																		</a>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Invoice Cleared and Payment Done</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].is_invoice_cleared_and_payment_done
																		?
																		siteDayWiseDetail[element.id].is_invoice_cleared_and_payment_done
																		== "YES" || siteDayWiseDetail[element.id].is_invoice_cleared_and_payment_done
																		== "yes" ? "Yes" : "No" : ""
																		}}
																	</span>
																</td>
															</tr>
														</table>
													</div>

													<div class="col-xl-12">
														<table class="detail-table-wise" cellpadding="0"
															cellspacing="0">
															<tr class="row-view full">
																<td colspan="2" class="full">
																	<h6>Check-In (Date & Time) </h6>
																</td>
															</tr>
															<tr class="row-view full">
																<td class="full">Coordinator</td>
															</tr>
															<tr class="row-view"
																*ngIf="siteDayWiseDetail[element.id].coordinator">
																<td>
																	<b>
																		{{
																		siteDayWiseDetail[element.id].coordinator.user.first_name
																		+ " " +
																		siteDayWiseDetail[element.id].coordinator.user
																		.last_name
																		}}
																	</b>
																	<span>{{ siteDayWiseDetail[element.id].coordinator
																		.site_check_in_time }}</span>
																</td>
															</tr>
															<tr class="row-view full">
																<td class="full">Counsellor</td>
															</tr>
															<tr class="row-view"
																*ngFor="let siteVisit of siteDayWiseDetail[element.id].counselor">
																<td>
																	<b> {{ siteVisit.user.first_name + " " +
																		siteVisit.user.last_name }}</b>
																	<span>{{siteVisit.site_check_in_time}}</span>
																</td>
															</tr>
														</table>
													</div>

													<div class="col-xl-12 m-b-0">
														<table class="detail-table-wise" cellpadding="0"
															cellspacing="0">
															<tr class="row-view full">
																<td colspan="2" class="full">
																	<h6>Camp Data</h6>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Temperature Logger Sent for the Camp</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].temperature_logger_sent_for_camp
																		?
																		siteDayWiseDetail[element.id].temperature_logger_sent_for_camp
																		== "YES" || siteDayWiseDetail[element.id].temperature_logger_sent_for_camp
																		== "yes" ? "Yes" : "No" : "-"
																		}}
																	</span>
																</td>
																<td>
																	<b>-80 Gel Packs Delivered</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].gel_packs_delivered_80
																		?
																		siteDayWiseDetail[element.id].gel_packs_delivered_80
																		== "YES" || siteDayWiseDetail[element.id].gel_packs_delivered_80
																		== "yes" ? "Yes" : "No" : "-"
																		}}
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Camp Materials Checklist Attachment</b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].materials_checklist_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].materials_checklist }}">
																			{{
																			siteDayWiseDetail[element.id].materials_checklist_ext
																			| uppercase }}
																		</a>
																	</span>
																</td>
																<td>
																	<b>Shipment Bill/AWB Number </b>
																	<span>
																		<a href="{{ siteDayWiseDetail[element.id].shipment_bill_or_awb_number_url }}"
																			target="_blank"
																			title="{{ siteDayWiseDetail[element.id].shipment_bill_or_awb_number }}">
																			{{
																			siteDayWiseDetail[element.id].shipment_bill_or_awb_number_ext
																			| uppercase }}
																		</a>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Gate Pass for Camp Materials given To</b>
																	<span
																		*ngIf=" siteDayWiseDetail[element.id] .gate_pass_for_camp_materials_given_to">
																		{{
																		siteDayWiseDetail[element.id].gate_pass_for_camp_materials_given_to.first_name
																		+ " " +
																		siteDayWiseDetail[element.id].gate_pass_for_camp_materials_given_to.last_name
																		}}
																	</span>
																</td>
																<td>
																	<b>Post Camp Key (Lab & Office Key) Handed over
																		To</b>
																	<span
																		*ngIf="siteDayWiseDetail[element.id].post_camp_key_handed_over_to">
																		{{
																		siteDayWiseDetail[element.id].post_camp_key_handed_over_to.first_name
																		+ " " +
																		siteDayWiseDetail[element.id].post_camp_key_handed_over_to.last_name
																		}}
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Cab Booked for Camp</b>
																	<span>
																		{{
																		siteDayWiseDetail[element.id].is_cab_booked ?
																		siteDayWiseDetail[element.id].is_cab_booked ===
																		"YES" || siteDayWiseDetail[element.id].is_cab_booked ===
																		"yes" ? "Yes" : "No" : "-"
																		}}
																	</span>
																</td>
																<td rowspan="3" class="v-middle">
																	<b>Camp Photos/Videos</b>
																	<span *ngIf="
																	siteDayWiseDetail[element.id] &&
																	siteDayWiseDetail[element.id].camp_photos_videos !== '' ">
																		<span class="camp-videos"
																			*ngFor=" let campPhotosVideo of siteDayWiseDetail[element.id].camp_photos_videos; let i = index">
																			<a href="{{ campPhotosVideo.full_url }}"
																				target="_blank"
																				title="{{ campPhotosVideo.name }}">
																				<span
																					*ngIf="campPhotosVideo.type === 'image'">
																					<img src="{{ campPhotosVideo.full_url }}"
																						alt="{{ campPhotosVideo.name }}" />
																				</span>
																				<span
																					*ngIf="campPhotosVideo.type === 'video'">
																					<img src="../assets/images/video64x64.png"
																						alt="{{ campPhotosVideo.name }}" />
																				</span>
																			</a>
																		</span>
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>Cab Booked By</b>
																	<span
																		*ngIf="siteDayWiseDetail[element.id].cab_booked_by ">
																		{{
																		siteDayWiseDetail[element.id].cab_booked_by
																		.first_name + " " +
																		siteDayWiseDetail[element.id].cab_booked_by
																		.last_name
																		}}
																	</span>
																</td>
															</tr>
															<tr class="row-view">
																<td>
																	<b>No. of Cabs Booked</b>
																	<span>{{
																		siteDayWiseDetail[element.id].total_cab_booked
																		}}</span>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
									<tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
										class="example-element-row" (click)="getDayWiseDataById(element)"
										[class.example-expanded-row]="expandedElement === element"
										(click)="expandedElement = expandedElement === element ? undefined : element">
									</tr>
									<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
										class="example-element-row example-detail-row">
										<th mat-header-cell *matHeaderCellDef>Action</th>
									</tr>
									<tr mat-row>
										<td *matNoDataRow colspan="7">{{matNoDataRowTitle}}</td>
									</tr>

								</table>

								<mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage"
									[pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
									(page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons>
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- table expand code end -->
		</div>
	</div>
</section>
